import React from 'react';
import { Box } from '@mui/material';
import { COLOR } from '@src/styles/color';
import { BOX_SHADOW_CARD } from '@src/styles/config';
import SelectOrganization from '../SelectOrganization';

const Home = () => (
  <Box
    maxWidth={600}
    margin="auto"
    border={`1px solid ${COLOR.ink[16]}`}
    borderRadius={8}
    mt={20}
    bgcolor={COLOR.white[100]}
    boxShadow={BOX_SHADOW_CARD}
  >
    <SelectOrganization />
  </Box>
);

export default Home;
