import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Divider,
  Rating,
  Tooltip,
  Typography,
} from '@mui/material';
import { Info, Reply } from '@mui/icons-material';
import StatusComponent from '@src/components/StatusComponent';
import apis from '@src/apis';
import ReplyComment from './ReplyComment';

const CommentItem = ({ organizationId, appId, comment }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [replyData, setReplyData] = useState(null);
  const { reply } = comment;
  const handleUpdateComment = async (id, data) => {
    const res = await apis.comments.replyComment(
      organizationId,
      appId,
      id,
      data,
    );
    return res;
  };

  const handleReplyComment = async (data) => {
    const res = await handleUpdateComment(comment.id, data);
    return res;
  };

  useEffect(() => {
    if (reply) setReplyData(reply);
  }, [reply]);

  return (
    <Box key={comment.id} borderBottom={1} borderColor="divider" pb={2}>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography fontSize={20} fontWeight={700}>
            {comment.title}
          </Typography>
          <Box display="flex" gap={1} alignItems="center">
            <Typography fontSize={14} fontWeight={400} color="textSecondary">
              bởi {comment.createdBy.name} -{' '}
              {moment(comment.createdAt).format('DD/MM/YYYY')}
            </Typography>
            <Rating value={comment.stars} readOnly sx={{ fontSize: 18 }} />
          </Box>
        </Box>
        <Box display="flex" gap={2} alignItems="center">
          <Tooltip title="Cho phép hiển thị trên Store" placement="top">
            <Box display="flex" gap={0.5} alignItems="center">
              <Info fontSize="small" />
              <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                Hiển thị
              </Typography>
            </Box>
          </Tooltip>
          <StatusComponent
            id={comment.id}
            active={comment.active}
            name={comment.title}
            handleCallApiUpdate={handleUpdateComment}
            activeTextContent="Bạn có chắc chắn muốn hiển thị bình luận này không?"
            inactiveTextContent="Bạn có chắc chắn muốn ẩn bình luận này không?"
            inactiveNotificationSuccess="Ẩn bình luận thành công"
            activeNotificationSuccess="Hiển thị bình luận thành công"
          />
          <Divider orientation="vertical" flexItem />
          <Button
            color="primary"
            startIcon={<Reply />}
            onClick={() => setIsReplying(true)}
            disabled={isReplying || !!replyData?.content}
            sx={{ whiteSpace: 'nowrap' }}
          >
            Phản hồi
          </Button>
        </Box>
      </Box>
      <Typography fontWeight={400} my={1.5} fontSize={16}>
        {comment.content}
      </Typography>
      {(isReplying || !!replyData?.content) && (
        <ReplyComment
          isReplying={isReplying}
          setIsReplying={setIsReplying}
          handleReplyComment={handleReplyComment}
          replyData={replyData}
          setReplyData={setReplyData}
        />
      )}
    </Box>
  );
};

export default CommentItem;
