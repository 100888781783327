import React, { useRef, useState } from 'react';
import { CopyToClipboard as ClipboardCopier } from 'react-copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';

import { ContentCopyRounded } from '@mui/icons-material';
import { StyledCopyToClipBoard } from './index.style';

const TIME_TO_RESET_COPY_STATE = 3000;

const CopyToClipboard = ({ text, children }) => {
  const copyRef = useRef();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    if (copyRef.current) clearTimeout(copyRef.current);

    copyRef.current = setTimeout(() => {
      setCopied(false);
    }, [TIME_TO_RESET_COPY_STATE]);
  };

  return (
    <StyledCopyToClipBoard>
      {copied ? (
        <IconButton size="small" className="copied-icon-container">
          <CheckIcon fontSize="small" className="copied-icon" />
        </IconButton>
      ) : (
        <ClipboardCopier onCopy={handleCopy} text={text}>
          {children || (
            <IconButton size="small">
              <ContentCopyRounded fontSize="small" className="copy-icon" />
            </IconButton>
          )}
        </ClipboardCopier>
      )}
    </StyledCopyToClipBoard>
  );
};

export default CopyToClipboard;
