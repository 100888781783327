import * as React from 'react';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const StyledTooltip = styled(({ className, ...props }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Tooltip {...props} classes={{ popper: className }} />
))((props) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: COLOR.darkBlue[8],
    backdropFilter: 'blur(25px)',
    color: COLOR.white,
    borderRadius: '12px',
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 16px',
    textAlign: 'center',
    maxWidth: props?.maxWidth,
  },
  [`& .${tooltipClasses.arrow}`]: {
    // color: COLOR.charcoal[100],
  },
}));

export { StyledTooltip };
