import ROUTES from '@src/constants/route';
import Unauthorized from '@src/pages/Unauthorized';
import Home from '@src/pages/Home';
import App from '@src/pages/App';
import AppDetail from '@src/pages/AppDetail';
import OrganizationInfo from '@src/pages/OrganizationInfo';
import Login from '@src/pages/Login';
import Statistic from '@src/pages/Statistic';

export default [
  {
    path: ROUTES.UNAUTHORIZED,
    component: Unauthorized,
    exact: true,
    restricted: true,
    isPrivate: false,
    isMenu: false,
    ignoreToken: true,
  },
  {
    path: ROUTES.HOME,
    component: Home,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: false,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP,
    component: App,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_DETAIL_INFO,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_VERSION_DETAIL,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_EVALUATION,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_EVALUATION_DETAIL,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.PAYMENT_HISTORY,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.ORGANIZATION_INFO,
    component: OrganizationInfo,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_PACKAGES,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_WEBHOOK,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.APP_COMMENT,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.CREDENTIAL_APP,
    component: AppDetail,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
    exact: false,
    restricted: false,
    isPrivate: false,
    isMenu: false,
    ignoreToken: false,
  },
  {
    path: ROUTES.STATISTIC,
    component: Statistic,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
  {
    path: ROUTES.REPORT,
    component: Statistic,
    exact: true,
    restricted: true,
    isPrivate: true,
    isMenu: true,
    ignoreToken: true,
  },
];
