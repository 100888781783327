import React, { useMemo } from 'react';
import JoditEditor from 'jodit-react';

const EDITOR_CONFIG = {
  readonly: true,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  toolbarAdaptive: false,
  askBeforePasteHTML: false,
  askBeforePasteFromWord: false,
  height: '50vh',
  toolbar: false,
};

const RickTextEditor = ({ reason, onChangeReason = () => {}, readonly }) => {
  const onChange = (newContent) => {
    onChangeReason(newContent);
  };

  const config = useMemo(
    () => ({
      ...EDITOR_CONFIG,
      readonly,
    }),
    [readonly],
  );

  return <JoditEditor value={reason} config={config} onChange={onChange} />;
};

export default RickTextEditor;
