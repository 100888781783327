import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

const StyledFileDropzone = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;

  .dropzone {
    border: 1px solid
      ${(props) => (props.iserror ? COLOR.error.base : COLOR.darkBlue[64])};
    background: ${COLOR.darkBlue[4]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    text-align: center;
    color: ${COLOR.outlined[80]};
    width: ${({ width }) => (width ? `${width}px` : '180px')};
    height: ${({ height }) => (height ? `${height}px` : '180px')};
  }

  .pointer {
    cursor: pointer;
  }

  .file-input {
    display: none;
  }

  .preview-image {
    border-radius: 12px !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .error {
    margin-top: 4px;
  }

  .action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action-buttons {
      display: flex;
      gap: 16px;
    }
  }

  .button {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    width: 160px;
  }

  .mt-24 {
    margin-top: 24px;
  }
`;

export const StyledImagePreview = styled(Box)`
  display: flex;
  gap: 20px;
  position: relative;
  width: 100%;
  height: 100%;

  .preview-image {
    border-radius: 6px;
    max-width: 100%;
    cursor: pointer;
  }

  .delete-image {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${COLOR.charcoal[100]};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 4px;
    border-radius: 100%;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }

  .fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: ${COLOR.black[64]};
    display: flex;
    justify-content: center;
    align-items: center;

    .close-button {
      position: absolute;
      top: 20px;
      right: 20px;
      color: ${COLOR.white[100]};
      font-size: 24px;
      cursor: pointer;
    }

    img {
      max-width: 95%;
      max-height: 95%;
    }
  }
`;

export { StyledFileDropzone };
