import React from 'react';
import { Skeleton } from '@mui/material';

const LoadingOverview = () => (
  <Skeleton
    variant="rectangular"
    height="calc((100vh - 377px) / 2)"
    width="100%"
    sx={{ borderRadius: '12px' }}
  />
);
export default LoadingOverview;
