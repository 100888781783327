import { Box, Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppPackages = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header-card {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .header-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .author-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .drag-drop {
    cursor: grab;

    :active {
      cursor: grabbing;
    }
  }

  .body-card {
    margin-bottom: 64px;
  }

  .footer-card {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: fill-available;
    position: absolute;
    bottom: 0;
    background-color: ${COLOR.white[100]};
    padding: 16px 32px 32px 32px;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      min-width: 128px;
    }
  }
`;

export const StyledCreatePackage = styled(Dialog)`
  .MuiDialog-paper {
    padding: 24px !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 800px;
  }

  .pd-0 {
    padding: 0 !important;
  }

  .dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .label-input {
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }

  @media (max-width: 900px) {
    .MuiDialog-paper {
      min-width: 500px;
    }
  }
`;
