import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, TextField, Typography } from '@mui/material';
import {
  IMAGE_ACCEPT_FILE,
  MAX_FILE_SIZE_MEGABYTE,
  VERSION_IMAGE_TYPE_FILE,
  VERSION_REGEX,
} from '@src/constants';
import FileDropzone from '@src/components/FileDropzone';
import { StyledGeneralInformation, StyledImageSelection } from './index.style';

const GeneralInformation = ({
  commonData,
  setCommonData,
  isError,
  isErrorDraft,
  invalidData,
  disabled,
}) => {
  const { t } = useTranslation();
  const isErrorVideoUrl = (isErrorDraft || isError) && invalidData.videoUrl;
  const isErrorVersion = (isErrorDraft || isError) && invalidData.version;
  const isErrorAppImage = isError && !commonData.appImageUrl;
  const isErrorFeaturedImage = isError && !commonData.featuredImageUrl;
  const isErrorThumbnailImage = isError && !commonData.thumbnailImageUrl;

  const minWidth1024 = 1024;
  const minHeight1024 = 1024;
  const minWidth640 = 640;
  const minHeight640 = 640;
  const minHeight500 = 500;

  const maxSizeOne = MAX_FILE_SIZE_MEGABYTE.ONE_MB;
  const maxSizeThree = MAX_FILE_SIZE_MEGABYTE.THREE_MB;

  const helperTextVersion =
    (!commonData.version && t('fieldRequired')) ||
    (commonData.version &&
      !VERSION_REGEX.test(commonData.version) &&
      t('invalidVersionFormat'));

  const handleSetAppImage = (value) =>
    setCommonData({ ...commonData, appImageUrl: value });

  const handleSetFeaturedImage = (value) =>
    setCommonData({ ...commonData, featuredImageUrl: value });

  const handleSetThumbnailImage = (value) =>
    setCommonData({ ...commonData, thumbnailImageUrl: value });

  return (
    <StyledGeneralInformation>
      <Typography variant="h4">{t('commonInfo')}</Typography>
      <Grid container spacing={8}>
        <Grid item xs={12} lg={5} xl={6} alignSelf="center">
          <Box display="flex">
            <Typography my={1} minWidth={150}>
              {t('version')} <span className="required">*</span>
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={commonData.version || ''}
              onChange={(e) =>
                setCommonData({ ...commonData, version: e.target.value })
              }
              name="version"
              placeholder={t('inputVersion')}
              error={isErrorVersion}
              helperText={isErrorVersion && helperTextVersion}
              disabled={disabled}
              id="version"
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={7} xl={6}>
          <StyledImageSelection justifyContent="space-between">
            <Box maxWidth={280}>
              <Typography>
                {t('appImage')} <span className="required">*</span>
              </Typography>
              <Typography variant="body2">
                {t('imageMinDesc', {
                  types: VERSION_IMAGE_TYPE_FILE.map((type) =>
                    type.toUpperCase(),
                  )
                    .join(', ')
                    .replace(/,([^,]*)$/, ' hoặc$1'),
                  maxSize: maxSizeOne,
                  minWidth: minWidth1024,
                  minHeight: minHeight1024,
                  ratio: '1:1',
                })}
              </Typography>
              {isErrorAppImage && (
                <Typography color="error" variant="body2" mt={2}>
                  {t('fieldRequired')}
                </Typography>
              )}
            </Box>
            <FileDropzone
              fileUrl={commonData.appImageUrl}
              fileType={VERSION_IMAGE_TYPE_FILE}
              acceptFile={IMAGE_ACCEPT_FILE}
              onAddFile={handleSetAppImage}
              onDeleteFile={handleSetAppImage}
              maxSize={maxSizeOne * 1024 * 1024}
              minWidth={minWidth1024}
              minHeight={minHeight1024}
              width={100}
              height={100}
              isError={isErrorAppImage}
              disabled={disabled}
              ratioCrop={1}
            />
          </StyledImageSelection>
        </Grid>
      </Grid>
      <Typography variant="h6">{t('featureImage')}</Typography>
      <Grid container spacing={8}>
        <Grid item lg={12} xl={6}>
          <StyledImageSelection justifyContent="space-between">
            <Box maxWidth={280}>
              <Typography>
                {t('thumbnailImage')} <span className="required">*</span>
              </Typography>
              <Typography variant="body2">
                {t('imageMinDesc', {
                  types: VERSION_IMAGE_TYPE_FILE.map((type) =>
                    type.toUpperCase(),
                  )
                    .join(', ')
                    .replace(/,([^,]*)$/, ' hoặc$1'),
                  maxSize: maxSizeOne,
                  minWidth: minWidth640,
                  minHeight: minHeight640,
                  ratio: '1:1',
                })}
              </Typography>
              {isErrorThumbnailImage && (
                <Typography color="error" variant="body2" mt={2}>
                  {t('fieldRequired')}
                </Typography>
              )}
            </Box>
            <FileDropzone
              fileUrl={commonData.thumbnailImageUrl}
              fileType={VERSION_IMAGE_TYPE_FILE}
              acceptFile={IMAGE_ACCEPT_FILE}
              onAddFile={handleSetThumbnailImage}
              onDeleteFile={handleSetThumbnailImage}
              maxSize={3 * 1024 * 1024}
              minWidth={minWidth640}
              minHeight={minHeight640}
              isError={isErrorThumbnailImage}
              disabled={disabled}
              ratioCrop={1}
            />
          </StyledImageSelection>
        </Grid>
        <Grid item lg={12} xl={6}>
          <StyledImageSelection justifyContent="space-between">
            <Box maxWidth={280}>
              <Typography>
                {t('largeImage')} <span className="required">*</span>
              </Typography>
              <Typography variant="body2">
                {t('imageMinDesc', {
                  types: VERSION_IMAGE_TYPE_FILE.map((type) =>
                    type.toUpperCase(),
                  )
                    .join(', ')
                    .replace(/,([^,]*)$/, ' hoặc$1'),
                  maxSize: maxSizeThree,
                  minWidth: minWidth1024,
                  minHeight: minHeight500,
                  ratio: '1024:500',
                })}
              </Typography>
              {isErrorFeaturedImage && (
                <Typography color="error" variant="body2" mt={2}>
                  {t('fieldRequired')}
                </Typography>
              )}
            </Box>
            <FileDropzone
              fileUrl={commonData.featuredImageUrl}
              fileType={VERSION_IMAGE_TYPE_FILE}
              acceptFile={IMAGE_ACCEPT_FILE}
              onAddFile={handleSetFeaturedImage}
              onDeleteFile={handleSetFeaturedImage}
              maxSize={maxSizeThree * 1024 * 1024}
              minWidth={minWidth1024}
              minHeight={minHeight500}
              width={369}
              isError={isErrorFeaturedImage}
              disabled={disabled}
              ratioCrop={1024 / 500}
            />
          </StyledImageSelection>
        </Grid>
      </Grid>
      <Box className="video-card">
        <Typography variant="h6">{t('featuredVideo')}</Typography>
        <Typography variant="body2">{t('urlVideoHelperText')}</Typography>
        <TextField
          size="small"
          fullWidth
          placeholder={t('URL video')}
          value={commonData.videoUrl || ''}
          onChange={(e) =>
            setCommonData({ ...commonData, videoUrl: e.target.value })
          }
          error={isErrorVideoUrl}
          helperText={isErrorVideoUrl && t('urlIsInvalid')}
          disabled={disabled}
          id="videoUrl"
        />
      </Box>
    </StyledGeneralInformation>
  );
};

export default GeneralInformation;
