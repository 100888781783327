import { Popper, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';
import { BOX_SHADOW_SIDEBAR, SIDE_BAR_WIDTH } from '@src/styles/config';

export const StyledBottom = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .bottom-wrapper {
    display: flex;
    padding: 10px;
    gap: 5px;
  }

  .small-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 5px;
  }

  .avatar-wrapper {
    padding: 0 !important;
  }

  .avatar {
    position: relative;
    border-radius: 100%;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    flex: 1;

    .name {
      max-width: 139px;
      color: ${COLOR.darkestBlue[80]};
    }

    .bonus {
      max-width: 139px;
      color: ${COLOR.blueGray[64]};
    }
  }

  .close-sidebar {
    margin: auto;

    .button-close {
      padding: 8px;
      border-radius: 50%;
    }
  }
`;

export const StyledMenu = styled(Box)`
  display: flex;
  flex-direction: column;
  width: ${`${SIDE_BAR_WIDTH - 20}px`};
  background-color: ${COLOR.white[100]};
  border-radius: 12px;
  padding: 4px;

  .menu-item {
    display: flex;
    padding: 8px;
    gap: 12px;
    border-radius: 8px;
    font-size: 14px;
  }

  .red-color {
    color: ${COLOR.red[100]};
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 1299;
  border-radius: 12px;
  background-color: ${COLOR.white[100]};
  box-shadow: ${BOX_SHADOW_SIDEBAR};
`;
