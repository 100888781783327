import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledFileDropzone = styled(Box)`
  .dropzone {
    border: 1px solid
      ${(props) => (props.iserror ? COLOR.error.base : COLOR.darkBlue[64])};
    background: ${COLOR.darkBlue[4]};
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    padding: 40px 40px 20px 40px;
    text-align: center;
    color: ${COLOR.outlined[80]};
  }

  .pointer {
    cursor: pointer;
  }

  .file-input {
    display: none;
  }

  .upload-note {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px 160px;
    gap: 16px;
  }

  .upload-success {
    margin-bottom: 16px;
  }

  .error {
    margin-top: 16px;
  }

  .preview-image-upload {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .preview-image {
    width: 100%;
    height: auto;
  }

  .action {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .action-buttons {
      display: flex;
      gap: 16px;
    }
  }

  .button {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    width: 160px;
  }

  .mt-24 {
    margin-top: 24px;
  }
`;

export const StyledImagePreview = styled(Box)`
  display: flex;
  gap: 20px;
  position: relative;
  max-width: 483px;
  height: 210px;

  .preview-image {
    border-radius: 6px;
    max-width: 100%;
    height: 210px;
    cursor: pointer;
  }

  .delete-image {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${COLOR.charcoal[100]};
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.16);
    padding: 4px;
    border-radius: 100%;
    top: -10px;
    right: -10px;
    cursor: pointer;
  }
`;
