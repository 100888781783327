import { Box } from '@mui/material';
import { COLOR } from '@src/styles/color';
import React from 'react';

export default function Card({ children, sx }) {
  return (
    <Box
      sx={{
        backgroundColor: COLOR.white[100],
        borderRadius: '16px',
        padding: '24px',
        width: '100%',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
