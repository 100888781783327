import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import camelcase from 'camelcase';
import { Box, Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import moment from 'moment';
import CustomTable from '@src/components/CustomTable';
import apis from '@src/apis';
import { DATE_TIME_FORMAT } from '@src/constants';
import { StyledAppPackages } from './index.style';

const AppPackages = ({ app }) => {
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const { organizationId, appId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const [appPackages, setAppPackages] = useState([]);

  const fetchAppPackages = async () => {
    try {
      const res = await apis.packages.getPackages(organizationId, appId, {
        sort: ['rank_asc'],
      });
      if (!res?.status) throw new Error();

      let count = res?.result?.filter((item) => item.rank).length;
      const data = res?.result.map((item) => {
        count += 1;
        return {
          ...item,
          rank: item?.rank || count,
        };
      });

      setAppPackages(data);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchAppPackages();
  }, []);

  const heads = [
    {
      label: t('index'),
      valueName: 'index',
      align: 'center',
    },
    {
      label: t('packageCode'),
      valueName: 'packageCode',
      align: 'left',
    },
    {
      label: t('money'),
      valueName: 'price',
      align: 'left',
    },
    {
      label: t('paymentMethod'),
      valueName: 'paymentMethod',
      align: 'left',
    },
    {
      label: t('displayOption'),
      valueName: 'displayOption',
      align: 'left',
    },
  ];

  const formatData = appPackages.map((el) => ({
    ...el,
    price: el?.price ? `${el?.price.toLocaleString(language)}đ` : '--',
    paymentMethod: el?.paymentMethod ? t(camelcase(el?.paymentMethod)) : '--',
    displayOption: el?.displayOption ? t(camelcase(el?.displayOption)) : '--',
  }));

  return (
    <StyledAppPackages>
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h3">{t('packagesList')}</Typography>
        </Box>
        <Box className="author-info">
          <Typography variant="caption" color="secondary">
            {t('updateLatestBy')}:{' '}
            {app?.latestPackageUpdated?.updatedBy?.name || '--'}
          </Typography>
          <FiberManualRecord sx={{ width: '6px', height: '6px' }} />
          <Typography variant="caption" color="secondary">
            {t('updateAt')}:{' '}
            {app?.latestPackageUpdated?.updatedAt
              ? moment(app?.latestPackageUpdated?.updatedAt).format(
                  DATE_TIME_FORMAT,
                )
              : '--'}
          </Typography>
        </Box>
        <Typography variant="caption" color="secondary">
          {t('packagesListNote')}
        </Typography>
      </Box>
      <Box className="body-card">
        <CustomTable items={formatData} heads={heads} disablePagination />
      </Box>
    </StyledAppPackages>
  );
};

export default AppPackages;
