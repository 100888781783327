import { createTheme } from '@mui/material/styles';
import { COLOR } from './color';

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR.primary.base,
    },
    secondary: {
      main: COLOR.secondary.base,
    },
    error: {
      main: COLOR.error.base,
    },
    warning: {
      main: COLOR.warning.base,
    },
    info: {
      main: COLOR.info.base,
    },
    success: {
      main: COLOR.success.base,
    },
    text: {
      primary: COLOR.text.primary,
    },
  },
  shape: { borderRadius: 3 },
  typography: { fontFamily: 'SF Pro Rounded', fontWeightRegular: 500 },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '500px',
          minWidth: 64,
          padding: 12,
          boxShadow: 'none',
          textTransform: 'none',
          '&:hover': { boxShadow: 'none' },

          // size
          '&.MuiButton-sizeSmall': { height: 32, fontSize: 12 },
          '&.MuiButton-sizeMedium': { height: 36 },
          '&.MuiButton-sizeLarge': { height: 48, minWidth: 120 },

          '&.MuiButton-text': { textTransform: 'none' },

          // color
          '&.MuiButton-containedPrimary': {
            color: COLOR.white[100],
            backgroundColor: COLOR.darkBlue[100],
            '&:hover': { backgroundColor: COLOR.darkBlue[100] },
            '&.Mui-disabled': {
              opacity: 0.32,
            },
          },
          '&.MuiButton-containedSecondary': {
            color: COLOR.blue[100],
            backgroundColor: COLOR.pastelBlue[64],
            '&:hover': { backgroundColor: COLOR.pastelBlue[100] },
            '&.Mui-disabled': {
              color: COLOR.blueGray[32],
              backgroundColor: COLOR.blueGray[16],
            },
          },
          '&.MuiButton-textPrimary': { color: COLOR.blue[100] },
          '&.MuiButton-textSecondary': { color: COLOR.white[100] },
          '&.MuiButton-containedInfo': {
            color: COLOR.white[100],
            backgroundColor: COLOR.standardBlue[100],
            '&:hover': { backgroundColor: COLOR.infoBlue[100] },
            '&.Mui-disabled': {
              opacity: 0.32,
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: { fontSize: 54, fontWeight: 700 },
        h2: { fontSize: 32, fontWeight: 700 },
        h3: { fontSize: 24, fontWeight: 700 },
        h4: { fontSize: 20, fontWeight: 700 },
        h4b: { fontSize: 20, fontWeight: 510 },
        h4m: { fontSize: 20, fontWeight: 500 },
        h5: { fontSize: 18, fontWeight: 700 },
        h6: { fontSize: 16, fontWeight: 700 },
        h6b: { fontSize: 16, fontWeight: 510 },
        subtitle1: { fontSize: 14 },
        subtitle2: { fontSize: 16, fontWeight: 400 },
        body1b: { fontSize: 14, fontWeight: 700 },
        body1: { fontSize: 14, fontWeight: 500 },
        body2: { fontSize: 14, fontWeight: 400 },
        caption: { fontSize: 12, fontWeight: 400 },
        caption1: { fontSize: 12, fontWeight: 500 },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        rounded: { width: 48, height: 48, borderRadius: 8 },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          minWidth: 64,
          padding: 0,
          gap: 4,
          '&.Mui-selected': { color: COLOR.draftColor.bottomBarActive },
        },
        label: { fontSize: 12 },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: 'none',
          },

          '& .MuiInput-underline:after': {
            borderBottom: 'none',
          },

          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },

          '& .MuiFilledInput-underline:before': {
            borderBottom: 'none',
          },

          '& .MuiFilledInput-underline:after': {
            borderBottom: 'none',
          },

          '& .MuiFilledInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none',
          },

          '& .MuiOutlinedInput-root': {
            borderRadius: 24,
            fieldset: {
              borderColor: COLOR.darkBlue[32],
            },

            '&.MuiInputBase-multiline': {
              borderRadius: 12,
            },
          },

          '& .Mui-disabled:before': {
            borderBottomStyle: 'unset !important',
          },

          '& .MuiFilledInput-root': {
            borderRadius: 24,
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
            backgroundColor: `${COLOR.darkBlue[4]} !important`,

            '&:hover': {
              backgroundColor: `${COLOR.darkBlue[8]} !important`,
            },

            '& .MuiFilledInput-input': {
              padding: '12.5px 12px',
            },
          },

          '& .Mui-disabled': {
            cursor: 'not-allowed !important',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorSuccess: {
          color: COLOR.green[100],
          backgroundColor: COLOR.green[16],
        },
        colorError: {
          color: COLOR.red[100],
          backgroundColor: COLOR.red[16],
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 20,
          padding: 0,
          '& .MuiSwitch-thumb': {
            width: 20,
            height: 20,
            boxShadow: 'none',
            backgroundColor: COLOR.white[100],
          },
          '& .MuiSwitch-switchBase': {
            padding: 0,
            '&.Mui-checked': {
              transform: 'translateX(20px)',
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: COLOR.green[100],
              },
            },
          },
        },
        track: {
          borderRadius: 12,
          opacity: 1,
          backgroundColor: COLOR.ink[16],
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          opacity: 0.64,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 'unset',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
          minWidth: '160px',
          minHeight: 'unset',
          fontSize: '16px',
          textTransform: 'unset',
          fontWeight: 700,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 12,
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          '&.MuiRating-sizeSmall': {
            fontSize: 12,
          },
        },
      },
    },
  },
});

export default theme;
