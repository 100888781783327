import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ROUTES from '@src/constants/route';
import { faAppStoreIos } from '@fortawesome/free-brands-svg-icons';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';

export const data = [
  {
    key: 'Statistic',
    label: 'statisticAndReport',
    icon: <FontAwesomeIcon icon={faChartSimple} size="lg" />,
    path: ROUTES.STATISTIC,
    path2: ROUTES.REPORT,
  },
  {
    key: 'App',
    label: 'app',
    icon: <FontAwesomeIcon icon={faAppStoreIos} size="lg" />,
    path: ROUTES.APP,
  },
];
