import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_APP_ICON } from '@src/constants';
import { StyledAppItem } from './index.style';

const AppItem = ({
  imgSrc = DEFAULT_APP_ICON,
  title,
  description = [],
  onClick = () => {},
}) => (
  <StyledAppItem onClick={onClick}>
    <Avatar variant="rounded" src={imgSrc} alt="img" className="img" />
    <Box className="info">
      <Typography variant="h6" className="title">
        {title}
      </Typography>
      {description.map((desc) => (
        <Box display="flex" gap={1} key={uuidv4()}>
          <Typography variant="body2">{desc.icon}</Typography>
          <Typography variant="body2" color="gray">
            {desc.text}
          </Typography>
        </Box>
      ))}
    </Box>
  </StyledAppItem>
);

export default AppItem;
