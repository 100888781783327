import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { COLOR } from '@src/styles/color';
import { Switch } from '@mui/material';
import Popup from '../Popup';

const StatusComponent = ({
  id,
  active,
  name,
  handleCallApiUpdate,
  activeTextContent,
  inactiveTextContent,
  inactiveNotificationSuccess,
  activeNotificationSuccess,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState(active);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const handleChangeStatus = async (e) => {
    e.stopPropagation();
    try {
      const res = await handleCallApiUpdate(id, { active: !status });
      if (res?.status === 1) {
        setStatus(!status);
      }
      if (inactiveNotificationSuccess) {
        const message = status
          ? inactiveNotificationSuccess
          : activeNotificationSuccess;

        enqueueSnackbar(t(message, { name }), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleOpenConfirmPopup = (e) => {
    e.stopPropagation();
    setOpenConfirmPopup(true);
  };

  const handleCloseConfirmPopup = (e) => {
    e.stopPropagation();
    setOpenConfirmPopup(false);
  };

  useEffect(() => {
    setStatus(active);
  }, [active]);

  return (
    <>
      <Switch checked={status} onClick={handleOpenConfirmPopup} />
      <Popup
        open={openConfirmPopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirm')}
        content={t(status ? inactiveTextContent : activeTextContent, { name })}
        okMessage={t('confirm')}
        cancelMessage={t('cancel')}
        onOk={handleChangeStatus}
        titleColor={COLOR.warning.base}
      />
    </>
  );
};

export default StatusComponent;
