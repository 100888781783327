import React, { useState } from 'react';
import { Box, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import camelcase from 'camelcase';
import { FILTER_OPTION } from '@src/constants';
import Ranking from './Ranking';
import Comments from './Comments';

const AppComment = ({ organizationId, appId, rating }) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState(FILTER_OPTION.LATEST);

  return (
    <Box>
      <Typography variant="h3" mb={1.5}>
        {t('evaluate')}
      </Typography>
      <Divider />
      <Box display="flex" gap={4} my={2.5}>
        <Box width="100%">
          <Ranking rating={rating} />
        </Box>
        <Box width={250} margin="auto" pt={3}>
          <TextField
            select
            fullWidth
            variant="outlined"
            size="small"
            value={sort}
            onChange={(e) => setSort(e.target.value)}
          >
            {Object.values(FILTER_OPTION).map((option) => (
              <MenuItem key={option} value={option}>
                {t(camelcase(option))}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Comments organizationId={organizationId} appId={appId} sort={sort} />
    </Box>
  );
};

export default AppComment;
