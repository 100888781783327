import { SERVICE } from '@src/constants';
import api from './api';

export const getMe = async (accessToken) => {
  const res = await api({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: '/users/me',
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const getOrganizations = async () => {
  const res = await api({
    method: 'GET',
    url: '/organizations/me',
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getOrganization = async (id) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${id}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
