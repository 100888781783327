import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import Tooltip from '@src/components/Tooltip';
import CustomSelect from '@src/components/CustomSelect';
import apis from '@src/apis';
import {
  AGE_RATING,
  APP_NAME_MAX_LENGTH,
  SUB_TITLE_MAX_LENGTH,
  URL_REGEX,
} from '@src/constants';
import { StyledAppDetail } from './index.style';

const AppDetailInfo = () => {
  const { t } = useTranslation();

  const { organizationId, appId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [appName, setAppName] = useState('');
  const [ageRating, setAgeRating] = useState('');
  const [primaryCategory, setPrimaryCategory] = useState({});
  const [secondaryCategory, setSecondaryCategory] = useState({});
  const [subTitle, setSubTitle] = useState('');
  const [policyUrl, setPolicyUrl] = useState('');
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [dataApi, setDataApi] = useState({});
  const [isFirst, setIsFirst] = useState(true);

  const handleAppNameChange = (e) => {
    setErrors({ ...errors, appName: '' });
    let { value } = e.target;
    if (value?.length > APP_NAME_MAX_LENGTH) {
      value = value.slice(0, APP_NAME_MAX_LENGTH);
    }
    setAppName(value);
  };

  const handleAgeRatingChange = (value) => {
    setAgeRating(value);
    setErrors({ ...errors, ageRating: '' });
  };

  const handleCopyRightChange = (e) => {
    setErrors({ ...errors, appName: '' });
    let { value } = e.target;
    if (value?.length > SUB_TITLE_MAX_LENGTH) {
      value = value.slice(0, SUB_TITLE_MAX_LENGTH);
    }
    setSubTitle(e.target.value);
  };

  const handlePolicyUrlChange = (e) => {
    setPolicyUrl(e.target.value);
    setErrors({ ...errors, policyUrl: '' });
  };

  const fetchCategories = async (params = {}) => {
    try {
      const response = await apis.category.getCategories(params);
      if (response?.status) {
        setCategories(response?.result?.data || []);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangeFilterCategoriesInputText = (newInputValue) => {
    if (isFirst) {
      setIsFirst(false);
      return;
    }
    fetchCategories({ inputText: newInputValue });
  };

  const handlePrimaryCategoryChange = (value) => {
    setPrimaryCategory(value);
    fetchCategories();
    setErrors({ ...errors, primaryCategory: '' });
  };

  const handleSecondaryCategoryChange = (value) => {
    setSecondaryCategory(value);
    fetchCategories();
  };

  const validateAppInfo = () => {
    const appInfoErrors = {};
    if (!appName) {
      appInfoErrors.appName = t('appNameIsRequired');
    }
    if (appName?.length > APP_NAME_MAX_LENGTH) {
      appInfoErrors.appName = t('appNameIsTooLong', {
        maxLength: APP_NAME_MAX_LENGTH,
      });
    }
    if (!ageRating) {
      appInfoErrors.ageRating = t('ageRatingIsRequired');
    }
    if (!primaryCategory) {
      appInfoErrors.primaryCategory = t('primaryCategoryIsRequired');
    }
    if (subTitle?.length > SUB_TITLE_MAX_LENGTH) {
      appInfoErrors.copyright = t('copyrightIsTooLong', {
        maxLength: SUB_TITLE_MAX_LENGTH,
      });
    }
    if (policyUrl && !URL_REGEX.test(policyUrl))
      appInfoErrors.policyUrl = t('urlIsInvalid');

    setErrors(appInfoErrors);
    return Object.keys(appInfoErrors).length === 0;
  };

  const handleAppInfoData = () => {
    const data = {
      name: appName,
      ageRating,
      primaryCategoryId: primaryCategory?.id,
    };
    if (secondaryCategory) data.secondCategoryId = secondaryCategory?.id;
    data.subTitle = subTitle || '';
    data.policyUrl = policyUrl || '';

    return data;
  };

  const isChangeAppInfo = () => {
    if (dataApi?.name !== appName) return true;
    if (dataApi?.ageRating !== ageRating) return true;
    if (dataApi?.primaryCategoryId !== primaryCategory?.id) return true;
    if (dataApi?.secondCategoryId !== secondaryCategory?.id) return true;
    if (dataApi?.subTitle !== subTitle) return true;
    if (dataApi?.policyUrl !== policyUrl) return true;
    return false;
  };

  const handleSaveAppInfo = async () => {
    try {
      if (!validateAppInfo()) return;
      const data = handleAppInfoData();
      const response = await apis.app.updateApp(organizationId, appId, data);
      if (response?.status) {
        setDataApi(response?.result);
        enqueueSnackbar(t('saveAppInfoSuccess'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchApp = async () => {
    try {
      const response = await apis.app.getApp(organizationId, appId);
      if (response?.status) {
        const app = response?.result;
        setAppName(app?.name);
        setAgeRating(app?.ageRating);
        setPrimaryCategory(app?.primaryCategory);
        setSecondaryCategory(app?.secondCategory);
        setSubTitle(app?.subTitle);
        setPolicyUrl(app?.policyUrl);
        setDataApi(app);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    fetchApp();
  }, []);

  return (
    <StyledAppDetail>
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h3">{t('appInfo')}</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAppInfo}
            disabled={!isChangeAppInfo()}
          >
            {t('save')}
          </Button>
        </Box>
        <Typography variant="subtitle1">{t('appInfoDescription')}</Typography>
      </Box>
      <Box className="body-card">
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">
              {t('appName')} <span>*</span>
              <Tooltip title={t('appNameTooltip')} arrow placement="top">
                <InfoOutlined fontSize="small" />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              fullWidth
              value={appName}
              onChange={handleAppNameChange}
              error={!!errors?.appName}
              helperText={errors?.appName}
              autoFocus={!appName}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">{t('subTitle')}</Typography>
            <TextField
              size="small"
              fullWidth
              value={subTitle}
              onChange={handleCopyRightChange}
              error={!!errors?.copyright}
              helperText={errors?.copyright}
              inputProps={{ maxLength: SUB_TITLE_MAX_LENGTH }}
            />
          </Grid>
        </Grid>
        <Box>
          <Typography className="label-input">
            {t('type')} <span>*</span>
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={12} lg={6}>
              <Autocomplete
                value={primaryCategory}
                size="small"
                options={categories?.filter(
                  (item) => item?.id !== secondaryCategory?.id,
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option) => option?.name || ''}
                onChange={(event, newValue) => {
                  handlePrimaryCategoryChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('primary')}
                    onChange={(event) => {
                      handleChangeFilterCategoriesInputText(event.target.value);
                    }}
                    error={!!errors?.primaryCategory}
                    helperText={errors?.primaryCategory}
                  />
                )}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <Autocomplete
                value={secondaryCategory}
                size="small"
                options={categories?.filter(
                  (item) => item?.id !== primaryCategory?.id,
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                getOptionLabel={(option) => option?.name || ''}
                onChange={(event, newValue) => {
                  handleSecondaryCategoryChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(event) => {
                      handleChangeFilterCategoriesInputText(event.target.value);
                    }}
                    label={`${t('secondary')} (${t('optional')})`}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">
              {t('ageRating')} <span>*</span>
            </Typography>
            <CustomSelect
              value={ageRating}
              options={Object.values(AGE_RATING).map((value) => ({
                value,
                label: `${value}+ ${t('yearsOld')}`,
              }))}
              onChange={handleAgeRatingChange}
              error={!!errors?.ageRating}
              helperText={errors?.ageRating}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Typography className="label-input">{t('urlPolicy')}</Typography>
            <TextField
              size="small"
              fullWidth
              value={policyUrl}
              onChange={handlePolicyUrlChange}
              error={!!errors?.policyUrl}
              helperText={errors?.policyUrl}
            />
          </Grid>
        </Grid>
        <Box />
      </Box>
    </StyledAppDetail>
  );
};

export default AppDetailInfo;
