import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { COLOR } from '@src/styles/color';
import { StyledOrganizationInfo } from './index.style';

const LoadingOrganizationInfo = () => {
  const { t } = useTranslation();

  return (
    <StyledOrganizationInfo>
      <Box className="body-card">
        <Grid container spacing={4} className="flex">
          <Grid item xs={3}>
            <Typography>{t('avatar')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={56}
              height={56}
              sx={{ bgcolor: COLOR.blueGray[8] }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="flex">
          <Grid item xs={3}>
            <Typography>{t('organizationName')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Skeleton
              animation="wave"
              width="100%"
              height={48}
              sx={{ bgcolor: COLOR.blueGray[8] }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="flex">
          <Grid item xs={3}>
            <Typography>{t('address')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Skeleton
              animation="wave"
              width="100%"
              height={48}
              sx={{ bgcolor: COLOR.blueGray[8] }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="flex">
          <Grid item xs={3}>
            <Typography>{t('phoneNumber')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Skeleton
              animation="wave"
              width="100%"
              height={48}
              sx={{ bgcolor: COLOR.blueGray[8] }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="flex">
          <Grid item xs={3}>
            <Typography>{t('webPage')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Skeleton
              animation="wave"
              width="100%"
              height={48}
              sx={{ bgcolor: COLOR.blueGray[8] }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className="flex">
          <Grid item xs={3}>
            <Typography>{t('owner')}</Typography>
          </Grid>
          <Grid item xs={9}>
            <Skeleton
              animation="wave"
              width="100%"
              height={48}
              sx={{ bgcolor: COLOR.blueGray[8] }}
            />
          </Grid>
        </Grid>
      </Box>
    </StyledOrganizationInfo>
  );
};

export default LoadingOrganizationInfo;
