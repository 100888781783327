import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledPaymentHistory = styled('div')`
  .text-success {
    color: ${COLOR.success.base};
    background: ${TRANSPARENT_COLOR.success};
  }

  .text-warning {
    color: ${COLOR.info.base};
    background: ${TRANSPARENT_COLOR.info};
  }

  .text-danger {
    color: ${COLOR.error.base};
    background: ${TRANSPARENT_COLOR.danger};
  }

  .text-info {
    color: ${COLOR.info.base};
    background: ${TRANSPARENT_COLOR.info};
  }

  .label {
    display: block;
    padding: 4px 12px;
    white-space: nowrap;
    border-radius: 100px;
    width: 128px;
    margin: 0 auto;
    text-align: center;
  }

  .nowrap {
    white-space: nowrap;
  }

  .select {
    min-width: 120px;
    .MuiOutlinedInput-root {
      border-radius: 12px;
      background-color: ${COLOR.darkBlue[8]};
      color: ${COLOR.darkestBlue[100]};

      fieldset {
        border: none;
      }
    }

    .MuiInputLabel-root {
      color: ${COLOR.darkestBlue[100]} !important;
      opacity: 1 !important;
    }
  }

  .filter {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    gap: 20px;

    .input-search {
      overflow: hidden;
      flex-grow: 1;
    }

    .selects {
      display: flex;
      gap: 16px;
    }

    .date-filter {
      display: flex;
      gap: 16px;
      white-space: nowrap;
    }
  }
`;
