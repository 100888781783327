const MIN_HEIGHT_CHART = '297px';

const STAT_OVERVIEW_CHART = {
  APP_STATUS: 'app-status',
};

const REPORT_CHARTS = {
  REVENUE: 'revenue',
  USER: 'user',
  TRANSACTION: 'transaction',
  REVENUE_BY_PACKAGE: 'revenue-by-package',
  REGISTERED_TRANSACTION_BY_PACKAGE: 'registered-transaction-by-package',
  RENEWED_TRANSACTION_BY_PACKAGE: 'renewed-transaction-by-package',
  CANCELLED_TRANSACTION_BY_PACKAGE: 'cancelled-transaction-by-package',
  USER_ACTIVE: 'user-active',
  USER_ACTIVE_BY_PACKAGE: 'user-active-by-package',
};

const REPORT_CATEGORIES = {
  TOTAL: 'total',
  BY_EVENT: 'byEvent',
  BY_PACKAGE: 'byPackage',
};

const CATEGORY_MAPPER = {
  [REPORT_CATEGORIES.TOTAL]: 'total',
  [REPORT_CATEGORIES.BY_EVENT]: 'action',
  [REPORT_CATEGORIES.BY_PACKAGE]: 'package',
};

export {
  MIN_HEIGHT_CHART,
  STAT_OVERVIEW_CHART,
  REPORT_CHARTS,
  REPORT_CATEGORIES,
  CATEGORY_MAPPER,
};
