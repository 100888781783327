import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import ROUTES from '@src/constants/route';

const useOrg = () => {
  const dispatch = useDispatch();
  const currentPath = window.location.pathname;
  const [loading, setLoading] = useState(true);
  const organizationId = currentPath.split('/')[2];
  const history = useHistory();
  const selectedOrganization = useSelector(
    (state) => state.user.organization.selected,
  );

  const conditionUpdateOrganization =
    organizationId && organizationId !== selectedOrganization?.id;

  const checkOrganization = async () => {
    try {
      const res = await apis.user.getOrganization(organizationId);
      if (res.status === 1) {
        dispatch(actions.user.updateSelectedOrganization(res?.result));
        return;
      }
      history.replace(ROUTES.HOME);
    } catch (err) {
      // TODO: optimize error handling later
      if (err?.message === 'forbidden') history.push(ROUTES.UNAUTHORIZED);
      else history.replace(ROUTES.HOME);
    } finally {
      setLoading(false);
    }
  };

  const updateOrganization = () => {
    if (conditionUpdateOrganization) checkOrganization();
    else setLoading(false);
  };

  useEffect(() => {
    updateOrganization();
  }, [organizationId]);

  return { organizationId, loading };
};

export default useOrg;
