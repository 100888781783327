import { PUBLISH_METHOD } from '@src/constants';

export const RELEASE_OPTIONS = [
  {
    value: PUBLISH_METHOD.MANUAL,
    label: 'Phát hành thủ công phiên bản này',
  },
  {
    value: PUBLISH_METHOD.AUTO,
    label: 'Phát hành tự động phiên bản này ngay sau khi nó được phê duyệt',
  },
  // {
  //   value: PUBLISH_METHOD.SCHEDULE,
  //   label: 'Phát hành theo lịch sau khi phiên bản này được phê duyệt',
  // },
];

export const EVALUATION_OPTIONS = [
  {
    value: false,
    label: 'Giữ những đánh giá đã tồn tại',
  },
  {
    value: true,
    label: 'Thiết lập lại đánh giá khi phiên bản này phát hành',
  },
];
