import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Box, Button, Divider, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { formatPhoneNumber } from '@src/utils/formatNumber';
import apis from '@src/apis';
import actions from '@src/redux/actions';
import BadgeAvatar from '@src/components/BadgeAvatar';
import ROUTES from '@src/constants/route';
import { handleReceivingTokens } from '@src/services/auth';
import { StyledSelectOrganization } from './index.style';

const SelectOrganization = ({ onClose = () => {} }) => {
  const { t } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);
  const { list, selected } = useSelector((state) => state.user.organization);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const fetchOrganizations = async () => {
    try {
      const response = await apis.user.getOrganizations();
      if (response?.status === 1) {
        let { data = [] } = response?.result;
        data = await Promise.all(
          data.map(async (item) => ({
            ...item,
            imageUrl: item?.avatarUrl,
          })),
        );
        dispatch(actions.user.updateListOrganization(data || []));
      }
    } catch (error) {
      // TODO: optimize error handling later
      if (error?.message === 'forbidden') history.push(ROUTES.UNAUTHORIZED);
      else enqueueSnackbar(t(error?.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleClickItem = (item) => {
    dispatch(actions.user.updateSelectedOrganization(item));
    history.push(`/organization/${item.id}/statistic`);
    onClose();
  };

  const getUserInfo = () => {
    const { phoneNumber, email, name } = userInfo;
    if (email) return email;
    if (phoneNumber) return formatPhoneNumber(phoneNumber);
    return name;
  };

  const checkSelected = (id) => selected?.id === id;

  const handleLogout = () => {
    handleReceivingTokens({ token: '', refreshToken: '' });
    dispatch(actions.user.updateUser({}));
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  if (loading) return <></>;

  return (
    <StyledSelectOrganization>
      <Box className="title">
        <img src="/logo-aihub-dark.svg" alt="logo" />
      </Box>
      <Divider />
      <Box className="content">
        <Box className="account-box">
          <BadgeAvatar name={userInfo?.name} width={24} />
          <Typography variant="body2">{getUserInfo()}</Typography>
        </Box>
        <Typography fontSize={24} mb={2} mt={1} fontWeight={500}>
          {userInfo?.active ? t('chooseOrganization') : t('accountInactive')}
        </Typography>
        <Box className="organization-box organization-container">
          {userInfo?.active && list.length === 0 && (
            <Typography>{t('organizationUnauthorized')}</Typography>
          )}
          {userInfo?.active &&
            list.map((organization) => (
              <Box key={organization.id} className="organization-box">
                <Box
                  className={`organization-item ${
                    checkSelected(organization.id) ? 'selected' : ''
                  }`}
                  onClick={() => handleClickItem(organization)}
                >
                  <BadgeAvatar
                    img={organization.imageUrl}
                    name={organization.name}
                    width={32}
                  />
                  <Typography>{organization.name}</Typography>
                  {checkSelected(organization.id) && (
                    <Typography ml="auto">
                      <Check />
                    </Typography>
                  )}
                </Box>
                <Divider className="divider" />
              </Box>
            ))}
        </Box>
        <Button
          onClick={handleLogout}
          variant="contained"
          className="logout-button"
        >
          {t('logout')}
        </Button>
      </Box>
    </StyledSelectOrganization>
  );
};

export default SelectOrganization;
