import { Accordion, AccordionSummary } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR, TRANSPARENT_COLOR } from '@src/styles/color';

export const StyledAccordion = styled(Accordion)`
  &:not(:last-child) {
    border-bottom: 0;
  }
  &::before {
    display: none;
  }

  .title {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: ${({ theme }) =>
    theme.palette.mode === 'dark' ? COLOR.white[8] : COLOR.ink[8]};
  flex-direction: row-reverse;
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(90deg);
  }
  & .MuiAccordionSummary-content {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }

  background-color: transparent !important;
  margin-bottom: 10px !important;

  :after {
    content: '';
    position: absolute !important;
    bottom: 0px;
    left: 2%;
    width: 96%;
    border-bottom: 1px solid ${({ theme }) => theme.palette.divider} !important;
  }
`;

export const StyledAppEvaluationDetail = styled('div')`
  h3 {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
  }
  .info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 2fr));
    grid-auto-rows: 80px;
    grid-gap: 5px;

    .attribute {
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .status {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .title-app-box {
    font-size: 24px;
    padding: 0 16px 16px 0px !important;

    .title-app {
      font-size: 20px;
      font-weight: 700;
    }
  }
`;

export const StyledStatusLabel = styled('div')`
  .center {
    margin: 0 auto;
  }

  .label {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 100px;
    width: 140px;
    text-align: center;
    white-space: nowrap;
  }

  .published {
    color: ${COLOR.green[80]};
    background-color: ${TRANSPARENT_COLOR.success};
  }

  .banned {
    color: ${COLOR.error.base};
    background-color: ${COLOR.red[8]};
  }

  .waitingReview {
    color: ${COLOR.warning.base};
    background-color: ${TRANSPARENT_COLOR.primary};
  }

  .approved {
    color: ${COLOR.success.base};
    background-color: ${TRANSPARENT_COLOR.success};
  }

  .rejected {
    color: ${COLOR.error.base};
    background-color: ${COLOR.red[8]};
  }

  .publish-scheduled {
    color: ${COLOR.warning.base};
    background-color: ${TRANSPARENT_COLOR.primary};
  }
`;

export const StyledAppEvaluation = styled('div')`
  .no-data-img {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      width: 30vw;
    }
  }
`;
