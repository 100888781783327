import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { validAspectRatio, validFile, validSize } from '@src/utils/checkValid';
import { InsertPhoto } from '@mui/icons-material';
import PreviewImage from './PreviewImage';
import { StyledFileDropzone } from './index.style';

const FileDropzone = ({
  fileUrl,
  clearFile,
  fileType,
  acceptFile,
  disabled,
  onAddFile,
  onClearFile,
  onDeleteFile,
  maxSize,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  width,
  height,
  aspectRatios,
  isError,
  ratioCrop,
}) => {
  const { t } = useTranslation();

  const fileInputRef = useRef(null);

  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [formatError, setFormatError] = useState(false);
  const [oversize, setOverSize] = useState(false);
  const [isCropped, setIsCropped] = useState(false);

  const handleResetError = () => {
    setFormatError(false);
    setOverSize(false);
  };

  const handleReset = () => {
    onDeleteFile(null);
    setPreview([]);
    handleResetError();
  };

  const handleChangeFile = (e) => {
    if (ratioCrop && isCropped) setIsCropped(false);
    if (disabled) return;
    const { files } = e.target;

    handleResetError();

    if (!files) return;

    if (!validFile(files[0].name, fileType)) {
      setFormatError(true);
      return;
    }
    if (!validSize(files[0].size, maxSize)) {
      setOverSize(true);
      return;
    }

    const objectUrl = URL.createObjectURL(files[0]);

    setPreview(objectUrl);
    setFile(files[0]);
    fileInputRef.current.value = null;
  };

  const handleCropImage = (event) => {
    const img = event.target;
    const { type = 'image/jpeg' } = file || {};
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const { naturalWidth, naturalHeight } = img;
    const isWidthShorter = naturalWidth < naturalHeight;
    const maxCanvasSize = isWidthShorter ? naturalWidth : naturalHeight; // Kích thước tối đa cho chiều rộng hoặc chiều cao của canvas cắt

    canvas.width = naturalWidth;
    canvas.height = naturalHeight;

    // Vẽ ảnh lên canvas với tỉ lệ đã tính
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

    // Tính vị trí và kích thước cho phần cắt (lấy trung tâm)
    let cropWidth = isWidthShorter // Chiều rộng của phần cắt
      ? maxCanvasSize
      : maxCanvasSize * ratioCrop;
    let cropHeight = isWidthShorter // Chiều cao của phần cắt
      ? maxCanvasSize / ratioCrop
      : maxCanvasSize;
    if (cropWidth > naturalWidth) {
      cropWidth = naturalWidth;
      cropHeight = naturalWidth / ratioCrop;
    }
    if (cropHeight > naturalHeight) {
      cropHeight = naturalHeight;
      cropWidth = naturalHeight * ratioCrop;
    }

    const x = (canvas.width - cropWidth) / 2; // Tọa độ x của phần cắt
    const y = (canvas.height - cropHeight) / 2; // Tọa độ y của phần cắt

    // Tạo ảnh mới từ phần cắt
    const croppedImageData = ctx.getImageData(x, y, cropWidth, cropHeight);

    const croppedCanvas = document.createElement('canvas');
    const croppedCtx = croppedCanvas.getContext('2d');
    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    croppedCtx.putImageData(croppedImageData, 0, 0);

    // Chuyển canvas cắt thành URL cho ảnh
    const croppedDataURL = croppedCanvas.toDataURL(type);
    // Set state để hiển thị ảnh cắt
    setPreview(croppedDataURL);

    // Lưu ảnh từ canvas thành một file
    if (file) {
      croppedCanvas.toBlob((blob) => {
        const croppedImageFile = new File([blob], file.name, {
          type,
          lastModified: Date.now(),
        });
        setFile(croppedImageFile);
        onAddFile(croppedImageFile);
      }, type);
    }
    setIsCropped(true);
  };

  const handleImageLoad = (imgRef, event) => {
    if (ratioCrop && isCropped) return;

    const { naturalHeight, naturalWidth } = imgRef.current;
    let isValidWidth = true;
    if (minWidth) isValidWidth = naturalWidth >= minWidth;
    if (isValidWidth && maxWidth) isValidWidth = naturalWidth <= maxWidth;

    let isValidHeight = true;
    if (minHeight) isValidHeight = naturalHeight >= minHeight;
    if (isValidHeight && maxHeight) isValidHeight = naturalHeight <= maxHeight;

    let isValidAspectRatio = true;
    if (aspectRatios && !ratioCrop)
      isValidAspectRatio = validAspectRatio(
        naturalWidth,
        naturalHeight,
        aspectRatios,
      );

    if (!isValidWidth || !isValidHeight || !isValidAspectRatio) {
      setOverSize(true);
      setPreview(null);
      onAddFile(null);
      return;
    }

    if (
      ratioCrop &&
      event &&
      ratioCrop.toFixed(2) !== (naturalWidth / naturalHeight).toFixed(2)
    ) {
      handleCropImage(event);
      return;
    }

    if (file) onAddFile(file);
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteFilePreview = () => {
    if (disabled) return;

    handleResetError();

    setPreview(null);
    onAddFile(null);
  };

  useEffect(() => {
    if (clearFile) {
      handleReset();
      onClearFile(false);
    }
  }, [clearFile]);

  return (
    <StyledFileDropzone
      width={width}
      height={height}
      iserror={isError || formatError || oversize ? 1 : 0}
    >
      <Box className="dropzone pointer" onClick={openFileDialog}>
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          disabled={disabled}
          accept={acceptFile}
          onChange={handleChangeFile}
        />

        {preview || fileUrl ? (
          <PreviewImage
            image={preview || fileUrl}
            onDelete={handleDeleteFilePreview}
            onImageLoad={handleImageLoad}
            disabled={disabled}
          />
        ) : (
          <InsertPhoto color="info" />
        )}
      </Box>
      {(formatError || oversize) && (
        <Box className="error">
          {formatError && (
            <Typography variant="body2" color="error" noWrap>
              {t('formatError')}
            </Typography>
          )}
          {oversize && (
            <Typography variant="body2" color="error" noWrap>
              {t('sizeError')}
            </Typography>
          )}
        </Box>
      )}
    </StyledFileDropzone>
  );
};

export default FileDropzone;
