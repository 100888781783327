import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as SendIcon } from '@src/assets/icons/send.svg';
import { Close, Delete, Edit } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { COLOR } from '@src/styles/color';
import Popup from '@src/components/Popup';

const MAX_LENGTH = 250;

const ReplyComment = ({
  isReplying,
  setIsReplying,
  handleReplyComment,
  replyData,
  setReplyData,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.user);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [loading, setLoading] = useState(false);
  const { content = '', createdBy } = replyData || {};
  const { name, updatedAt } = createdBy || {};

  const handleChangeContent = (e) => {
    if (e.target.value.length > MAX_LENGTH) return;
    setReplyContent(e.target.value);
  };

  const handleSendReply = async () => {
    try {
      setLoading(true);
      const res = await handleReplyComment({
        reply: { content: replyContent },
      });
      if (res?.status === 1) {
        setIsReplying(false);
        setReplyData({
          content: replyContent,
          createdBy: { name: userInfo.name, updatedAt: moment() },
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReply = async () => {
    try {
      setLoading(true);
      const res = await handleReplyComment({ reply: { content: '' } });
      if (res?.status === 1) {
        setIsReplying(false);
        setReplyData(null);
        enqueueSnackbar(t('Xóa phản hồi bình luận thành công'), {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (content) setReplyContent(content);
  }, [content]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography fontWeight={700} mr="auto">
          Phản hồi
        </Typography>
        {isReplying && (
          <IconButton
            onClick={() => {
              setIsReplying(!isReplying);
              setReplyContent(content);
              if (!updatedAt) setReplyData(null);
            }}
            color="primary"
            size="small"
          >
            <Close />
          </IconButton>
        )}
        {!isReplying && replyData && (
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              color="primary"
              startIcon={<Edit />}
              onClick={() => setIsReplying(true)}
            >
              Chỉnh sửa
            </Button>
            <Button
              color="error"
              startIcon={<Delete />}
              onClick={() => setOpenConfirmPopup(true)}
            >
              Xóa
            </Button>
          </Box>
        )}
      </Box>
      {isReplying ? (
        <TextField
          multiline
          fullWidth
          placeholder="Nhập nội dung phản hồi"
          variant="outlined"
          size="small"
          rows={4}
          value={replyContent}
          onChange={handleChangeContent}
          InputProps={{
            endAdornment: (
              <Typography
                variant="body2"
                color="textSecondary"
                mt="auto"
                ml={1}
              >
                {replyContent.length}/{MAX_LENGTH}
              </Typography>
            ),
          }}
          disabled={loading}
        />
      ) : (
        <Box borderRadius={4} bgcolor={COLOR.darkBlue[4]} padding={1}>
          <Typography fontWeight={400} fontSize={16}>
            {content}
          </Typography>
          <Typography
            fontSize={14}
            fontWeight={400}
            color="textSecondary"
            mt={1}
          >
            được phản hồi bởi {name} - {moment(updatedAt).format('DD/MM/YYYY')}
          </Typography>
        </Box>
      )}
      {isReplying && (
        <Box display="flex" justifyContent="end" mt={1}>
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={
              replyContent?.length === 0 || replyContent?.length > MAX_LENGTH
            }
            startIcon={<SendIcon />}
            onClick={handleSendReply}
            loading={loading}
          >
            Gửi phản hồi
          </LoadingButton>
        </Box>
      )}
      <Popup
        open={openConfirmPopup}
        onClose={() => setOpenConfirmPopup(false)}
        title={t('confirm')}
        content="Bạn có chắc muốn xóa phản hồi bình luận này không?"
        okMessage={t('confirm')}
        cancelMessage={t('cancel')}
        onOk={handleDeleteReply}
        titleColor={COLOR.warning.base}
      />
    </>
  );
};

export default ReplyComment;
