import moment from 'moment';

const LANGUAGE = { VN: 'vi', EN: 'en' };

const SERVICE = {
  ACCOUNT: 'ACCOUNT',
  API_MANAGEMENT: 'API_MANAGEMENT',
};

const ERROR_CODE_SYSTEM = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
};

const ERROR_CODE_ACCOUNT_SERVICE = {
  // users 1000 - 1999
  USER_EXISTS: 1000,
  EMAIL_OR_PASSWORD_WRONG: 1001,
  USER_NOT_FOUND: 1002,
  INVALID_USER: 1003,
};

const ERROR_CODE_API_MANAGEMENT = {
  // iam 1000 - 1099
  IAM_GET_ACCESS_TOKEN_ERROR: 1000,

  // user 1100 - 1199
  USER_NOT_FOUND: 1100,

  // app 1200 - 1299
  APP_EXIST: 1200,
  APP_NOT_FOUND: 1201,

  // app version 1300 - 1399
  APP_VERSION_EXIST: 1300,
  PREPARE_SUBMISSION_APP_VERSION_EXIST: 1301,
  APP_VERSION_NOT_FOUND: 1302,
  APP_VERSION_STATUS_INVALID: 1303,

  // organization 4300 - 4399
  ORGANIZATION_NOT_FOUND: 4300,

  // category 4400 - 4499
  PRIMARY_CATEGORY_NOT_FOUND: 4400,
  SECONDARY_CATEGORY_NOT_FOUND: 4401,
  PRIMARY_CATEGORY_SECONDARY_CATEGORY_DUPLICATE: 4402,
  PRIMARY_CATEGORY_ALREADY_SECONDARY_CATEGORY: 4403,
  SECONDARY_CATEGORY_ALREADY_PRIMARY_CATEGORY: 4404,
};

const PAGINATION_LIMIT = 10;

const API_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const ALL = 'all';

const PLATFORM = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WEB_APP: 'WEB_APP',
};

const FILTER_APP_STATUS = {
  PREPARE_PUBLISH: 'PREPARE_PUBLISH',
  PUBLISHED: 'PUBLISHED',
  BANNED: 'BANNED',
  REJECTED: 'REJECTED',
};

const APP_VERSION_STATUS = {
  WAITING_REVIEW: 'WAITING_REVIEW',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  DRAFT: 'DRAFT',
  BANNED: 'BANNED',
  PUBLISH_SCHEDULED: 'PUBLISH_SCHEDULED',
  APPROVED: 'APPROVED',
};

const PUBLISH_METHOD = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO',
  SCHEDULE: 'SCHEDULE',
};
const REPORT_GROUP_OPTION = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
};
const APP_VERSION_REVIEW_ACTION = {
  SUBMIT: 'SUBMIT',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  BAN: 'BAN',
  UNBAN: 'UNBAN',
  CANCEL_SUBMIT: 'CANCEL_SUBMIT',
  PUBLISH: 'PUBLISH',
};

const SCREEN_SHOTS_TYPE = {
  PHONE: 'PHONE',
  WEBSITE: 'WEBSITE',
};

const DEFAULT_APP_ICON =
  'https://d29fhpw069ctt2.cloudfront.net/clipart/90673/preview/iOS_7_Icon_Template_preview_4ffa.png';

const AGE_RATING = {
  FOUR: 4,
  NIGHT: 9,
  TWELVE: 12,
  SEVENTEEN: 17,
};

const APP_NAME_MAX_LENGTH = 30;
const SUB_TITLE_MAX_LENGTH = 30;

const URL_REGEX = new RegExp(
  '^([a-zA-Z]+:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i',
);

const VERSION_IMAGE_TYPE_FILE = ['png', 'jpeg', 'jpg', 'gif', 'svg', 'webp'];

const MAX_FILE_SIZE = {
  ONE_MB: 1 * 1024 * 1024,
  THREE_MB: 3 * 1024 * 1024,
  EIGHT_MB: 8 * 1024 * 1024,
  FIVE_TEEN_MB: 15 * 1024 * 1024,
};

const VERSION_REGEX = /^(\d+\.)?(\d+\.)?(\d+)$/;

const REGEX_IOS_LINK =
  /https:\/\/(?:itunes\.apple\.com\/(?:.*\/)?(id(\d+))|apps\.apple\.com\/(?:.*\/)?app\/(?:.*\/)(id(\d+)))/i;

const REGEX_ANDROID_LINK =
  /https:\/\/play\.google\.com\/store\/apps\/details\?id=([a-zA-Z0-9._]+)$/;

const PAYMENT_METHOD = {
  ONE_TIME: 'ONE_TIME',
  RECURRING: 'RECURRING',
};

const TRANSACTION_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

const TRANSACTION_ACTION = {
  REGISTER: 'REGISTER',
  RENEW: 'RENEW',
  CANCEL: 'CANCEL',
  RENEW_ERROR: 'RENEW_ERROR',
};

const INIT_PAGINATION = {
  page: 1,
  limit: 10,
  total: 0,
  totalPages: 0,
};

const DATE_TIME_PICKER_TYPES = {
  DATE: 'DATE',
  TIME: 'TIME',
  DATE_TIME: 'DATE_TIME',
};

const BADGE_TYPE = { DOT: 'dot', IMAGE: 'image', ICON: 'icon' };

const IMAGE_ACCEPT_FILE = '.jpeg,.png,.jpg,.gif,.svg,.webp';

const MAX_FILE_SIZE_MEGABYTE = {
  ONE_MB: 1,
  THREE_MB: 3,
};

const FILTER_OPTION = {
  LATEST: 'LATEST',
  MOST_USEFUL: 'MOST_USEFUL',
  WORST: 'WORST',
};

const DATE_TIME_FORMAT = 'HH:mm:ss - DD/MM/YYYY';

const START_TIME_DEFAULT = moment()
  .subtract(1, 'months')
  .startOf('day')
  .subtract(7, 'hours')
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const END_TIME_DEFAULT = moment()
  .endOf('day')
  .subtract(7, 'hours')
  .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

const MAX_SEARCH_LENGTH = 50;

// Chứa các ký tự đặc biệt
/* eslint-disable no-useless-escape */
const REGEX_SPECIAL_CHARACTERS = /[\,\.\^\$\*\+\?\[\]\|\(\)\\]/;

const DEBOUNCE_AUTO_COMPLETE = 500;

export {
  LANGUAGE,
  SERVICE,
  ERROR_CODE_SYSTEM,
  ERROR_CODE_ACCOUNT_SERVICE,
  ERROR_CODE_API_MANAGEMENT,
  PAGINATION_LIMIT,
  API_METHOD,
  ALL,
  PLATFORM,
  FILTER_APP_STATUS,
  DEFAULT_APP_ICON,
  APP_VERSION_STATUS,
  AGE_RATING,
  APP_NAME_MAX_LENGTH,
  SUB_TITLE_MAX_LENGTH,
  URL_REGEX,
  PUBLISH_METHOD,
  VERSION_IMAGE_TYPE_FILE,
  VERSION_REGEX,
  REGEX_ANDROID_LINK,
  REGEX_IOS_LINK,
  MAX_FILE_SIZE,
  APP_VERSION_REVIEW_ACTION,
  SCREEN_SHOTS_TYPE,
  TRANSACTION_STATUS,
  TRANSACTION_ACTION,
  INIT_PAGINATION,
  PAYMENT_METHOD,
  DATE_TIME_PICKER_TYPES,
  BADGE_TYPE,
  IMAGE_ACCEPT_FILE,
  MAX_FILE_SIZE_MEGABYTE,
  FILTER_OPTION,
  DATE_TIME_FORMAT,
  START_TIME_DEFAULT,
  END_TIME_DEFAULT,
  REPORT_GROUP_OPTION,
  MAX_SEARCH_LENGTH,
  REGEX_SPECIAL_CHARACTERS,
  DEBOUNCE_AUTO_COMPLETE,
};
