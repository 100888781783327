import { SERVICE } from '@src/constants';
import api from './api';

export const getTransactions = async (organizationId, appId, params) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps/${appId}/transactions`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
