import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledCopyToClipBoard = styled(Box)`
  margin: 0 3px;

  .copied-icon-container {
    .copied-icon {
      color: ${COLOR.success.base} !important;
    }
  }
`;
