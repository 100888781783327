import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ROUTE from '@src/constants/route';
import { API_METHOD } from '@src/constants';
import apis from '@src/apis';

import appRoutes from './appRoutes';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const [isPermission, setIsPermission] = useState(null);
  const { organization } = useSelector((state) => state.user);

  const checkUserPermission = async () => {
    try {
      const EXCLUDING_PERMISSION_PATHS = [ROUTE.HOME];
      const excludingPermission = EXCLUDING_PERMISSION_PATHS.includes(path);
      if (!path || excludingPermission) {
        setIsPermission(true);
        return;
      }

      const organizationId = organization?.selected?.id;
      if (!organizationId) setIsPermission(false);

      const route = appRoutes
        .filter((item) => item.isMenu)
        .find((menu) => path === menu.path);

      if (route) {
        const response = await apis.auth.checkUserPermission({
          method: API_METHOD.GET,
          uri: route.path,
          organizationId,
        });
        if (response?.status) {
          setIsPermission(true);
          return;
        }
      }

      setIsPermission(false);
    } catch (error) {
      setIsPermission(false);
    }
  };

  useEffect(() => {
    checkUserPermission();
  }, [path, organization?.selected?.id]);

  if (isPermission === null) return null;

  return (
    <Route
      path
      {...rest}
      render={(props) =>
        isPermission ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTE.UNAUTHORIZED} />
        )
      }
    />
  );
};

export default PrivateRoute;
