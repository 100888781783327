/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TableCell,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  UnfoldMore,
} from '@mui/icons-material';
import { PAGINATION_LIMIT } from '@src/constants';
import { getByPath } from '@src/utils/object';
import { StyledCustomTable, StyledTableCell } from './index.style';
import CustomPagination from '../CustomPagination';

const DroppableComponent = ({ onDragEnd, onBeforeDragStart, ...props }) => (
  <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onBeforeDragStart}>
    <Droppable droppableId="droppable" direction="vertical">
      {(provided) => (
        <TableBody ref={provided.innerRef} {...props.droppableProps} {...props}>
          {props.children}
          {provided.placeholder}
        </TableBody>
      )}
    </Droppable>
  </DragDropContext>
);
const DraggableTableCell = ({ isDragging, ...props }) => (
  <StyledTableCell {...props}>{props.children}</StyledTableCell>
);
const DraggableComponent = ({ id, index, ...props }) => (
  <Draggable draggableId={`draggable-${id}`} index={index}>
    {(provided) => (
      <TableRow ref={provided.innerRef} {...provided.draggableProps} {...props}>
        <DraggableTableCell {...provided.dragHandleProps}>
          <DragHandleIcon />
        </DraggableTableCell>
        {props.children}
      </TableRow>
    )}
  </Draggable>
);
const ColGroup = ({ colWidths = [] }) => {
  const cols = [];
  const len = colWidths.length;
  for (let i = len - 1; i >= 0; i -= 1) {
    const width = colWidths[i];
    cols.unshift(<col key={i} style={{ width }} />);
  }
  return <colgroup>{cols}</colgroup>;
};
const CustomTable = ({
  heads,
  items,
  actions,
  sort,
  pagination = { page: 1, limit: PAGINATION_LIMIT, totalPages: 1, total: 0 },
  onChangePagination,
  onChangeSort,
  disablePagination = false,
  loading,
  border,
  reorderable = false,
  onReorder,
  onBeforeDragStart,
  onClickRow = () => {},
  isStickyHeader = false,
  maxHeight = 'unset',
  maxWidth = 'unset',
}) => {
  const { t } = useTranslation();
  const { page: currentPage, limit, total } = pagination;

  const [data, setData] = useState([]);

  const onDragEnd = useCallback(
    (event) => {
      if (
        !event.destination ||
        event.source.index === event.destination.index
      ) {
        return;
      }
      const reorderedData = [...data];
      const [removed] = reorderedData.splice(event.source.index, 1);
      reorderedData.splice(event.destination.index, 0, removed);
      if (onReorder) onReorder(reorderedData);
      setData(reorderedData);
    },
    [onReorder, data],
  );
  const onBeforeDragStartHook = useCallback(
    (event) => {
      if (onBeforeDragStart) onBeforeDragStart(event);
    },
    [onBeforeDragStart],
  );
  const droppableProps = reorderable
    ? {
        onDragEnd,
        onBeforeDragStart: onBeforeDragStartHook,
      }
    : {};

  useEffect(() => {
    setData(items);
  }, [items]);

  const colWidths = useMemo(() => {
    const widths = heads.map(({ width }) => width);
    if (reorderable) widths.unshift(80);
    return widths;
  }, [heads, reorderable]);

  const renderSorter = (head) => {
    if (!head.sortable) return '';
    if (sort) {
      const [sortField, sorter] = sort.split('_');
      if (head.valueName === sortField)
        return sorter === 'asc' ? (
          <IconButton onClick={() => onChangeSort(`${head.valueName}_desc`)}>
            <KeyboardArrowDown />
          </IconButton>
        ) : (
          <IconButton onClick={() => onChangeSort(`${head.valueName}_asc`)}>
            <KeyboardArrowUp />
          </IconButton>
        );
    }
    return (
      <IconButton onClick={() => onChangeSort(`${head.valueName}_asc`)}>
        <UnfoldMore />
      </IconButton>
    );
  };

  return (
    <StyledCustomTable>
      {loading ? (
        <CircularProgress color="primary" />
      ) : (
        <TableContainer
          className="table-container"
          sx={{ maxHeight, maxWidth }}
        >
          <Table stickyHeader={isStickyHeader}>
            <ColGroup colWidths={colWidths} />
            <TableHead>
              <TableRow>
                {data.length > 0 && reorderable && <StyledTableCell />}
                {heads.map((head, columnNo) => (
                  <StyledTableCell
                    key={columnNo}
                    border={border}
                    align={head.align}
                  >
                    {head.valueName === 'index' ? t(head.label) : head.label}
                    {renderSorter(head)}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              {...droppableProps}
              component={reorderable ? DroppableComponent : undefined}
            >
              {!data.length && !loading && (
                <TableRow>
                  <StyledTableCell
                    className="body-cell"
                    colSpan={heads.length}
                    align="center"
                  >
                    {t('noDataFound')}
                  </StyledTableCell>
                </TableRow>
              )}
              {!loading &&
                data.map((item, index) => (
                  <TableRow
                    className="table-row"
                    key={uuidv4()}
                    id={item.id}
                    component={reorderable ? DraggableComponent : undefined}
                    index={index}
                    onClick={() => {
                      onClickRow(item);
                    }}
                  >
                    {heads.map((head) => {
                      if (item.id === 'totalRow') {
                        return (
                          <TableCell
                            key={uuidv4()}
                            className="table-cell"
                            align={head.align}
                          >
                            {head.valueName !== 'actions' &&
                            head.valueName !== 'index' &&
                            (item[head.valueName] || item[head.valueName] === 0)
                              ? item[head.valueName]
                              : ' '}
                          </TableCell>
                        );
                      }
                      return (
                        <StyledTableCell
                          key={head.valueName}
                          border={border}
                          align={head.align}
                        >
                          {head.valueName === 'index' &&
                            (currentPage - 1) * limit + index + 1}
                          {head.valueName === 'actions' &&
                            actions.length &&
                            Object.keys(item).length !== 0 &&
                            actions.map((action, ind) => (
                              <Tooltip
                                key={uuidv4()}
                                title={
                                  typeof action.title === 'function'
                                    ? action.title(item)
                                    : action.title
                                }
                              >
                                <span>
                                  <IconButton
                                    key={ind}
                                    className="icon-button"
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      action.onClick(item);
                                    }}
                                    disabled={action.disable}
                                  >
                                    {typeof action.icon === 'function'
                                      ? action.icon(item)
                                      : action.icon}
                                  </IconButton>
                                </span>
                              </Tooltip>
                            ))}
                          {head.valueName !== 'actions' &&
                            head.valueName !== 'index' &&
                            item[head.valueName] === 0 &&
                            0}
                          {head.valueName !== 'actions' &&
                            head.valueName !== 'index' &&
                            getByPath(item, head.valueName)}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!disablePagination && !loading && (
        <CustomPagination
          currentPage={currentPage}
          limit={limit}
          total={total}
          onChangePagination={onChangePagination}
        />
      )}
    </StyledCustomTable>
  );
};
export default CustomTable;
