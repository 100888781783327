const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const regexPassword = /^.{8,}$/;
const regexName = /^[\p{L}0-9]+(([',. -][\p{L}0-9 ])?[\p{L}0-9]*)*$/u;
const regexPhone =
  /(^1[8|9]00([0-9]{4})$)|(^02([0-9]{9})$)|(^0[3|5|7|8|9]([0-9]{8})$)\b/;
const regexUrl = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', // validate fragment locator
  'i',
);
const regexUsername = new RegExp(
  `^(${regexEmail.source}|${regexPhone.source})$`,
);
const regexDomain = /^(?:[-A-Za-z0-9]+\.)+[A-Za-z]{2,6}$/;
const regexYoutube = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;

// validate redirect uri: http, https, domain
const regexRedirectURI = /^https?:\/\/?(?:[-A-Za-z0-9]+\.)+[A-Za-z]{2,6}$/;

export {
  regexEmail,
  regexPassword,
  regexName,
  regexPhone,
  regexUrl,
  regexUsername,
  regexDomain,
  regexYoutube,
  regexRedirectURI,
};
