import { Box } from '@mui/material';
import { useParams, matchPath, useHistory } from 'react-router-dom';
import ROUTES from '@src/constants/route';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import apis from '@src/apis';
import { DEFAULT_APP_ICON } from '@src/constants';
import TitleApp from './TitleApp';
import SidebarApp from './SidebarApp';
import { StyledAppDetail } from './index.style';
import AppCreateVersion from '../AppCreateVersion';
import AppDetailInfo from '../AppDetailInfo';
import AppEvaluation from '../AppEvaluation';
import AppEvaluationDetail from '../AppEvaluation/AppEvaluationDetail';
import AppPaymentHistory from '../AppPaymentHistory';
import AppPackages from '../AppPackages';
import AppWebhook from '../AppWebhook';
import AppComment from '../AppComment';
import CredentialApp from '../CredentialApp';

const AppDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { organizationId, appId, versionId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [app, setApp] = useState(null);

  const getVersionDataById = () => {
    if (versionId) {
      if (app?.prepareSubmissionAppVersion?.id === versionId) {
        return app?.prepareSubmissionAppVersion;
      }
      if (app?.latestPublishedAppVersion?.id === versionId) {
        return app?.latestPublishedAppVersion;
      }
      return null;
    }
    return null;
  };

  const handleBackApps = () =>
    history.push(ROUTES.APP.replace(':organizationId', organizationId));

  const getImageUrl = (appData) => {
    if (appData?.latestPublishedAppVersion) {
      return appData.latestPublishedAppVersion.appImageUrl;
    }

    return (
      appData?.prepareSubmissionAppVersion?.appImageUrl || DEFAULT_APP_ICON
    );
  };

  const fetchApp = async () => {
    try {
      const res = await apis.app.getApp(organizationId, appId);
      if (res?.status)
        setApp({
          ...res?.result,
          imageUrl: await getImageUrl(res?.result),
        });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchApp();
  }, [organizationId, appId]);

  const currentPath = window.location.pathname;

  const renderContent = () => {
    if (matchPath(currentPath, ROUTES.APP_DETAIL_INFO))
      return <AppDetailInfo />;

    if (matchPath(currentPath, ROUTES.APP_EVALUATION_DETAIL))
      return (
        <AppEvaluationDetail
          organizationId={organizationId}
          appId={appId}
          versionId={versionId}
        />
      );

    if (matchPath(currentPath, ROUTES.APP_EVALUATION))
      return <AppEvaluation organizationId={organizationId} appId={appId} />;

    if (matchPath(currentPath, ROUTES.PAYMENT_HISTORY))
      return (
        <AppPaymentHistory organizationId={organizationId} appId={appId} />
      );

    if (matchPath(currentPath, ROUTES.APP_PACKAGES))
      return <AppPackages app={app} />;

    if (matchPath(currentPath, ROUTES.APP_WEBHOOK)) return <AppWebhook />;

    if (matchPath(currentPath, ROUTES.CREDENTIAL_APP)) return <CredentialApp />;

    if (matchPath(currentPath, ROUTES.APP_COMMENT))
      return (
        <AppComment
          organizationId={organizationId}
          appId={appId}
          rating={app?.rating}
        />
      );

    return (
      <AppCreateVersion
        versionData={getVersionDataById()}
        organizationId={organizationId}
        appId={appId}
        onChangeApp={fetchApp}
        isExistOldVersion={!!app?.latestPublishedAppVersion}
      />
    );
  };

  const isAppEvaluationDetail = matchPath(
    currentPath,
    ROUTES.APP_EVALUATION_DETAIL,
  );

  return (
    <StyledAppDetail>
      <>
        <TitleApp
          name={app?.name}
          imgSrc={app?.imageUrl}
          onBack={handleBackApps}
          isBack={!isAppEvaluationDetail}
        />
      </>
      <Box className="content">
        {!matchPath(currentPath, ROUTES.APP_EVALUATION_DETAIL) && (
          <>
            <Box className="sidebar-box">
              <SidebarApp
                preVersion={app?.prepareSubmissionAppVersion}
                lastVersion={app?.latestPublishedAppVersion}
                organizationId={organizationId}
                appId={app?.id}
                versionId={versionId}
                resetApp={fetchApp}
              />
            </Box>
          </>
        )}
        <Box p={2} pt={isAppEvaluationDetail ? 0 : 2} flexGrow={1}>
          {renderContent()}
        </Box>
      </Box>
    </StyledAppDetail>
  );
};

export default AppDetail;
