import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { formatNumber as formatNumberUtil } from '@src/utils/formatNumber';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { ArrowDownwardRounded, ArrowUpwardRounded } from '@mui/icons-material';
import { getByPath } from '@src/utils/object';
import { MIN_HEIGHT_CHART } from '@src/constants/chart';
import Tooltip from '../Tooltip';
import { StyledCustomTable, StyledTableCell } from './index.style';

const CustomTable = ({
  heads = [],
  data = [],
  isShowMedal = true,
  loading,
}) => {
  const { t } = useTranslation();

  return (
    <StyledCustomTable>
      <TableContainer className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              {isShowMedal && <StyledTableCell />}
              {heads.map((head) => (
                <StyledTableCell key={uuidv4()} align={head.align}>
                  {head.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={heads.length + 2}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {!data.length && (
                <TableRow>
                  <StyledTableCell
                    className="body-cell"
                    colSpan={heads.length + 2}
                    align="center"
                  >
                    {t('noData')}
                  </StyledTableCell>
                </TableRow>
              )}
              {data.map((item, index) => (
                <TableRow className="table-row" key={item.id}>
                  <StyledTableCell align="center">{index + 1}</StyledTableCell>
                  {isShowMedal && (
                    <StyledTableCell
                      align="center"
                      sx={{ writingMode: 'vertical-rl' }}
                    >
                      {index < 3 && (
                        <img
                          src={`/icons/${index + 1}.svg`}
                          alt="medal"
                          width="24px"
                          height="24px"
                        />
                      )}
                    </StyledTableCell>
                  )}
                  {heads.map((head) => (
                    <StyledTableCell key={head.valueName} align={head.align}>
                      {getByPath(item, head.valueName)}
                    </StyledTableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </StyledCustomTable>
  );
};

const RankTable = ({
  title,
  heads = [],
  data = [],
  limit,
  type,
  object,
  isDecrease,
  onChangeSort,
  status,
  loading,
  total,
  percent = 0,
}) => {
  const { t } = useTranslation();
  const { formatNumber } = useFormatNumber();

  return (
    <Box
      sx={{
        borderRadius: '12px',
        border: '1px solid rgba(60, 60, 67, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        p: '25px',
        minHeight: MIN_HEIGHT_CHART,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="center"
          gap={0.5}
          sx={{ cursor: 'pointer' }}
          onClick={onChangeSort}
        >
          <Typography variant="h4m">{title}</Typography>
          {isDecrease ? (
            <Tooltip
              title={t('viewTop', { limit, type, object, status })}
              placement="top"
              arrow
            >
              <ArrowDownwardRounded fontSize="small" onClick={onChangeSort} />
            </Tooltip>
          ) : (
            <Tooltip
              title={t('viewTop', { limit, type, object, status })}
              placement="top"
              arrow
            >
              <ArrowUpwardRounded fontSize="small" onClick={onChangeSort} />
            </Tooltip>
          )}
        </Box>
        {!!data?.length && (
          <Box display="flex" alignItems="flex-end" flexDirection="column">
            <Tooltip title={formatNumberUtil(total)} placement="top" arrow>
              <Typography variant="body1b">
                {t('total')}: {formatNumber(total)}
              </Typography>
            </Tooltip>
            <Typography variant="caption">
              {t('accountingTotal', { percent })}
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <CustomTable
          heads={heads}
          data={data}
          isShowMedal={isDecrease}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default RankTable;
