import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  IconButton,
  Box,
  Divider,
  Typography,
  MenuItem,
  ClickAwayListener,
  Dialog,
  Tooltip,
} from '@mui/material';
import { formatPhoneNumber } from '@src/utils/formatNumber';
import { ReactComponent as ArrowBack } from '@src/assets/icons/arrow-left.svg';
import { ReactComponent as ArrowForward } from '@src/assets/icons/arrow-right.svg';
import { Business, Info, LogoutOutlined } from '@mui/icons-material';
import BadgeAvatar from '@src/components/BadgeAvatar';
import SelectOrganization from '@src/containers/SelectOrganization';
import { handleReceivingTokens } from '@src/services/auth';
import ROUTES from '@src/constants/route';
import actions from '@src/redux/actions';
import { StyledBottom, StyledPopper, StyledMenu } from './index.style';

const Bottom = ({ isCollapse, setIsCollapse }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSelectOrganization, setOpenSelectOrganization] = useState(false);
  const [avatar, setAvatar] = useState('');

  const { t } = useTranslation();
  const { userInfo, organization } = useSelector((state) => state.user);

  const handleAvatarClick = (e) => setAnchorEl(e.currentTarget);

  const handleLogout = () => {
    handleReceivingTokens({ token: '', refreshToken: '' });
    dispatch(actions.user.updateUser({}));
    history.push(ROUTES.LOGIN);
  };

  const handleOpenSelectOrganization = () => {
    setOpenSelectOrganization(true);
  };

  const handleCloseSelectOrganization = () => {
    setOpenSelectOrganization(false);
  };

  const handleClickOrganizationInfo = () =>
    history.push(`/organization/${organizationId}`);

  const getUserInfo = () => {
    const { phoneNumber, email } = userInfo;
    if (phoneNumber) return formatPhoneNumber(phoneNumber);
    if (email) return email;
    return '';
  };

  const handleAvatar = async () => {
    const avatarUrl = organization.selected?.avatarUrl;
    setAvatar(avatarUrl);
  };

  useEffect(() => {
    handleAvatar();
  }, [organization.selected?.avatarUrl]);

  const renderLargeBottom = () => (
    <Box className="bottom-wrapper">
      <div>
        <IconButton className="avatar-wrapper" onClick={handleAvatarClick}>
          <div className="avatar">
            <BadgeAvatar
              active={userInfo?.id}
              name={organization?.selected?.name}
              number={new Date(userInfo?.createdAt)}
              smallImgWidth={20}
              img={avatar}
            />
          </div>
        </IconButton>
      </div>
      <Box className="user-info">
        <Tooltip title={organization?.selected?.name} placement="top" arrow>
          <Typography variant="subtitle1" className="name" noWrap>
            {organization?.selected?.name}
          </Typography>
        </Tooltip>
        <Typography variant="caption" className="bonus" noWrap>
          {getUserInfo()}
        </Typography>
      </Box>
      <Box className="close-sidebar">
        <IconButton
          className="button-close"
          onClick={() => setIsCollapse(!isCollapse)}
        >
          <ArrowBack />
        </IconButton>
      </Box>
    </Box>
  );

  const renderSmallBottom = () => (
    <Box className="small-bottom-wrapper">
      <div>
        <IconButton className="avatar-wrapper" onClick={handleAvatarClick}>
          <div className="avatar">
            <BadgeAvatar
              active={userInfo?.id}
              name={organization?.selected?.name}
              number={new Date(userInfo?.createdAt)}
              smallImgWidth={20}
            />
          </div>
        </IconButton>
      </div>
      <Box className="close-sidebar">
        <IconButton
          className="button-close"
          onClick={() => setIsCollapse(!isCollapse)}
        >
          <ArrowForward />
        </IconButton>
      </Box>
    </Box>
  );

  return (
    <StyledBottom>
      <Divider />
      {isCollapse ? renderSmallBottom() : renderLargeBottom()}

      {anchorEl && (
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <StyledPopper
            open={Boolean(anchorEl)}
            placement="top-end"
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            modifiers={[{ name: 'offset', options: { offset: [0, 12] } }]}
          >
            <StyledMenu>
              <MenuItem
                className="menu-item"
                onClick={handleOpenSelectOrganization}
              >
                <Business />
                {t('chooseOrganization')}
              </MenuItem>
              <MenuItem
                className="menu-item"
                onClick={handleClickOrganizationInfo}
              >
                <Info />
                {t('organizationInfo')}
              </MenuItem>
              <MenuItem className="menu-item red-color" onClick={handleLogout}>
                <LogoutOutlined />
                {t('logout')}
              </MenuItem>
            </StyledMenu>
          </StyledPopper>
        </ClickAwayListener>
      )}
      <Dialog
        open={openSelectOrganization}
        onClose={handleCloseSelectOrganization}
        maxWidth="sm"
        fullWidth
      >
        <SelectOrganization onClose={handleCloseSelectOrganization} />
      </Dialog>
    </StyledBottom>
  );
};

export default Bottom;
