import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { APP_VERSION_STATUS } from '@src/constants';
import { StyledStatusLabel } from './index.style';

const STATUS_CLASS_MAP = {
  [APP_VERSION_STATUS.PUBLISHED]: 'published',
  [APP_VERSION_STATUS.BANNED]: 'banned',
  [APP_VERSION_STATUS.WAITING_REVIEW]: 'waitingReview',
  [APP_VERSION_STATUS.APPROVED]: 'approved',
  [APP_VERSION_STATUS.REJECTED]: 'rejected',
  [APP_VERSION_STATUS.PUBLISH_SCHEDULED]: 'publish-scheduled',
};

const StatusLabel = ({ status, center }) => {
  const { t } = useTranslation();
  const labelClassName = STATUS_CLASS_MAP[status] || '';

  return (
    <StyledStatusLabel>
      <Typography className={`label ${labelClassName} ${center && 'center'}`}>
        {t(status)}
      </Typography>
    </StyledStatusLabel>
  );
};

export default StatusLabel;
