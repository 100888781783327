import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/lab';
import { COLOR } from '@src/styles/color';
import { DATE_TIME_PICKER_TYPES } from '@src/constants';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { ReactComponent as RefreshIcon } from '@src/assets/icons/refresh-2.svg';
import { ReactComponent as CalendarIcon } from '@src/assets/icons/calendar.svg';

import { StyledCustomDatePicker } from './index.style';

const CustomDatePicker = ({
  type,
  selectedDate,
  handleChangeDate,
  minDate,
  maxDate,
  width,
  textFieldDisable,
  disableFuture = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleFormatDateChange = (value) => {
    if (!value) return;
    // If it's a valid Date object, convert it to ISO string
    if (!Number.isNaN(value.getTime())) {
      const valueFormat = value.toISOString();
      handleChangeDate(valueFormat);
    }
  };

  return (
    <StyledCustomDatePicker width={width}>
      {type === DATE_TIME_PICKER_TYPES.DATE && (
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedDate || null}
          onChange={handleFormatDateChange}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              onClick={() => setOpen(true)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <CalendarIcon />
                  </IconButton>
                ),
              }}
              className="date-picker"
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
          disableFuture={disableFuture}
        />
      )}
      {type === DATE_TIME_PICKER_TYPES.TIME && (
        <TimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedDate || null}
          onChange={handleFormatDateChange}
          inputFormat="HH:mm"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              onClick={() => setOpen(true)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <AccessTimeIcon />
                  </IconButton>
                ),
              }}
              disabled={textFieldDisable}
              sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                  WebkitTextFillColor: COLOR.black,
                },
              }}
            />
          )}
          minTime={minDate}
          maxTime={maxDate}
          ampm={false}
          disabled={textFieldDisable}
        />
      )}
      {type === DATE_TIME_PICKER_TYPES.DATE_TIME && (
        <DateTimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedDate || ''}
          onChange={handleFormatDateChange}
          inputFormat="HH:mm - dd/MM/yyyy"
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              onClick={() => setOpen(true)}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <CalendarIcon />
                  </IconButton>
                ),
              }}
            />
          )}
          minDateTime={minDate}
          maxDateTime={maxDate}
          ampm={false}
        />
      )}
    </StyledCustomDatePicker>
  );
};

const CustomDatePickerRange = ({
  isRefresh = true,
  type = DATE_TIME_PICKER_TYPES.DATE_TIME,
  startDate = '',
  endDate = '',
  handleChangeStartDate,
  handleChangeEndDate,
  handleRefresh,
  minDate,
  maxDate,
  hasFromText = true,
  width,
  className,
  startAdornment,
  middleAdornment,
  disableFuture = true,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1.5}
      className={className}
    >
      {hasFromText ? (
        <Typography variant="body1" fontWeight={400} color={COLOR.text.primary}>
          {t('from')}
        </Typography>
      ) : (
        startAdornment
      )}
      <CustomDatePicker
        width={width}
        type={type}
        selectedDate={startDate}
        handleChangeDate={handleChangeStartDate}
        minDate={minDate && new Date(minDate)}
        maxDate={endDate && new Date(endDate)}
        textFieldDisable
        disableFuture={disableFuture}
      />
      {middleAdornment || (
        <Typography
          variant="body1"
          fontWeight={400}
          color={COLOR.text.primary}
          textTransform="lowercase"
        >
          {t('to')}
        </Typography>
      )}
      <CustomDatePicker
        width={width}
        type={type}
        selectedDate={endDate}
        handleChangeDate={handleChangeEndDate}
        minDate={startDate && new Date(startDate)}
        maxDate={maxDate && new Date(maxDate)}
        textFieldDisable
        disableFuture={disableFuture}
      />
      {isRefresh && (
        <IconButton aria-label="refresh" onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      )}
    </Box>
  );
};

export { CustomDatePickerRange, CustomDatePicker };
