import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Add, InfoOutlined, DeleteRounded } from '@mui/icons-material';
import { regexRedirectURI } from '@src/constants/regex';
import apis from '@src/apis';
import Tooltip from '@src/components/Tooltip';
import CustomPasswordInput from '@src/components/CustomInput/CustomPasswordInput';
import CopyToClipboard from '@src/components/CopyToClipboard';
import { StyledAppSSO } from './index.style';

const INIT_REDIRECT_URLS = { url: '' };
const INIT_ERRORS = { urls: [] };

const getInitRedirectURLs = (quantity) => {
  const initRedirectURLs = [];
  for (let index = 0; index < quantity; index += 1) {
    initRedirectURLs.push({ ...INIT_REDIRECT_URLS, id: uuidv4() });
  }

  return initRedirectURLs;
};

const CredentialApp = () => {
  const { t } = useTranslation();
  const { organizationId, appId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [redirectUrls, setRedirectUrls] = useState([]);
  const [errors, setErrors] = useState(INIT_ERRORS);
  const [loading, setLoading] = useState(false);
  const [credentialsApp, setCredentialsApp] = useState(false);

  const initRedirectUrls = (urls = []) => {
    if (urls?.length) {
      const initURLs = urls.map((url) => ({ url, id: uuidv4() }));
      setRedirectUrls(initURLs);
    } else {
      setRedirectUrls([]);
    }
  };

  const fetchApp = async () => {
    try {
      setLoading(true);
      const response = await apis.app.getApp(organizationId, appId);
      if (response?.status) {
        const app = response?.result;
        const { sso = {}, credentials = {} } = app;
        initRedirectUrls(sso?.redirectUrls);
        setCredentialsApp(credentials);
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleAddRedirectURL = () => {
    setErrors((prev) => ({
      ...prev,
      urls: [...prev.urls, ''],
    }));
    setRedirectUrls((prev) => [...prev, ...getInitRedirectURLs(1)]);
  };

  const handleRemoveRedirectURL = (id) => {
    setRedirectUrls((prev) => prev.filter((item, index) => index !== id));
    const errorRedirectURLs = [...errors.urls];
    setErrors((prev) => ({
      ...prev,
      urls: errorRedirectURLs.filter((item, index) => index !== id),
    }));
  };

  const handleChangeRedirectURL = (index, value) => {
    const tmpRedirectUrls = [...redirectUrls];
    tmpRedirectUrls[index].url = value;
    setRedirectUrls(tmpRedirectUrls);

    const errorRedirectURLs = [...errors.urls];
    setErrors((prev) => ({
      ...prev,
      urls: errorRedirectURLs.map((item, id) => {
        if (index === id) {
          return '';
        }
        return item;
      }),
    }));
  };

  const handleReset = async () => {
    await fetchApp();
    setErrors(INIT_ERRORS);
  };

  const validateRedirectURLs = () => {
    let isValid = true;
    const errorRedirectURLs = redirectUrls.map(({ url }, index, currArray) => {
      if (!url) {
        isValid = false;
        return t('redirectUrlRequired');
      }
      if (url && !regexRedirectURI.test(url)) {
        isValid = false;
        return t('invalidRedirectUrl');
      }
      const urlExist = currArray.find(
        (item, id) => id < index && item.url === url,
      );
      if (urlExist) {
        isValid = false;
        return t('redirectUrlIsExist');
      }

      return '';
    });

    setErrors((prev) => ({ ...prev, urls: errorRedirectURLs }));
    return isValid;
  };

  const validate = () => validateRedirectURLs();

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!validate()) return;
      const sso = {
        redirectUrls: redirectUrls.map(({ url }) => url),
      };
      const response = await apis.app.updateApp(organizationId, appId, { sso });
      if (response?.status) {
        enqueueSnackbar(t('saveAppInfoSuccess'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApp();
  }, []);

  return (
    <StyledAppSSO>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3">{t('applicationCredentials')}</Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="outlined"
              color="primary"
              className="action"
              onClick={handleReset}
            >
              {t('reset')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="action"
              onClick={handleSave}
              disabled={loading}
            >
              {t('save')}
            </Button>
          </Stack>
        </Stack>
        <Typography variant="subtitle1" marginY="12px" lineHeight="20px">
          {t('applicationCredentialsDesc')}
        </Typography>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} className="title-input-grid">
          <Typography>{t('clientId')}</Typography>
          <Box className="copy-input">
            <TextField
              size="small"
              fullWidth
              value={credentialsApp.clientId}
              disabled
            />
            <CopyToClipboard text={credentialsApp.clientId} />
          </Box>
        </Grid>
        <Grid item xs={6} className="title-input-grid">
          <Typography>{t('clientSecret')}</Typography>
          <Box className="copy-input">
            <CustomPasswordInput value={credentialsApp.clientSecret} disabled />
            <CopyToClipboard text={credentialsApp.clientSecret} />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Box className="card-info">
          <Box className="title-input">
            <Typography>{t('redirectUrls')}</Typography>
            <Tooltip title={t('redirectUrlsDesc')} arrow placement="top">
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </Box>
          {redirectUrls.map((redirectUrl, index) => (
            <Grid container spacing={1} key={redirectUrl.id}>
              <Grid item xs={11}>
                <TextField
                  size="small"
                  fullWidth
                  value={redirectUrl?.url || ''}
                  onChange={(e) =>
                    handleChangeRedirectURL(index, e.target.value)
                  }
                  error={!!errors?.urls[index]}
                  helperText={errors?.urls[index]}
                  placeholder="https://example.com"
                />
              </Grid>
              <Grid item xs={1}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    aria-label="delete"
                    className="text-red border-text-red"
                    onClick={() => handleRemoveRedirectURL(index)}
                  >
                    <DeleteRounded />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          ))}
          <Box>
            <Button
              variant="text"
              size="large"
              startIcon={<Add />}
              onClick={handleAddRedirectURL}
            >
              {t('addUrl')}
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
      />
    </StyledAppSSO>
  );
};

export default CredentialApp;
