import axios from 'axios';
import { UPLOAD_URL } from '@src/configs';
import api from './api';

export const getUploadAppImagePresignedUrl = async (extension, suffix) => {
  const res = await api({
    method: 'GET',
    url: `${UPLOAD_URL}/api/v1/presigned-url-for-uploading`,
    params: { extension, suffix },
  });
  return res;
};

export const uploadByPresignedUrl = async (url, file) => {
  const res = await axios({
    method: 'PUT',
    url,
    data: file,
  });
  return res;
};

export const uploadBase64 = async (base64, extension) => {
  const data = { extension, base64 };

  const response = await api({
    method: 'POST',
    url: `${UPLOAD_URL}/api/v1/upload-base64`,
    data,
  });

  return response.result;
};
