import { SERVICE } from '@src/constants';
import api from './api';

export const getAppReport = async (organizationId, params) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/reports`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getPaidApps = async (organizationId, params) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/reports/apps`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
