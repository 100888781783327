import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import camelcase from 'camelcase';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import apis from '@src/apis';
import { LoadingButton } from '@mui/lab';
import { APP_NAME_MAX_LENGTH, PLATFORM } from '@src/constants';

const INITIAL_STATE = {
  name: '',
  platforms: [],
  primaryCategory: '',
  secondaryCategory: '',
};

const CreateAppPopup = ({ open, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );
  const [data, setData] = useState(INITIAL_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});
  const [isFirst, setIsFirst] = useState(true);

  const validate = () => {
    const appInfoErrors = {};

    if (!data?.name?.trim() || data?.name?.length > APP_NAME_MAX_LENGTH) {
      appInfoErrors.name = t('fieldNameAppValid', {
        maxLength: APP_NAME_MAX_LENGTH,
      });
    }

    if (!data?.platforms?.length) {
      appInfoErrors.platforms = t('fieldRequired');
    }

    if (!data?.primaryCategory?.id) {
      appInfoErrors.primaryCategory = t('primaryCategoryIsRequired');
    }

    setErrors(appInfoErrors);
    return Object.keys(appInfoErrors).length === 0;
  };

  const handleReset = () => {
    setData(INITIAL_STATE);
    setErrors({});
    onClose();
  };

  const handleAppInfoData = () => {
    const { name, platforms, primaryCategory, secondaryCategory } = data;
    const createData = {
      name,
      platforms,
      primaryCategoryId: primaryCategory?.id,
    };
    if (secondaryCategory?.id)
      createData.secondCategoryId = secondaryCategory?.id;

    return createData;
  };

  const handleSubmit = async () => {
    try {
      if (!validate()) return;
      setIsSubmitting(true);
      const createData = handleAppInfoData();
      const res = await apis.app.createApp(organizationId, createData);
      if (!res?.status) throw new Error();
      history.push(
        `/organization/${organizationId}/app/${res?.result?.id}/version/${res?.result?.prepareSubmissionAppVersionId}`,
      );
      enqueueSnackbar(t('createAppSuccess'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchCategories = async (params = {}) => {
    try {
      const response = await apis.category.getCategories(params);
      if (response?.status) {
        setCategories(response?.result?.data || []);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangeFilterCategoriesInputText = (newInputValue) => {
    if (isFirst) {
      setIsFirst(false);
      return;
    }
    fetchCategories({ inputText: newInputValue });
  };

  const handleChangeData = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: '' });
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (open) fetchCategories();
  }, [open]);

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { borderRadius: 4 } }}>
      <Box padding={4} display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h3" mb={2}>
          {t('addApp')}
        </Typography>
        <Typography variant="body1" minWidth={150}>
          {t('platform')} <span className="required">*</span>
        </Typography>
        <Box display="flex" alignItems="center" gap={4}>
          {Object.values(PLATFORM).map((item) => (
            <FormControlLabel
              key={item}
              control={
                <Checkbox
                  checked={data.platforms.includes(item)}
                  onChange={(e) => {
                    handleChangeData({
                      target: {
                        name: 'platforms',
                        value: e.target.checked
                          ? [...data.platforms, item]
                          : data.platforms.filter((p) => p !== item),
                      },
                    });
                  }}
                />
              }
              label={t(camelcase(item))}
            />
          ))}
        </Box>
        {errors?.platforms && (
          <Typography variant="caption" color="error">
            {errors?.platforms}
          </Typography>
        )}
        <Typography variant="body1" minWidth={150} mt={1}>
          {t('nameApp')} <span className="required">*</span>
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          placeholder={t('nameApp')}
          value={data.name}
          name="name"
          onChange={handleChangeData}
          error={!!errors?.name}
          helperText={errors?.name}
        />
        <Typography variant="body1" minWidth={150} mt={1}>
          {t('type')} <span className="required">*</span>
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={12} lg={6}>
            <Autocomplete
              value={data?.primaryCategory || ''}
              size="small"
              options={categories?.filter(
                (item) => item?.id !== data?.secondaryCategory?.id,
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(event, newValue) => {
                handleChangeData({
                  target: { name: 'primaryCategory', value: newValue },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('primary')}
                  onChange={(event) => {
                    handleChangeFilterCategoriesInputText(event.target.value);
                  }}
                  error={!!errors?.primaryCategory}
                  helperText={errors?.primaryCategory}
                />
              )}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Autocomplete
              value={data?.secondaryCategory}
              size="small"
              options={categories?.filter(
                (item) => item?.id !== data?.primaryCategory?.id,
              )}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option?.name || ''}
              onChange={(event, newValue) => {
                handleChangeData({
                  target: {
                    name: 'secondaryCategory',
                    value: newValue,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={(event) => {
                    handleChangeFilterCategoriesInputText(event.target.value);
                  }}
                  label={`${t('secondary')} (${t('optional')})`}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box mt={2} display="flex" gap={1} justifyContent="end">
          <Button variant="outlined" onClick={handleReset}>
            {t('cancel')}
          </Button>
          <LoadingButton
            onClick={handleSubmit}
            loading={isSubmitting}
            variant="contained"
          >
            {t('create')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateAppPopup;
