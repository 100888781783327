import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import MultiFileDropzone from '@src/components/MultiFileDropzone';

import {
  IMAGE_ACCEPT_FILE,
  MAX_FILE_SIZE,
  PLATFORM,
  SCREEN_SHOTS_TYPE,
  VERSION_IMAGE_TYPE_FILE,
} from '@src/constants';
import { StyledIntroductoryPhoto } from './index.style';

const TABS = {
  MOBILE: 0,
  WEBSITE: 1,
};

const sizeImage = {
  minWidth: 320,
  maxWidth: 3840,
  minHeight: 320,
  maxHeight: 3840,
  aspectRatios: [16 / 9, 9 / 16],
};

const MAX_FILE = 8;

const IntroductoryPhoto = ({
  screenShots,
  setScreenShots,
  isError,
  invalidData,
  platformData,
  disabled,
  countClickSubmit,
}) => {
  const { t } = useTranslation();

  const [device, setDevice] = useState(TABS.MOBILE);

  const mapUrls = (urls) =>
    urls?.map((item) => (typeof item === 'string' ? item : item.url)) || [];

  const listImages = {
    [SCREEN_SHOTS_TYPE.PHONE]: mapUrls(
      screenShots?.find((item) => item.type === SCREEN_SHOTS_TYPE.PHONE)?.urls,
    ),
    [SCREEN_SHOTS_TYPE.WEBSITE]: mapUrls(
      screenShots?.find((item) => item.type === SCREEN_SHOTS_TYPE.WEBSITE)
        ?.urls,
    ),
  };

  const handleChangeDevice = (event, newValue) => setDevice(newValue);

  const handleAddFile = (type, images) => {
    const newImages = images.map((item) => {
      if (typeof item === 'string') {
        return item;
      }
      return {
        url: URL.createObjectURL(item),
        file: item,
      };
    });

    setScreenShots((prev) =>
      prev.map((item) =>
        item.type === type
          ? { ...item, urls: [...item.urls, ...newImages] }
          : item,
      ),
    );
  };

  const handleDeleteAllFile = (type) => {
    setScreenShots((prev) =>
      prev.map((item) => (item.type === type ? { ...item, urls: [] } : item)),
    );
  };

  const handleDeleteFile = (type, image) => {
    const newUrls = listImages[type].filter((item) =>
      typeof item === 'string' ? item !== image : item.url !== image,
    );
    setScreenShots((prev) =>
      prev.map((item) =>
        item.type === type
          ? {
              ...item,
              urls: newUrls,
            }
          : item,
      ),
    );
  };

  const requiredText = () => {
    if (platformData?.length === 1) {
      return platformData[0].name === PLATFORM.ANDROID ||
        platformData[0].name === PLATFORM.IOS
        ? t('phoneImageIsRequired')
        : t('websiteImageIsRequired');
    }
    return t('phoneAndWebsiteImageIsRequired');
  };

  const tabs = [
    {
      type: SCREEN_SHOTS_TYPE.PHONE,
      label: t('mobile'),
      previewUrls: listImages[SCREEN_SHOTS_TYPE.PHONE],
      onAddFile: (images) => handleAddFile(SCREEN_SHOTS_TYPE.PHONE, images),
      onDeleteAllFile: () => handleDeleteAllFile(SCREEN_SHOTS_TYPE.PHONE),
      onDeleteFilePreview: (image) =>
        handleDeleteFile(SCREEN_SHOTS_TYPE.PHONE, image),
      isError:
        isError &&
        invalidData.screenShots &&
        screenShots?.find((item) => item.type === SCREEN_SHOTS_TYPE.PHONE)?.urls
          .length < 2 &&
        [
          t('phoneImageIsRequired'),
          t('phoneAndWebsiteImageIsRequired'),
        ].includes(requiredText()),
    },
    {
      type: SCREEN_SHOTS_TYPE.WEBSITE,
      label: t('website'),
      previewUrls: listImages[SCREEN_SHOTS_TYPE.WEBSITE],
      onAddFile: (images) => handleAddFile(SCREEN_SHOTS_TYPE.WEBSITE, images),
      onDeleteAllFile: () => handleDeleteAllFile(SCREEN_SHOTS_TYPE.WEBSITE),
      onDeleteFilePreview: (image) =>
        handleDeleteFile(SCREEN_SHOTS_TYPE.WEBSITE, image),
      isError:
        isError &&
        invalidData.screenShots &&
        screenShots?.find((item) => item.type === SCREEN_SHOTS_TYPE.WEBSITE)
          ?.urls.length < 2 &&
        [
          t('websiteImageIsRequired'),
          t('phoneAndWebsiteImageIsRequired'),
        ].includes(requiredText()),
    },
  ];

  useEffect(() => {
    if (platformData?.length >= 1) {
      setDevice(
        platformData[0].name === PLATFORM.ANDROID ||
          platformData[0].name === PLATFORM.IOS
          ? TABS.MOBILE
          : TABS.WEBSITE,
      );
    }
  }, [platformData]);

  useEffect(() => {
    if (countClickSubmit > 0) {
      if (platformData?.length >= 1) {
        if (
          tabs[device].isError &&
          invalidData.screenShots &&
          screenShots?.find((item) => item.type === tabs[device].type)?.urls
            .length < 2
        ) {
          setDevice(device);
        } else {
          setDevice(
            platformData[0].name === PLATFORM.ANDROID ||
              platformData[0].name === PLATFORM.IOS
              ? TABS.MOBILE
              : TABS.WEBSITE,
          );
        }
      }
    }
  }, [countClickSubmit]);

  return (
    <StyledIntroductoryPhoto id="screenshots">
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h4">{t('screenshot')}</Typography>
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Tabs value={device} onChange={handleChangeDevice} className="tabs">
          {tabs.map((tab, index) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={index}
              className="tab"
            />
          ))}
        </Tabs>
        {tabs.map((tab, index) => (
          <div key={tab.type} role="tabpanel" hidden={device !== index}>
            <Box display={device === index ? 'inherit' : 'none'}>
              <MultiFileDropzone
                fileType={VERSION_IMAGE_TYPE_FILE}
                acceptFile={IMAGE_ACCEPT_FILE}
                onAddFile={tab.onAddFile}
                onDeleteAllFile={tab.onDeleteAllFile}
                onDeleteFilePreview={tab.onDeleteFilePreview}
                maxFiles={MAX_FILE}
                maxSize={MAX_FILE_SIZE.EIGHT_MB}
                noteContent={
                  <Typography lineHeight="16px" variant="body2">
                    {t('uploadVersionImageDesc', {
                      device:
                        tab.type === SCREEN_SHOTS_TYPE.PHONE
                          ? t('mobile').toLowerCase()
                          : t('website'),
                    })}
                  </Typography>
                }
                previewUrls={tab.previewUrls}
                isError={tab.isError}
                disabled={disabled}
                {...sizeImage}
              />
            </Box>
          </div>
        ))}
        {isError && invalidData.screenShots && (
          <Typography variant="caption" color="error">
            {requiredText()}
          </Typography>
        )}
      </Box>
    </StyledIntroductoryPhoto>
  );
};

export default IntroductoryPhoto;
