import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DeleteRounded, Add } from '@mui/icons-material';

import { regexUrl } from '@src/constants/regex';
import apis from '@src/apis';
import CustomPasswordInput from '@src/components/CustomInput/CustomPasswordInput';
import { StyledAppWebhook } from './index.style';
import IOSSwitch from './IOSSwitch';

const INIT_HEADER = { key: '', value: '' };

const INIT_BASIC_AUTH = {
  username: '',
  password: '',
};

const getInitHeaders = (quantity) => {
  const initHeaders = [];
  for (let index = 0; index < quantity; index += 1) {
    initHeaders.push({ ...INIT_HEADER, id: uuidv4() });
  }

  return initHeaders;
};

const INIT_ERROR = {
  url: '',
  basicAuth: INIT_BASIC_AUTH,
  headers: getInitHeaders(2),
};

const AppWebhook = () => {
  const { t } = useTranslation();
  const { organizationId, appId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [active, setActive] = useState(true);
  const [url, setUrl] = useState('');
  const [basicAuth, setBasicAuth] = useState(INIT_BASIC_AUTH);
  const [headers, setHeaders] = useState(getInitHeaders(2));
  const [errors, setErrors] = useState(INIT_ERROR);
  const [loading, setLoading] = useState(false);

  const initWebhook = (webhook = {}) => {
    if (typeof webhook.active === 'boolean') setActive(webhook.active);
    setUrl(webhook.url || '');
    setBasicAuth(webhook.basicAuth || INIT_BASIC_AUTH);
    setErrors(INIT_ERROR);
    if (webhook.headers?.length) {
      setErrors((prev) => ({
        ...prev,
        headers: getInitHeaders(webhook.headers.length + 1),
      }));
      setHeaders([...webhook.headers, ...getInitHeaders(1)]);
    } else {
      setHeaders(getInitHeaders(2));
    }
  };

  const fetchApp = async () => {
    try {
      setLoading(true);
      const response = await apis.app.getApp(organizationId, appId);
      if (response?.status) {
        const app = response?.result;
        initWebhook(app.webhook);
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchApp();
  }, []);

  const handleChangeActive = (e) => setActive(e.target.checked);

  const handleChangeUrl = (e) => {
    setUrl(e.target.value);
    setErrors((prev) => ({ ...prev, url: '' }));
  };

  const handleChangeBasicAuth = (e, field) => {
    setBasicAuth((prev) => ({ ...prev, [field]: e.target.value }));
    const basicAuthError = { ...errors.basicAuth, [field]: '' };
    setErrors((prev) => ({ ...prev, basicAuth: basicAuthError }));
  };

  const handleAddHeader = () => {
    setErrors((prev) => ({
      ...prev,
      headers: [...prev.headers, ...getInitHeaders(1)],
    }));
    setHeaders((prev) => [...prev, ...getInitHeaders(1)]);
  };

  const handleRemoveHeader = (id) => {
    setHeaders((prev) => prev.filter((item, index) => index !== id));
    const errorHeaders = [...errors.headers];
    setErrors((prev) => ({
      ...prev,
      headers: errorHeaders.filter((item, index) => index !== id),
    }));
  };

  const handleChangeHeader = (id, key, value) => {
    setHeaders((prev) =>
      prev.map((header, index) => {
        if (index === id) return { ...header, [key]: value };
        return header;
      }),
    );

    const errorHeaders = [...errors.headers];
    setErrors((prev) => ({
      ...prev,
      headers: errorHeaders.map((item, index) => {
        if (index === id) {
          return INIT_HEADER;
        }
        return item;
      }),
    }));
  };

  const handleReset = () => {
    fetchApp();
  };

  const validateUrl = () => {
    if (!url) {
      setErrors((prev) => ({ ...prev, url: 'fieldRequired' }));
      return false;
    }
    if (!regexUrl.test(url)) {
      setErrors((prev) => ({ ...prev, url: 'urlIsInvalid' }));
      return false;
    }
    return true;
  };

  const validateBasicAuth = () => {
    if (!basicAuth.username && basicAuth.password) {
      setErrors((prev) => ({
        ...prev,
        basicAuth: { ...prev.basicAuth, username: 'fieldRequired' },
      }));
      return false;
    }

    if (basicAuth.username && !basicAuth.password) {
      setErrors((prev) => ({
        ...prev,
        basicAuth: { ...prev.basicAuth, password: 'fieldRequired' },
      }));
      return false;
    }
    return true;
  };

  const validateHeaders = () => {
    let isValid = true;
    const errorHeaders = headers.map((header, index, currArray) => {
      if (!header.key && header.value) {
        isValid = false;
        return { ...INIT_HEADER, key: 'fieldRequired' };
      }
      if (header.key && !header.value) {
        isValid = false;
        return { ...INIT_HEADER, value: 'fieldRequired' };
      }
      const headerExist = currArray.find(
        ({ key }, id) => id < index && key === header.key && key !== '',
      );
      if (headerExist) {
        isValid = false;
        return { ...INIT_HEADER, key: 'keyHeaderExist' };
      }

      return INIT_HEADER;
    });

    setErrors((prev) => ({ ...prev, headers: errorHeaders }));
    return isValid;
  };

  const validate = () =>
    validateUrl() && validateBasicAuth() && validateHeaders();

  const handleSave = async () => {
    try {
      setLoading(true);
      if (!validate()) return;
      const webhook = {
        active,
        url,
        basicAuth,
        headers: headers
          .filter((header) => header.key && header.value)
          .map(({ key, value }) => ({ key, value })),
      };
      const response = await apis.app.updateApp(organizationId, appId, {
        webhook,
      });
      if (response?.status) {
        const app = response?.result;
        initWebhook(app.webhook);
        enqueueSnackbar(t('saveAppInfoSuccess'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledAppWebhook>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        paddingBottom={1}
      >
        <Typography variant="h3">{t('webhookConfig')}</Typography>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button
            variant="outlined"
            color="primary"
            className="action"
            onClick={handleReset}
          >
            {t('reset')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="action"
            onClick={handleSave}
            disabled={loading}
          >
            {t('save')}
          </Button>
        </Stack>
      </Stack>
      <Divider />
      <Box paddingTop={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingBottom={1}
        >
          <Typography>
            {t('url')} <span className="text-red">*</span>
          </Typography>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Typography>{t('status')}</Typography>
            <FormControlLabel
              control={
                <IOSSwitch checked={active} onChange={handleChangeActive} />
              }
            />
          </Stack>
        </Stack>

        <TextField
          size="small"
          fullWidth
          value={url}
          onChange={handleChangeUrl}
          onBlur={validateUrl}
          error={!!errors.url}
          helperText={t(errors.url)}
          placeholder={t('fillUrl')}
        />
      </Box>
      <Box paddingTop={2}>
        <Typography paddingBottom={1}>{t('basicAuth')}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              defaultValue={null}
              value={basicAuth?.username}
              onChange={(e) => handleChangeBasicAuth(e, 'username')}
              error={!!errors?.basicAuth?.username}
              helperText={t(errors?.basicAuth?.username)}
              placeholder={t('fillUsername')}
              name="basic-auth-username"
              id="basic-auth-username"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomPasswordInput
              value={basicAuth?.password}
              onChange={(e) => handleChangeBasicAuth(e, 'password')}
              error={!!errors?.basicAuth?.password}
              helperText={t(errors?.basicAuth?.password)}
              placeholder={t('fillPassword')}
              name="basic-auth-password"
              id="basic-auth-password"
            />
          </Grid>
        </Grid>
      </Box>
      <Box paddingTop={2} paddingBottom={1}>
        <Typography paddingBottom={1}>{t('headers')}</Typography>
        {headers.map((header, index) => (
          <Grid container spacing={2} key={header.id} paddingBottom={1}>
            <Grid item xs={4}>
              <TextField
                size="small"
                fullWidth
                value={header.key}
                onChange={(e) =>
                  handleChangeHeader(index, 'key', e.target.value)
                }
                error={!!errors.headers[index]?.key}
                helperText={t(errors.headers[index]?.key)}
                placeholder={t('fillKey')}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                size="small"
                fullWidth
                value={header.value}
                onChange={(e) =>
                  handleChangeHeader(index, 'value', e.target.value)
                }
                error={!!errors.headers[index]?.value}
                helperText={t(errors.headers[index]?.value)}
                placeholder={t('fillValue')}
              />
            </Grid>
            <Grid item xs={1}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  aria-label="delete"
                  className="text-red border-text-red"
                  onClick={() => handleRemoveHeader(index)}
                >
                  <DeleteRounded />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        ))}
        <Box>
          <Button
            variant="text"
            size="large"
            startIcon={<Add />}
            onClick={handleAddHeader}
          >
            {t('addHeader')}
          </Button>
        </Box>
      </Box>
      <Divider />
    </StyledAppWebhook>
  );
};

export default AppWebhook;
