const COLOR = {
  success: {
    base: '#00D918',
  },
  primary: {
    base: '#0069CE',
    dark: '#004390',
    light: '#E4EAF3',
  },
  secondary: {
    base: 'rgba(60, 60, 69, 0.64)',
  },
  error: {
    base: '#D2001A',
  },
  warning: {
    base: '#FF9500',
  },
  info: {
    base: '#007AFF',
  },
  text: {
    primary: '#1C1C1E',
    secondary: 'rgba(245, 245, 247, 0.80)',
    tertiary: 'rgba(255, 255, 255, 0.64)',
    highlight: '#1565C0',
  },
  outlined: {
    8: 'rgba(60, 60, 67, 0.08)',
    16: 'rgba(60, 60, 67, 0.16)',
    32: 'rgba(60, 60, 67, 0.32)',
    64: 'rgba(60, 60, 67, 0.64)',
    80: 'rgba(60, 60, 67, 0.8)',
  },
  fills: {
    8: 'rgba(255, 255, 255, 0.08)',
    16: 'rgba(255, 255, 255, 0.16)',
    32: 'rgba(255, 255, 255, 0.32)',
    48: 'rgba(255, 255, 255, 0.48)',
    64: 'rgba(255, 255, 255, 0.64)',
    80: 'rgba(255, 255, 255, 0.8)',
    charcoal: {
      100: '#F5F5F7',
    },
  },
  black: {
    4: 'rgba(0, 0, 0, 0.04)',
    8: 'rgba(0, 0, 0, 0.08)',
    64: 'rgba(0, 0, 0, 0.64)',
  },
  gray: {
    primary: '#e9ebec',
    97: 'rgba(153, 153, 153, 0.97)',
  },

  // New colors
  white: {
    100: 'rgba(255, 255, 255, 1)',
    80: 'rgba(255, 255, 255, 0.8)',
    64: 'rgba(255, 255, 255, 0.64)',
    32: 'rgba(255, 255, 255, 0.32)',
    16: 'rgba(255, 255, 255, 0.16)',
    8: 'rgba(255, 255, 255, 0.08)',
    4: 'rgba(255, 255, 255, 0.04)',
  },
  darkBlue: {
    100: 'rgba(0, 67, 144, 1)',
    80: 'rgba(0, 67, 144, 0.8)',
    64: 'rgba(0, 67, 144, 0.64)',
    32: 'rgba(0, 67, 144, 0.32)',
    16: 'rgba(0, 67, 144, 0.16)',
    8: 'rgba(0, 67, 144, 0.08)',
    4: 'rgba(0, 67, 144, 0.04)',
  },
  standardBlue: {
    100: 'rgba(21, 101, 192, 1)',
    80: 'rgba(21, 101, 192, 0.8)',
    64: 'rgba(21, 101, 192, 0.64)',
    32: 'rgba(21, 101, 192, 0.32)',
    16: 'rgba(21, 101, 192, 0.16)',
    8: 'rgba(21, 101, 192, 0.08)',
    4: 'rgba(21, 101, 192, 0.04)',
  },
  infoBlue: {
    100: 'rgba(18, 85, 161, 1)',
  },
  darkestBlue: {
    100: 'rgba(36, 47, 108, 1)',
    80: 'rgba(36, 47, 108, 0.8)',
    64: 'rgba(36, 47, 108, 0.64)',
    32: 'rgba(36, 47, 108, 0.32)',
    16: 'rgba(36, 47, 108, 0.16)',
    8: 'rgba(36, 47, 108, 0.08)',
    4: 'rgba(36, 47, 108, 0.04)',
  },
  pastelBlue: {
    100: 'rgba(236, 239, 248, 1)',
    80: 'rgba(236, 239, 248, 0.8)',
    64: 'rgba(236, 239, 248, 0.64)',
    32: 'rgba(236, 239, 248, 0.32)',
    16: 'rgba(236, 239, 248, 0.16)',
    8: 'rgba(236, 239, 248, 0.08)',
    4: 'rgba(236, 239, 248, 0.04)',
  },
  blue: {
    100: 'rgba(0, 111, 238, 1)',
    80: 'rgba(0, 111, 238, 0.8)',
    64: 'rgba(0, 111, 238, 0.64)',
    32: 'rgba(0, 111, 238, 0.32)',
    16: 'rgba(0, 111, 238, 0.16)',
    8: 'rgba(0, 111, 238, 0.08)',
    4: 'rgba(0, 111, 238, 0.04)',
  },
  red: {
    100: 'rgba(210, 0, 26, 1)',
    80: 'rgba(210, 0, 26, 0.8)',
    64: 'rgba(210, 0, 26, 0.64)',
    32: 'rgba(210, 0, 26, 0.32)',
    16: 'rgba(210, 0, 26, 0.16)',
    8: 'rgba(210, 0, 26, 0.08)',
    4: 'rgba(210, 0, 26, 0.04)',
  },
  yellow: {
    100: 'rgba(241, 184, 33, 1)',
    80: 'rgba(241, 184, 33, 0.8)',
    64: 'rgba(241, 184, 33, 0.64)',
    32: 'rgba(241, 184, 33, 0.32)',
    16: 'rgba(241, 184, 33, 0.16)',
    8: 'rgba(241, 184, 33, 0.08)',
    4: 'rgba(241, 184, 33, 0.04)',
  },
  green: {
    100: 'rgba(52, 199, 89, 1)',
    80: 'rgba(52, 199, 89, 0.8)',
    64: 'rgba(52, 199, 89, 0.64)',
    32: 'rgba(52, 199, 89, 0.32)',
    16: 'rgba(52, 199, 89, 0.16)',
    8: 'rgba(52, 199, 89, 0.08)',
    4: 'rgba(52, 199, 89, 0.04)',
  },
  blueGray: {
    100: 'rgba(60, 60, 67, 1)',
    80: 'rgba(60, 60, 67, 0.8)',
    64: 'rgba(60, 60, 67, 0.64)',
    32: 'rgba(60, 60, 67, 0.32)',
    16: 'rgba(60, 60, 67, 0.16)',
    8: 'rgba(60, 60, 67, 0.08)',
    4: 'rgba(60, 60, 67, 0.04)',
  },
  charcoal: {
    100: 'rgba(245, 245, 247, 1)',
    80: 'rgba(245, 245, 247, 0.8)',
    64: 'rgba(245, 245, 247, 0.64)',
    32: 'rgba(245, 245, 247, 0.32)',
    16: 'rgba(245, 245, 247, 0.16)',
    8: 'rgba(245, 245, 247, 0.08)',
    4: 'rgba(245, 245, 247, 0.04)',
  },
  ink: {
    100: 'rgba(28, 28, 30, 1)',
    80: 'rgba(28, 28, 30, 0.8)',
    64: 'rgba(28, 28, 30, 0.64)',
    32: 'rgba(28, 28, 30, 0.32)',
    16: 'rgba(28, 28, 30, 0.16)',
    8: 'rgba(28, 28, 30, 0.08)',
    4: 'rgba(28, 28, 30, 0.04)',
  },
  border: {
    error: 'rgba(255, 59, 48, 0.1)',
  },
  // TODO: remove this after the design is finalized
  draftColor: {
    backgroundTopbar: '#ebf0f6',
    bottomBar: '#2B3467',
    bottomBarActive: '#242F6C',
  },
};

const TRANSPARENT_COLOR = {
  primary: 'rgba(252, 102, 52, 0.12)',
  secondary: 'rgba(108, 117, 125, 0.12)',
  success: 'rgba(40, 199, 111, 0.12)',
  danger: 'rgba(234, 84, 85, 0.12)',
  warning: 'rgba(255, 225, 67, 0.12)',
  info: 'rgba(0, 207, 232, 0.12)',
  dark: 'rgba(30, 30, 30, 0.12)',
  light: 'rgba(186, 191, 199, 0.12)',
};

export { COLOR, TRANSPARENT_COLOR };
