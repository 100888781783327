import { Box, Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import {
  DATE_TIME_PICKER_TYPES,
  END_TIME_DEFAULT,
  START_TIME_DEFAULT,
} from '@src/constants';
import { ReplayRounded } from '@mui/icons-material';
import { COLOR } from '@src/styles/color';
import { useSearchParams } from '@src/hooks';
import AppRevenueStats from './AppRevenueStats';
import AppStats from './AppStats';
import PackageQuantityStats from './PackageQuantityStats';
import PackageRevenueStats from './PackageRevenueStats';

const OverviewStats = () => {
  const { params, addParams, removeParams } = useSearchParams();

  const { startTime = START_TIME_DEFAULT, endTime = END_TIME_DEFAULT } = params;

  const [isReLoad, setIsReLoad] = useState(false);

  const onChangeStartDate = (value) => addParams({ startTime: value });

  const onChangeEndDate = (value) => addParams({ endTime: value });

  const handleRefresh = () => {
    removeParams('startTime', 'endTime');
    setIsReLoad(!isReLoad);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gap={2}
        width="100%"
        marginBottom="12px"
      >
        <CustomDatePickerRange
          startDate={startTime}
          endDate={endTime}
          handleChangeStartDate={onChangeStartDate}
          handleChangeEndDate={onChangeEndDate}
          type={DATE_TIME_PICKER_TYPES.DATE}
          isRefresh={false}
          hasFromText={false}
          disableFuture
        />
        <IconButton
          sx={{ border: `1px solid ${COLOR.darkBlue[32]}` }}
          onClick={handleRefresh}
        >
          <ReplayRounded color="primary" />
        </IconButton>
      </Box>
      <Grid container spacing={2.5}>
        <Grid item xs={12} lg={6}>
          <AppStats isReLoad={isReLoad} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AppRevenueStats
            startTime={startTime}
            endTime={endTime}
            isReLoad={isReLoad}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PackageQuantityStats
            startTime={startTime}
            endTime={endTime}
            isReLoad={isReLoad}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <PackageRevenueStats
            startTime={startTime}
            endTime={endTime}
            isReLoad={isReLoad}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default OverviewStats;
