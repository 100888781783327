import React, { useRef, useState } from 'react';

import { CloseRounded, Delete } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { StyledImagePreview } from './index.style';

const PreviewImage = ({ image, onDelete, onImageLoad, disabled }) => {
  const imgRef = useRef(null);

  const [isHover, setIsHover] = useState(false);
  const [isZoom, setIsZoom] = useState(false);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(image, e);
  };

  const handleImageLoad = (e) => {
    onImageLoad(imgRef, e);
  };

  const zoomImage = (e) => {
    e.stopPropagation();
    setIsZoom(!isZoom);
    setIsHover(false);
  };

  return (
    <StyledImagePreview
      onClick={zoomImage}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isZoom ? (
        <Box className="fullscreen">
          <IconButton onClick={zoomImage} className="close-button">
            <CloseRounded />
          </IconButton>

          <img src={image} alt="banner" />
        </Box>
      ) : (
        <>
          <img
            ref={imgRef}
            src={image}
            className="preview-image"
            alt="banner"
            onLoad={handleImageLoad}
            onClick={zoomImage}
            crossOrigin="anonymous"
          />
          {isHover && !disabled && (
            <div
              onClick={handleDelete}
              role="button"
              tabIndex="0"
              className="delete-image"
            >
              <Delete color="error" fontSize="small" />
            </div>
          )}
        </>
      )}
    </StyledImagePreview>
  );
};

export default PreviewImage;
