import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppItem = styled(Box)`
  display: flex;
  padding: 12px;
  gap: 12px;
  cursor: pointer;
  border-radius: 16px;

  &:hover {
    background-color: ${COLOR.ink[4]};
  }

  .img {
    width: 80px;
    height: 80px;
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
