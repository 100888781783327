import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { validFile, validSize } from '@src/utils/checkValid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import PreviewImage from './PreviewImage';
import { StyledFileDropzone } from './index.style';

const MultiFileDropzone = ({
  fileType,
  acceptFile,
  onAddFile,
  onDeleteAllFile,
  maxFiles,
  noteContent,
  maxSize,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  aspectRatios,
  previewUrls = [],
  onDeleteFilePreview,
  isError,
  disabled,
}) => {
  const { t } = useTranslation();
  const fileInputRef = useRef(null);

  const [formatError, setFormatError] = useState('');
  const [oversize, setOversize] = useState('');
  const [sizeError, setSizeError] = useState('');

  const handleOpenUploadFiles = () => fileInputRef.current.click();

  const getImageDimensions = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });

  const validAspectRatio = (width, height, aspectRatio) =>
    Math.abs(width / height - aspectRatio) < 0.01;

  const isValidateFiles = async (files) => {
    setFormatError('');
    setOversize('');
    setSizeError('');

    let formatErrorText = null;
    let oversizeText = null;
    let sizeErrorText = null;

    await Promise.all(
      files.map(async (file) => {
        if (!validFile(file.name, fileType)) {
          formatErrorText = file.name;
        }
        if (!validSize(file.size, maxSize)) {
          oversizeText = file.name;
        }
        const { width, height } = await getImageDimensions(file);
        if (
          (minWidth && width < minWidth) ||
          (maxWidth && width > maxWidth) ||
          (minHeight && height < minHeight) ||
          (maxHeight && height > maxHeight)
        ) {
          sizeErrorText = file.name;
        }
        if (aspectRatios && aspectRatios.length > 0) {
          const isValidate = aspectRatios.some((aspectRatio) =>
            validAspectRatio(width, height, aspectRatio),
          );
          if (!isValidate) {
            sizeErrorText = file.name;
          }
        }
      }),
    );

    if (formatErrorText) setFormatError(formatErrorText);
    if (oversizeText) setOversize(oversizeText);
    if (sizeErrorText) setSizeError(sizeErrorText);
    return !formatErrorText && !oversizeText && !sizeErrorText;
  };

  const handleChangeFile = async (e) => {
    const { files } = e.target;
    const lengthFilesCanAdd = maxFiles - previewUrls.length;
    const formatFiles = Array.from(files).slice(0, lengthFilesCanAdd);
    const isValidate = await isValidateFiles(formatFiles);
    if (!isValidate) return;

    onAddFile(formatFiles);
    fileInputRef.current.value = null;
  };

  const handleReset = () => {
    setFormatError('');
    setOversize('');
    setSizeError('');
    onDeleteAllFile(null);
  };

  const renderUploadButton = () => (
    <Button
      variant="outlined"
      onClick={handleOpenUploadFiles}
      disabled={previewUrls?.length >= maxFiles || disabled}
      className="button"
    >
      <FontAwesomeIcon icon={faUpload} />
      {t('uploadFile')}
    </Button>
  );

  return (
    <StyledFileDropzone iserror={isError ? 1 : 0}>
      <Box className="dropzone">
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          accept={acceptFile}
          onChange={handleChangeFile}
          multiple
          disabled={disabled}
        />

        {previewUrls?.length === 0 ? (
          <div className="upload-note">
            {noteContent}
            {renderUploadButton()}
            <Typography variant="body2" className="mt-24">
              {previewUrls?.length} {t('in')} {maxFiles} {t('imageLowercase')}
            </Typography>
          </div>
        ) : (
          <>
            <Box className="upload-success">
              <div className="preview-image-upload">
                {previewUrls.map((item) => (
                  <PreviewImage
                    key={item}
                    image={item}
                    onDelete={onDeleteFilePreview}
                    disabled={disabled}
                  />
                ))}
              </div>
            </Box>
            <Box className="action">
              <Typography variant="body2">
                {previewUrls?.length} {t('in')} {maxFiles} {t('imageLowercase')}
              </Typography>
              <div className="action-buttons">
                {renderUploadButton()}
                <Button
                  variant="outlined"
                  onClick={handleReset}
                  disabled={previewUrls?.length === 0 || disabled}
                  className="button"
                  color="error"
                >
                  <FontAwesomeIcon icon={faTrash} />
                  {t('deleteAll')}
                </Button>
              </div>
            </Box>
          </>
        )}
        <Box className="error">
          {formatError && (
            <Typography variant="subtitle2" color="error">
              {t('onlyUpload', {
                fileName: formatError,
                fileType: fileType.join(', '),
              })}
            </Typography>
          )}
          {oversize && (
            <Typography variant="subtitle2" color="error">
              {t('oversizeError', {
                fileName: oversize,
                maxSize,
              })}
            </Typography>
          )}
          {sizeError && (
            <Typography variant="subtitle2" color="error">
              {t('sizeErrorMulti', { fileName: sizeError })}
            </Typography>
          )}
        </Box>
      </Box>
    </StyledFileDropzone>
  );
};

export default MultiFileDropzone;
