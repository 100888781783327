import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { PLATFORM, REGEX_ANDROID_LINK, REGEX_IOS_LINK } from '@src/constants';
import { regexUrl } from '@src/constants/regex';
import { StyledPlatformSupport } from './index.style';

const PlatformSupport = ({
  platforms,
  setPlatforms,
  isError,
  isErrorDraft,
  disabled,
}) => {
  const { t } = useTranslation();

  const handlePlatformChange = (platform) => {
    if (platforms.find((item) => item.name === platform)) {
      setPlatforms(platforms.filter((item) => item.name !== platform));
    } else {
      setPlatforms([...platforms, { name: platform, url: '' }]);
    }
  };

  const handleUrlChange = (platform, url) => {
    setPlatforms(
      platforms.map((item) =>
        item.name === platform ? { ...item, url } : item,
      ),
    );
  };

  const renderTextField = (platform, regex, placeholder) => {
    const isChecked = platforms.find((item) => item.name === platform);
    const isHasUrl = platforms.find((item) => item.name === platform)?.url;
    const invalidUrl = !regex.test(
      platforms.find((item) => item.name === platform)?.url,
    );

    const isErrorTextField =
      (isError && isChecked && invalidUrl) ||
      (isErrorDraft &&
        isChecked &&
        !regex.test(platforms.find((item) => item.name === platform)?.url) &&
        isHasUrl);

    const helperText =
      (!platforms.find((item) => item.name === platform)?.url &&
        t('fieldRequired')) ||
      (isHasUrl && invalidUrl && t('urlIsInvalid'));

    return (
      <TextField
        fullWidth
        size="small"
        value={platforms.find((item) => item.name === platform)?.url || ''}
        disabled={!platforms.find((item) => item.name === platform) || disabled}
        onChange={(e) => handleUrlChange(platform, e.target.value)}
        placeholder={placeholder}
        error={isErrorTextField}
        helperText={isErrorTextField && helperText}
        id={`${platform}-url`}
      />
    );
  };

  return (
    <StyledPlatformSupport id="platform">
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h4">
            {t('platformSupport')} <span className="required">*</span>
          </Typography>
        </Box>
        {isError && !platforms.length && (
          <Typography variant="body2" color="error" mb={1}>
            {t('platformRequired')}
          </Typography>
        )}
      </Box>
      <Box className="body-card">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={platforms.some((item) => item.name === PLATFORM.IOS)}
                  onChange={() => handlePlatformChange(PLATFORM.IOS)}
                  size="small"
                  disabled={disabled}
                />
              }
              label="iOS"
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            {renderTextField(PLATFORM.IOS, REGEX_IOS_LINK, t('iOSLinkExample'))}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={platforms.some(
                    (item) => item.name === PLATFORM.ANDROID,
                  )}
                  onChange={() => handlePlatformChange(PLATFORM.ANDROID)}
                  size="small"
                  disabled={disabled}
                />
              }
              label="Android"
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            {renderTextField(
              PLATFORM.ANDROID,
              REGEX_ANDROID_LINK,
              t('androidLinkExample'),
            )}
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={platforms.some(
                    (item) => item.name === PLATFORM.WEB_APP,
                  )}
                  onChange={() => handlePlatformChange(PLATFORM.WEB_APP)}
                  size="small"
                  disabled={disabled}
                />
              }
              label="Web"
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            {renderTextField(
              PLATFORM.WEB_APP,
              regexUrl,
              t('webAppLinkExample'),
            )}
          </Grid>
        </Grid>
      </Box>
    </StyledPlatformSupport>
  );
};

export default PlatformSupport;
