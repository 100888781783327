import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import apis from '@src/apis';
import { VERSION_REGEX } from '@src/constants';

const CreateVersionPopup = ({
  open,
  onClose,
  organizationId,
  appId,
  defaultName,
  resetApp,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const validate = () => {
    if (!name || !VERSION_REGEX.test(name)) {
      setIsError(true);
      return false;
    }
    setIsError(false);
    return true;
  };

  const handleCreateVersion = async () => {
    try {
      if (!validate()) return;
      setIsSubmitting(true);
      const res = await apis.app.createAppVersion(organizationId, appId, {
        version: name,
      });
      if (res?.status) {
        history.push(
          `/organization/${organizationId}/app/${appId}/version/${res.result.id}`,
        );
        resetApp();
        onClose();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setName(defaultName);
  }, [defaultName, open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{ sx: { borderRadius: 4 } }}
    >
      <Box padding={4} display="flex" flexDirection="column" gap={0.5}>
        <Typography variant="h3" mb={2}>
          {t('createNewVersion')}
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          size="small"
          label={t('nameVersion')}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={isError && !VERSION_REGEX.test(name)}
          helperText={
            isError && !VERSION_REGEX.test(name) && t('invalidVersionFormat')
          }
        />
        <Box mt={2} display="flex" gap={1} justifyContent="end">
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
          <LoadingButton
            onClick={handleCreateVersion}
            loading={isSubmitting}
            variant="contained"
          >
            {t('create')}
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateVersionPopup;
