import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { regexUrl } from '@src/constants/regex';
import React from 'react';
import { useTranslation } from 'react-i18next';

const OtherInformation = ({
  otherData,
  setOtherData,
  isError,
  maxTextLength,
  invalidData,
  isErrorDraft,
  invalidDataDraft,
  isExistOldVersion,
  disabled,
}) => {
  const { t } = useTranslation();

  const isErrorAdvertisingContent =
    (isError && invalidData.advertisingContent) ||
    (isErrorDraft && invalidDataDraft.advertisingContent);
  const isErrorDescription =
    (isError && invalidData.description) ||
    (isErrorDraft && invalidDataDraft.description);
  const isErrorNewFeatures =
    (isError && invalidData.newFeatures) ||
    (isErrorDraft && invalidDataDraft.newFeatures);
  const isErrorKeywords =
    (isError && invalidData.keywords) ||
    (isErrorDraft && invalidDataDraft.keywords);
  const isErrorSupportUrl =
    (isError && invalidData.supportUrl) ||
    (isErrorDraft && invalidDataDraft.supportUrl);
  const isErrorMarketingUrl =
    (isError && invalidData.marketingUrl) ||
    (isErrorDraft && invalidDataDraft.marketingUrl);
  const isErrorCopyRight =
    (isError && invalidData.copyRight) ||
    (isErrorDraft && invalidDataDraft.copyRight);

  const helperTextDescription =
    (!otherData.description && t('fieldRequired')) ||
    (otherData.description &&
      otherData.description.length > 4000 &&
      t('tooLong'));

  const helperTextNewFeature =
    otherData.newFeatures &&
    otherData.newFeatures.length > 4000 &&
    t('tooLong');

  const helperTextSupportUrl =
    (!otherData.supportUrl && t('fieldRequired')) ||
    (otherData.supportUrl &&
      !regexUrl.test(otherData.supportUrl) &&
      t('urlIsInvalid'));

  const handleChange = (e) =>
    setOtherData({
      ...otherData,
      [e.target.name]: e.target.value || '',
    });

  return (
    <Box>
      <Typography variant="h4" mb={1}>
        {t('advertisingContent')}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        multiline
        rows={5}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {otherData.advertisingContent.length}/{maxTextLength}
            </InputAdornment>
          ),
        }}
        value={otherData.advertisingContent || ''}
        onChange={handleChange}
        name="advertisingContent"
        placeholder={t('inputContent')}
        error={isErrorAdvertisingContent}
        helperText={isErrorAdvertisingContent && t('tooLong')}
        disabled={disabled}
        id="advertisingContent"
      />
      <Typography variant="h4" mb={1} mt={3}>
        {t('description')} <span className="required">*</span>
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        multiline
        rows={5}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {otherData.description.length}/4000
            </InputAdornment>
          ),
        }}
        value={otherData.description || ''}
        onChange={handleChange}
        name="description"
        placeholder={t('inputContent')}
        error={isErrorDescription}
        helperText={isErrorDescription && helperTextDescription}
        disabled={disabled}
        id="description"
      />
      {isExistOldVersion && (
        <>
          <Typography variant="h4" mb={1} mt={3}>
            {t('whatsNew')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={5}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {otherData.newFeatures.length}/4000
                </InputAdornment>
              ),
            }}
            value={otherData.newFeatures || ''}
            onChange={handleChange}
            name="newFeatures"
            placeholder={t('inputContent')}
            error={isErrorNewFeatures}
            helperText={isErrorNewFeatures && helperTextNewFeature}
            disabled={disabled}
            id="newFeatures"
          />
        </>
      )}
      <Typography variant="h4" mb={1} mt={3}>
        {t('keywords')} <span className="required">*</span>
      </Typography>
      <Autocomplete
        multiple
        freeSolo
        options={[]}
        onChange={(e, value) => setOtherData({ ...otherData, keywords: value })}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder={t('inputContent')}
            error={isErrorKeywords}
            helperText={isErrorKeywords && t('fieldRequired')}
          />
        )}
        value={otherData.keywords || []}
        disabled={disabled}
        id="keywords"
      />
      <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={2} mt={3}>
        <Box>
          <Typography variant="h4" mb={1}>
            {t('supportUrl')} <span className="required">*</span>
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={otherData.supportUrl || ''}
            onChange={handleChange}
            name="supportUrl"
            placeholder={t('inputUrl')}
            error={isErrorSupportUrl}
            helperText={isErrorSupportUrl && helperTextSupportUrl}
            disabled={disabled}
            id="supportUrl"
          />
        </Box>
        <Box>
          <Typography variant="h4" mb={1}>
            {t('marketingUrl')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={otherData.marketingUrl || ''}
            onChange={handleChange}
            name="marketingUrl"
            placeholder={t('inputUrl')}
            error={isErrorMarketingUrl}
            helperText={isErrorMarketingUrl && t('urlIsInvalid')}
            disabled={disabled}
            id="marketingUrl"
          />
        </Box>

        <Box>
          <Typography variant="h4" mb={1}>
            {t('copyRight')}
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={otherData.copyRight || ''}
            onChange={handleChange}
            name="copyRight"
            placeholder={t('inputUrl')}
            error={isErrorCopyRight}
            helperText={isErrorCopyRight && t('urlIsInvalid')}
            disabled={disabled}
            id="copyRight"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OtherInformation;
