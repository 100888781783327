import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AccordionDetails, Typography } from '@mui/material';
import apis from '@src/apis';
import { APP_VERSION_STATUS, DATE_TIME_FORMAT } from '@src/constants';
import EmptyImage from '@src/assets/image/empty.png';
import ROUTES from '@src/constants/route';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import moment from 'moment';
import CustomTable from '@src/components/CustomTable';
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAppEvaluation,
} from './index.style';
import StatusLabel from './StatusLabel';

const AppEvaluationTab = ({
  title,
  versions,
  defaultExpand,
  organizationId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const heads = [
    { label: t('createdAt'), valueName: 'createdAt', align: 'left' },
    { label: t('version'), valueName: 'version', align: 'left' },
    { label: t('status'), valueName: 'status', align: 'center' },
  ];

  const formattedVersions = versions.map((version = {}) => ({
    ...version,
    createdAt: moment(version.createdAt).format(DATE_TIME_FORMAT),
    status: <StatusLabel status={version.status} center />,
  }));

  const handleClickRow = (row) => {
    history.push(
      ROUTES.APP_EVALUATION_DETAIL.replace(':organizationId', organizationId)
        .replace(':appId', row?.appId)
        .replace(':versionId', row?.id),
    );
  };
  useEffect(() => {
    if (defaultExpand) setExpanded(title);
  }, [defaultExpand]);

  return (
    <>
      <StyledAccordion
        expanded={expanded === title}
        onChange={handleChange(title)}
        disableGutters
        elevation={0}
        square
      >
        <StyledAccordionSummary
          expandIcon={<ArrowForwardIosSharpIcon fontSize="small" />}
        >
          <Typography className="title">{title}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <CustomTable
            items={formattedVersions}
            heads={heads}
            disablePagination
            onClickRow={handleClickRow}
          />
        </AccordionDetails>
      </StyledAccordion>
    </>
  );
};

const AppEvaluation = ({ organizationId, appId }) => {
  const [versions, setVersions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchReviews = async () => {
    try {
      setIsLoading(true);
      const res = await apis.app.getAppVersions(organizationId, appId);
      if (res?.status) setVersions(res?.appVersions || []);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  const processReviews = versions.filter((version = {}) =>
    [APP_VERSION_STATUS.WAITING_REVIEW, APP_VERSION_STATUS.REJECTED].includes(
      version.status,
    ),
  );

  const completedReviews = versions.filter((version = {}) =>
    [
      APP_VERSION_STATUS.PUBLISHED,
      APP_VERSION_STATUS.BANNED,
      APP_VERSION_STATUS.PUBLISH_SCHEDULED,
      APP_VERSION_STATUS.APPROVED,
    ].includes(version.status),
  );

  const renderContent = () => {
    if (isLoading) return <></>;

    if (processReviews?.length === 0 && completedReviews?.length === 0)
      return (
        <div className="no-data-img">
          <img src={EmptyImage} className="img" alt="empty" />
          <Typography>{t('noVersion')}</Typography>
        </div>
      );

    return (
      <>
        {processReviews?.length > 0 && (
          <AppEvaluationTab
            title={t('inProgress')}
            versions={processReviews}
            defaultExpand
            organizationId={organizationId}
          />
        )}
        {completedReviews?.length > 0 && (
          <AppEvaluationTab
            title={t('completed')}
            versions={completedReviews}
            defaultExpand
            organizationId={organizationId}
          />
        )}
      </>
    );
  };

  return <StyledAppEvaluation>{renderContent()}</StyledAppEvaluation>;
};

export default AppEvaluation;
