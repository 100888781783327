import React from 'react';
import moment from 'moment';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PUBLISH_METHOD } from '@src/constants';
import CustomPasswordInput from '@src/components/CustomInput/CustomPasswordInput';
import { EVALUATION_OPTIONS, RELEASE_OPTIONS } from './data';

const MAX_NOTE_LENGTH = 500;

const AppEvaluation = ({
  evaluationData,
  setEvaluationData,
  publishData,
  setPublishData,
  isError,
  invalidData,
  isErrorDraft,
  invalidDataDraft,
  disabled,
}) => {
  const { t } = useTranslation();

  const isErrorUsername = isError && invalidData.loginInfo.username;
  const isErrorPassword = isError && invalidData.loginInfo.password;
  const isErrorName =
    (isError && invalidData.contactInfo.name) ||
    (isErrorDraft && invalidDataDraft.contactInfo.name);
  const isErrorPhone =
    (isError && invalidData.contactInfo.phoneNumber) ||
    (isErrorDraft && invalidDataDraft.contactInfo.phoneNumber);
  const isErrorEmail =
    (isError && invalidData.contactInfo.email) ||
    (isErrorDraft && invalidDataDraft.contactInfo.email);
  const isErrorNote =
    (isError && invalidData.note) || (isErrorDraft && invalidDataDraft.note);

  const handleLogInRequiredChange = (e) => {
    setEvaluationData({
      ...evaluationData,
      loginInfo: {
        ...evaluationData.loginInfo,
        active: e.target.checked,
      },
    });
  };

  const handleLoginInfoChange = (e) => {
    if (e.target.value.length > 50) {
      e.target.value = e.target.value.slice(0, 50);
    }
    setEvaluationData({
      ...evaluationData,
      loginInfo: {
        ...evaluationData.loginInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleContactDataChange = (e) => {
    if (e.target.value.length > 50) {
      e.target.value = e.target.value.slice(0, 50);
    }
    setEvaluationData({
      ...evaluationData,
      contactInfo: {
        ...evaluationData.contactInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleNoteChange = (e) => {
    setEvaluationData({
      ...evaluationData,
      note: e.target.value,
    });
  };

  // const handleReleaseScheduleChange = (e) => {
  //   setPublishData({
  //     ...publishData,
  //     publishAt: e.target.value,
  //   });
  // };

  return (
    <Box>
      <Typography variant="h4">{t('appReviewInfo')}</Typography>
      <Grid container gap={3} mt={3}>
        <Grid item lg={6}>
          <Typography variant="h6">{t('signinInfo')}</Typography>
          <Typography variant="subtitle1" mb={2}>
            {t('signinInfoDescription')}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!evaluationData.loginInfo.active}
                onChange={handleLogInRequiredChange}
                size="small"
                disabled={disabled}
              />
            }
            label={t('signinRequest')}
          />
          {evaluationData.loginInfo.active && (
            <Box display="flex" gap={1}>
              <TextField
                fullWidth
                label={t('username')}
                variant="outlined"
                size="small"
                margin="dense"
                value={evaluationData.loginInfo.username || ''}
                onChange={handleLoginInfoChange}
                name="username"
                error={isErrorUsername}
                helperText={isErrorUsername && t('invalidUsername')}
                disabled={disabled}
                id="username"
              />
              <CustomPasswordInput
                label={t('password')}
                variant="outlined"
                margin="dense"
                value={evaluationData.loginInfo.password || ''}
                onChange={handleLoginInfoChange}
                name="password"
                type="password"
                error={isErrorPassword}
                helperText={isErrorPassword && t('invalidPassword')}
                disabled={disabled}
                id="password"
              />
            </Box>
          )}
        </Grid>
        <Grid item lg={5} ml="auto">
          <Typography variant="h6">{t('contactInfo')}</Typography>
          <TextField
            fullWidth
            label={t('fullName')}
            variant="outlined"
            size="small"
            margin="dense"
            value={evaluationData.contactInfo.name || ''}
            onChange={handleContactDataChange}
            name="name"
            error={isErrorName}
            helperText={isErrorName && t('invalidName')}
            disabled={disabled}
            id="name"
          />
          <TextField
            fullWidth
            label={t('phoneNumber')}
            variant="outlined"
            size="small"
            margin="dense"
            value={evaluationData.contactInfo.phoneNumber || ''}
            onChange={handleContactDataChange}
            name="phoneNumber"
            error={isErrorPhone}
            helperText={isErrorPhone && t('invalidPhone')}
            disabled={disabled}
            id="phoneNumber"
          />
          <TextField
            fullWidth
            label={t('Email')}
            variant="outlined"
            size="small"
            margin="dense"
            value={evaluationData.contactInfo.email || ''}
            onChange={handleContactDataChange}
            name="email"
            error={isErrorEmail}
            helperText={isErrorEmail && t('invalidEmail')}
            disabled={disabled}
            id="email"
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <Typography variant="h6">{t('notes')}</Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          multiline
          rows={5}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {evaluationData.note.length}/{MAX_NOTE_LENGTH}
              </InputAdornment>
            ),
          }}
          value={evaluationData.note}
          onChange={handleNoteChange}
          error={isErrorNote}
          helperText={isErrorNote && t('fieldRequired')}
          disabled={disabled}
          id="note"
        />
      </Box>
      <Box mt={3}>
        <Typography variant="h5">{t('releaseOptions')}</Typography>
        <RadioGroup value={publishData.publishMethod}>
          {RELEASE_OPTIONS.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Radio
                  color="primary"
                  onChange={() =>
                    setPublishData({
                      ...publishData,
                      publishMethod: option.value,
                      publishAt:
                        option.value === PUBLISH_METHOD.SCHEDULE
                          ? moment().format('YYYY-MM-DDTHH:mm')
                          : null,
                    })
                  }
                  checked={publishData.publishMethod === option.value}
                  size="small"
                  disabled={disabled}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
        {/* {publishData.publishMethod === PUBLISH_METHOD.SCHEDULE && (
          <TextField
            variant="outlined"
            size="small"
            margin="dense"
            value={publishData.publishAt}
            onChange={handleReleaseScheduleChange}
            type="datetime-local"
            error={isError && !publishData.publishAt}
            helperText={isError && !publishData.publishAt && t('fieldRequired')}
            disabled={disabled}
            id="publishAt"
          />
        )} */}
      </Box>
      <Box mt={3}>
        <Typography variant="h6">{t('evaluationSetting')}</Typography>
        <RadioGroup value={publishData.resetReview}>
          {EVALUATION_OPTIONS.map((option) => (
            <FormControlLabel
              key={option.value}
              control={
                <Radio
                  color="primary"
                  checked={publishData.resetReview === option.value}
                  onChange={() =>
                    setPublishData({
                      ...publishData,
                      resetReview: option.value,
                    })
                  }
                  size="small"
                  disabled={disabled}
                />
              }
              label={option.label}
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default AppEvaluation;
