import { SERVICE } from '@src/constants';
import api from './api';

export const getApps = async (organizationId, params) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const createApp = async (organizationId, data) => {
  const res = await api({
    method: 'POST',
    url: `/organizations/${organizationId}/apps`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};

export const getApp = async (organizationId, appId) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps/${appId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateApp = async (organizationId, appId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};

export const createAppVersion = async (organizationId, appId, data) => {
  const res = await api({
    method: 'POST',
    url: `/organizations/${organizationId}/apps/${appId}/versions`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};

export const updateAppVersion = async (
  organizationId,
  appId,
  versionId,
  data,
) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}/versions/${versionId}`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};

export const submitAppVersion = async (
  organizationId,
  appId,
  versionId,
  data,
) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}/versions/${versionId}/submit`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};

export const getAppVersionReviews = async (organizationId, appId) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps/${appId}/version-reviews`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getAppVersionReview = async (
  organizationId,
  appId,
  versionReviewId,
) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps/${appId}/version-reviews/${versionReviewId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const cancelSubmitAppVersion = async (
  organizationId,
  appId,
  versionId,
) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}/versions/${versionId}/cancel-submit`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const publishAppVersion = async (organizationId, appId, versionId) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}/versions/${versionId}/publish`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const requestChangeAppVersion = async (
  organizationId,
  appId,
  versionId,
) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}/versions/${versionId}/request-change`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const getAppVersions = async (organizationId, appId) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps/${appId}/versions`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};
