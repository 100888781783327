import React from 'react';
import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import {
  DATE_TIME_PICKER_TYPES,
  END_TIME_DEFAULT,
  START_TIME_DEFAULT,
} from '@src/constants';
import { useSearchParams } from '@src/hooks';
import ReportTable from './ReportTable';

const Report = () => {
  const { params, addParams } = useSearchParams();
  const {
    appName = '',
    startTime = START_TIME_DEFAULT,
    endTime = END_TIME_DEFAULT,
  } = params;

  const onChangeStartDate = (value) => addParams({ startTime: value });

  const onChangeEndDate = (value) => addParams({ endTime: value });

  return (
    <>
      <Box display="flex" flexDirection="column" mb={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
            Báo cáo
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <TextField
              size="small"
              variant="outlined"
              placeholder="Nhập tên ứng dụng"
              value={appName}
              onChange={(e) => {
                addParams({ appName: e.target.value, page: 1 });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" weight="280px" />
                  </InputAdornment>
                ),
              }}
            />
            <CustomDatePickerRange
              startDate={startTime}
              endDate={endTime}
              handleChangeStartDate={onChangeStartDate}
              handleChangeEndDate={onChangeEndDate}
              type={DATE_TIME_PICKER_TYPES.DATE}
              isRefresh={false}
              hasFromText={false}
            />
            <LoadingButton variant="contained">Xuất báo cáo</LoadingButton>
          </Box>
        </Box>
      </Box>
      <ReportTable startTime={startTime} endTime={endTime} />
    </>
  );
};

export default Report;
