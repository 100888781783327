import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledImageSelection = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StyledGeneralInformation = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .video-card {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 20px;
  }
`;

export const StyledIntroductoryPhoto = styled(Box)`
  .tabs {
    margin: 12px 0px;
    min-height: unset;

    .tab {
      padding: 6px 16px;
      min-width: 160px;
      min-height: unset;
      font-size: 14px;
      font-weight: 500;
    }
  }
`;

export const StyledPlatformSupport = styled(Box)``;

export const StyledLoadingButton = styled(LoadingButton)`
  width: 150px;
  margin-top: 20px;
`;
