import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CustomTable from '@src/components/CustomTable';
import { Avatar, Box, Tooltip, Typography } from '@mui/material';
import {
  DEFAULT_APP_ICON,
  INIT_PAGINATION,
  REPORT_GROUP_OPTION,
} from '@src/constants';
import { COLOR } from '@src/styles/color';
import useSearchParams from '@src/hooks/useSearchParams';
import useFormatNumber from '@src/hooks/useFormatNumber';
import apis from '@src/apis';
import { REPORT_CHARTS } from '@src/constants/chart';

const START_TIME_DEFAULT = new Date(
  moment().subtract(1, 'months').startOf('day').toDate(),
);
const END_TIME_DEFAULT = new Date(moment().endOf('day').toDate());

const StatsValue = ({ value, changed, onClickDetail, sx = {} }) => {
  const { formatNumber, toLocaleString } = useFormatNumber();
  const color = changed > 0 ? COLOR.success.base : 'error';

  return (
    <Box display="flex" justifyContent="flex-end" sx={sx}>
      <Box display="flex" flexDirection="column" width="fit-content">
        <Tooltip title={toLocaleString(value)} placement="top">
          <Typography
            variant="h4b"
            textAlign="end"
            sx={{ '&:hover': { color: COLOR.info.base } }}
            onClick={(e) => {
              e.stopPropagation();
              onClickDetail();
            }}
          >
            {formatNumber(value)}
          </Typography>
        </Tooltip>
        <Tooltip
          title={`${changed > 0 ? '+' : ''}${toLocaleString(changed)}%`}
          placement="top"
        >
          <Typography
            variant="body2"
            color={changed === 0 ? COLOR.blueGray[64] : color}
            textAlign="end"
          >
            {`${changed > 0 ? '+' : ''}${formatNumber(changed)}%`}
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );
};

const ReportTable = () => {
  const { params = {}, addParams } = useSearchParams();
  const {
    startTime = START_TIME_DEFAULT,
    endTime = END_TIME_DEFAULT,
    appName,
    page = 1,
  } = params;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );

  const [data, setData] = useState({
    apps: [],
    pagination: { ...INIT_PAGINATION, page },
  });

  const goToDetail = (appId, report) => {
    addParams({
      appId,
      startTime: startTime || START_TIME_DEFAULT,
      endTime: endTime || END_TIME_DEFAULT,
      report,
      group: REPORT_GROUP_OPTION.DAY,
    });
  };

  const heads = [
    {
      label: 'Tên ứng dụng',
      valueName: 'name',
      align: 'left',
    },
    {
      label: 'Doanh thu',
      valueName: 'revenue',
      align: 'right',
      width: '15%',
    },
    {
      label: 'Số thuê bao tương tác',
      valueName: 'user',
      align: 'right',
      width: '15%',
    },
    {
      label: 'Sản lượng giao dịch',
      valueName: 'transaction',
      align: 'right',
      width: '15%',
    },
    {
      label: <Box paddingRight="20px">Số thuê bao hoạt động</Box>,
      valueName: 'userActive',
      align: 'right',
      width: '15%',
    },
  ];

  const fetchAppsReport = async () => {
    try {
      const res = await apis.report.getAppReport(organizationId, {
        appName,
        startTime,
        endTime,
        pageNum: page || 1,
        unLimited: false,
      });
      if (res?.status);
      setData((prevData) => ({
        ...prevData,
        apps: res?.result?.data || [],
        pagination: {
          page: res?.result?.pager?.currentPageNum || 1,
          limit: res?.result?.pager?.limit || 10,
          total: res?.result?.pager?.totalCount || 0,
          totalPages: res?.result?.pager?.lastPageNum || 1,
        },
      }));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangePage = (p) => addParams({ page: p });

  useEffect(() => {
    fetchAppsReport();
  }, [startTime, endTime, appName, organizationId, page]);

  const formatData =
    data.apps?.map((item) => ({
      ...item,
      name: (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            variant="rounded"
            src={
              item?.latestPublishedAppVersion?.appImageUrl || DEFAULT_APP_ICON
            }
            alt={item.name}
            sizes="small"
          />
          <Typography variant="h6b" className="table-cell">
            {item.name}
          </Typography>
        </Box>
      ),
      revenue: (
        <StatsValue
          value={item?.report?.revenue?.total}
          changed={item?.report?.revenue?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.REVENUE)}
        />
      ),
      user: (
        <StatsValue
          value={item?.report?.user?.total}
          changed={item?.report?.user?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.USER)}
        />
      ),

      transaction: (
        <StatsValue
          sx={{ paddingRight: '20px' }}
          value={item?.report?.transaction?.total}
          changed={item?.report?.transaction?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.TRANSACTION)}
        />
      ),
      userActive: (
        <StatsValue
          sx={{ paddingRight: '20px' }}
          value={item?.report?.userActive?.total}
          changed={item?.report?.userActive?.changed}
          onClickDetail={() => goToDetail(item.id, REPORT_CHARTS.USER_ACTIVE)}
        />
      ),
      id: item.id,
    })) || [];
  return (
    <CustomTable
      items={formatData}
      heads={heads}
      checkClassList={false}
      onClickRow={(row) => {
        addParams({
          appId: row.id,
          startTime: startTime || START_TIME_DEFAULT,
          endTime: endTime || END_TIME_DEFAULT,
        });
      }}
      maxHeight="calc(100vh - 200px)"
      isStickyHeader
      pagination={data.pagination}
      onChangePagination={handleChangePage}
    />
  );
};

export default ReportTable;
