import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { APP_VERSION_STATUS } from '@src/constants';
import { StyledLoadingButton } from './index.style';

const ActionButtons = ({
  status,
  handleSaveDraft,
  handleClickSubmitForReview,
  handleClickCancelSubmit,
  handlePublish,
  handleRequestChangeAppVersion,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  if ([APP_VERSION_STATUS.DRAFT].includes(status))
    return (
      <Box display="flex" gap={1}>
        <StyledLoadingButton
          variant="outlined"
          onClick={handleSaveDraft}
          loading={isSubmitting}
        >
          {t('saveDraft')}
        </StyledLoadingButton>
        <StyledLoadingButton
          variant="contained"
          color="primary"
          onClick={handleClickSubmitForReview}
          loading={isSubmitting}
        >
          {t('submitForReview')}
        </StyledLoadingButton>
      </Box>
    );
  if ([APP_VERSION_STATUS.WAITING_REVIEW].includes(status))
    return (
      <StyledLoadingButton
        variant="contained"
        color="primary"
        onClick={handleClickCancelSubmit}
        loading={isSubmitting}
      >
        {t('cancelReviewRequest')}
      </StyledLoadingButton>
    );
  if ([APP_VERSION_STATUS.APPROVED].includes(status))
    return (
      <Box display="flex" gap={1}>
        <StyledLoadingButton
          variant="contained"
          color="primary"
          onClick={handlePublish}
          loading={isSubmitting}
        >
          {t('publish')}
        </StyledLoadingButton>
        <StyledLoadingButton
          variant="outlined"
          color="primary"
          onClick={handleRequestChangeAppVersion}
          loading={isSubmitting}
        >
          {t('cancelReviewRequest')}
        </StyledLoadingButton>
      </Box>
    );

  if (
    [
      APP_VERSION_STATUS.REJECTED,
      APP_VERSION_STATUS.PUBLISH_SCHEDULED,
    ].includes(status)
  )
    return (
      <StyledLoadingButton
        variant="contained"
        color="primary"
        onClick={handleRequestChangeAppVersion}
        loading={isSubmitting}
      >
        {t('cancelReviewRequest')}
      </StyledLoadingButton>
    );

  return <></>;
};

export default ActionButtons;
