import { actionTypes } from './actions';

export const initialState = {
  userInfo: {},
  organization: {
    selected: {},
    list: [],
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER: {
      const { userInfo } = action;
      return { ...state, userInfo };
    }
    case actionTypes.UPDATE_LIST_ORGANIZATION: {
      const { listOrganization } = action;
      return {
        ...state,
        organization: { ...state.organization, list: listOrganization },
      };
    }
    case actionTypes.UPDATE_SELECTED_ORGANIZATION: {
      const { selectedOrganization } = action;
      return {
        ...state,
        organization: { ...state.organization, selected: selectedOrganization },
      };
    }
    default:
      return state;
  }
};

export default userReducer;
