import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppSSO = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .text-red {
    color: ${COLOR.error.base};
  }

  .border-text-red {
    padding: 7px;
    border-radius: 100%;
    border: 1px solid ${COLOR.border.error};
  }

  .guide {
    color: ${COLOR.primary.base};
    cursor: pointer;
    text-decoration: underline;
  }

  .action {
    width: 150px;
  }

  .card-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 12px;
  }

  .title-input {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .title-input-grid {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .copy-input {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;
