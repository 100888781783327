import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material';
import { Search, Sync } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import apis from '@src/apis';
import CustomTable from '@src/components/CustomTable';
import { formatNumber } from '@src/utils/formatNumber';
import {
  DATE_TIME_FORMAT,
  DATE_TIME_PICKER_TYPES,
  END_TIME_DEFAULT,
  INIT_PAGINATION,
  PAGINATION_LIMIT,
  START_TIME_DEFAULT,
  TRANSACTION_ACTION,
} from '@src/constants';
import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import debounce from '@src/utils/debounce';
import { StyledPaymentHistory } from './index.style';

const Action = ({ action }) => {
  const { t } = useTranslation();

  switch (action) {
    case TRANSACTION_ACTION.REGISTER:
      return <span className="label text-success">{t(action)}</span>;
    case TRANSACTION_ACTION.CANCEL:
      return <span className="label text-danger">{t(action)}</span>;
    case TRANSACTION_ACTION.RENEW:
      return <span className="label text-info">{t(action)}</span>;
    case TRANSACTION_ACTION.RENEW_ERROR:
      return <span className="label text-info">{t(action)}</span>;
    default:
      return <></>;
  }
};

const INIT_FILTER = {
  inputText: '',
  status: '',
  action: '',
  startTime: START_TIME_DEFAULT,
  endTime: END_TIME_DEFAULT,
};

const AppPaymentHistory = ({ organizationId, appId }) => {
  const [data, setData] = useState({
    payments: [],
    pagination: INIT_PAGINATION,
  });

  const [filter, setFilter] = useState(INIT_FILTER);
  const [inputText, setInputText] = useState(filter.inputText);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const heads = [
    { label: t('index'), valueName: 'index', align: 'center' },
    { label: t('emailOrPhoneNumber'), valueName: 'contact', align: 'left' },
    { label: t('packageName'), valueName: 'packageName', align: 'left' },
    { label: t('money'), valueName: 'price', align: 'right' },
    { label: t('sessionId'), valueName: 'sessionId', align: 'center' },
    { label: t('packageCode'), valueName: 'packageCode', align: 'left' },
    { label: t('time'), valueName: 'createdAt', align: 'center' },
    { label: t('paymentChannel'), valueName: 'channel', align: 'center' },
    { label: t('action'), valueName: 'action', align: 'center' },
  ];

  const formattedPayment = data?.payments?.map((transaction = {}, index) => ({
    ...transaction,
    index,
    createdAt: (
      <div className="nowrap">
        {moment(transaction.createdAt).format(DATE_TIME_FORMAT)}
      </div>
    ),
    packageName: <div className="nowrap">{transaction.package?.name}</div>,
    packageCode: (
      <div className="nowrap">{transaction.package?.packageCode}</div>
    ),
    contact: transaction.user?.email || transaction.user?.phoneNumber,
    price: formatNumber(transaction.chargePrice),
    action: <Action action={transaction.action} />,
  }));

  const fetchTransactions = async (page) => {
    try {
      const params = {
        inputText: filter.inputText,
        pageNum: page || data.pagination.page,
        limit: PAGINATION_LIMIT,
      };

      if (filter.action !== '') params.action = filter.action;
      if (filter.startTime) params.startTime = filter.startTime;
      if (filter.endTime) params.endTime = filter.endTime;

      const res = await apis.transaction.getTransactions(
        organizationId,
        appId,
        params,
      );
      if (res?.status)
        setData((prevData) => ({
          ...prevData,
          payments: res?.result?.data || [],
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handleChangePage = (page) => fetchTransactions(page);

  const handleChangeAction = (e) =>
    setFilter({ ...filter, action: e.target.value });

  const handleSearch = (e) => {
    const { value } = e.target;
    setInputText(value);
    debounce(() => setFilter({ ...filter, inputText: value }))();
  };

  const onChangeStartDate = (value) =>
    setFilter((prevState) => ({ ...prevState, startTime: value }));

  const onChangeEndDate = (value) =>
    setFilter((prevState) => ({ ...prevState, endTime: value }));

  const onReloadData = () =>
    setFilter((prevState) => ({
      ...prevState,
      status: '',
      action: '',
      startTime: START_TIME_DEFAULT,
      endTime: END_TIME_DEFAULT,
    }));

  useEffect(() => {
    fetchTransactions(INIT_PAGINATION.page);
  }, []);

  useEffect(() => {
    fetchTransactions(INIT_PAGINATION.page);
    setData({ ...data, pagination: INIT_PAGINATION });
  }, [filter]);

  return (
    <StyledPaymentHistory>
      <div className="filter">
        <TextField
          size="small"
          variant="outlined"
          className="input-search"
          placeholder={t('orderSearchPlaceholder')}
          value={inputText}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="primary" fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <div className="selects">
          <TextField
            select
            variant="outlined"
            size="small"
            label={t('action')}
            className="select"
            value={filter.action}
            onChange={handleChangeAction}
          >
            {Object.keys(TRANSACTION_ACTION).map((action) => (
              <MenuItem key={action} value={action}>
                {t(action)}
              </MenuItem>
            ))}
          </TextField>

          <div className="date-filter">
            <CustomDatePickerRange
              startDate={filter.startTime}
              endDate={filter.endTime}
              handleChangeStartDate={onChangeStartDate}
              handleChangeEndDate={onChangeEndDate}
              type={DATE_TIME_PICKER_TYPES.DATE}
              isRefresh={false}
              hasFromText={false}
            />
            <Tooltip title={t('reloadData')}>
              <IconButton onClick={onReloadData}>
                <Sync />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </div>
      <CustomTable
        items={formattedPayment}
        heads={heads}
        pagination={data.pagination}
        onChangePagination={handleChangePage}
      />
    </StyledPaymentHistory>
  );
};
export default AppPaymentHistory;
