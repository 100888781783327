import useSearchParams from '@src/hooks/useSearchParams';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { Box, Tooltip, Typography } from '@mui/material';
import { REPORT_GROUP_OPTION } from '@src/constants';
import { COLOR } from '@src/styles/color';
import React from 'react';
import Chart from 'react-apexcharts';

const AreaChart = ({ series }) => {
  const options = {
    chart: {
      type: 'area',
      animations: { enabled: false },
      toolbar: { show: false },
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'straight', width: 2, colors: [COLOR.standardBlue[100]] },
    xaxis: {
      show: false,
      labels: { show: false },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: { labels: { show: false }, min: 0 },
    grid: { show: false },
    tooltip: { enabled: false },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      width="100%"
      height="100%"
    />
  );
};

const LineChartBlock = ({ data }) => {
  const { addParams } = useSearchParams();
  const { formatNumber, toLocaleString } = useFormatNumber();

  const { value, changed, label, type } = data;
  const color = changed > 0 ? COLOR.success.base : 'error';

  const series = [{ name: 'Series 1', data: data.series || [] }];

  const handleClick = () =>
    addParams({ report: type, group: REPORT_GROUP_OPTION.DAY });

  return (
    <Box
      sx={{
        borderRadius: '12px',
        border: '1px solid rgba(60, 60, 67, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        pr: '10px',
        pb: '25px',
        gap: '30px',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <Box display="flex" flexDirection="column" p="28px">
        <Typography variant="subtitle2">{label}</Typography>
        <Box display="flex" justifyContent="flex-start">
          <Box display="flex" alignItems="center" gap="10px">
            <Tooltip title={toLocaleString(value || 0)} placement="top">
              <Typography variant="h3" textAlign="end">
                {formatNumber(value)}
              </Typography>
            </Tooltip>
            <Tooltip
              title={`${changed && changed > 0 ? '+' : ''}${toLocaleString(
                changed || 0,
              )}%`}
              placement="top"
            >
              <Typography
                variant="body2"
                color={changed === 0 ? COLOR.blueGray[64] : color}
                textAlign="end"
              >{`${changed > 0 ? '+' : ''}${formatNumber(
                changed,
              )}%`}</Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box height="calc((100vh - 500px) / 2)">
        <AreaChart series={series} />
      </Box>
    </Box>
  );
};

export default LineChartBlock;
