import apis from '@src/apis';
import { getFileExtension } from '@src/utils/file';

const uploadAppImageFile = async (file, suffix = '') => {
  const extension = getFileExtension(file);
  if (!extension) return null;

  const presignedUrlRes = await apis.upload.getUploadAppImagePresignedUrl(
    extension,
    suffix,
  );
  if (!presignedUrlRes || !presignedUrlRes.status) return null;

  const { url: presignedUrl } = presignedUrlRes.result;
  const { pathname, origin } = new URL(presignedUrl);

  const uploadRes = await apis.upload.uploadByPresignedUrl(presignedUrl, file);
  if (uploadRes.status !== 200) return null;

  return `${origin}${pathname}`;
};

const getBase64 = async (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });

const uploadBase64Image = async (file) => {
  const extension = getFileExtension(file);
  if (!extension) return null;

  const files = await getBase64(file);
  const result = await apis.upload.uploadBase64(files, extension);

  return result?.url;
};

export { uploadAppImageFile, uploadBase64Image };
