import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ALL, DEFAULT_APP_ICON } from '@src/constants';
import apis from '@src/apis';
import AppList from './AppList';
import FilterApp from './FilterApp';
import { StyledAppList } from './index.style';

const LIMIT_LARGE = 15;
const LIMIT_MEDIUM = 10;

const App = () => {
  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const isMedium = window.innerWidth < 1200;

  const [filter, setFilter] = useState({ inputText: '', status: ALL });
  const [data, setData] = useState({
    listApps: [],
    pagination: {
      page: 1,
      limit: isMedium ? LIMIT_MEDIUM : LIMIT_LARGE,
      total: 0,
      totalPages: 0,
    },
  });

  const getImageUrl = async (app) => {
    if (app?.latestPublishedAppVersion) {
      return app.latestPublishedAppVersion.appImageUrl;
    }

    return app?.prepareSubmissionAppVersion?.appImageUrl || DEFAULT_APP_ICON;
  };

  const fetchData = async (page) => {
    try {
      const params = {
        inputText: filter.inputText,
        status: filter.status === ALL ? null : filter.status,
        pageNum: page || 1,
        limit: isMedium ? LIMIT_MEDIUM : LIMIT_LARGE,
      };
      const res = await apis.app.getApps(organizationId, params);
      if (res?.status) {
        const listApps = await Promise.all(
          res?.result?.data.map(async (app) => {
            const imageUrl = await getImageUrl(app);
            return {
              ...app,
              imageUrl,
            };
          }),
        );
        setData((prevData) => ({
          ...prevData,
          listApps,
          pagination: {
            page: res?.result?.pager?.currentPageNum || 1,
            limit: res?.result?.pager?.limit || LIMIT_MEDIUM,
            total: res?.result?.pager?.totalCount || 0,
            totalPages: res?.result?.pager?.lastPageNum || 1,
          },
        }));
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const handlePageChange = (page) => fetchData(page);

  useEffect(() => {
    fetchData();
  }, [filter, organizationId]);

  return (
    <StyledAppList>
      <FilterApp filter={filter} setFilter={setFilter} setData={setData} />
      <AppList
        data={data}
        onChangePage={handlePageChange}
        organizationId={organizationId}
      />
    </StyledAppList>
  );
};

export default App;
