import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import apis from '@src/apis';
import CustomPagination from '@src/components/CustomPagination';
import CommentItem from './CommentItem';

const Comments = ({ organizationId, appId, sort }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [stars, setStars] = useState(0);
  const [comments, setComments] = useState({
    data: [],
    loading: false,
    pagination: {
      page: 1,
      limit: 10,
      total: 0,
      totalPages: 0,
    },
  });

  const fetchComments = async (params) => {
    try {
      setComments((prevComments) => ({
        ...prevComments,
        loading: true,
      }));
      const res = await apis.comments.getComments(
        organizationId,
        appId,
        params,
      );
      if (res?.status) {
        const { data, pager } = res?.result;
        setComments((prevComments) => ({
          ...prevComments,
          data,
          pagination: {
            page: pager?.currentPageNum || 1,
            limit: pager?.limit || 10,
            total: pager?.totalCount || 0,
            totalPages: pager?.lastPageNum || 1,
          },
        }));
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setComments((prevComments) => ({
        ...prevComments,
        loading: false,
      }));
    }
  };

  const handleChangePage = (page) => {
    fetchComments({
      filterOption: sort,
      stars: stars === 0 ? null : stars,
      pageNum: page,
    });
  };

  useEffect(() => {
    fetchComments({
      filterOption: sort,
      stars: stars === 0 ? null : stars,
      pageNum: 1,
    });
  }, [sort, stars]);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom={1}
        borderColor="divider"
        pb={2}
        mb={2}
      >
        <TextField
          select
          variant="outlined"
          size="small"
          value={stars}
          onChange={(e) => setStars(e.target.value)}
        >
          <MenuItem value={0}>Tất cả đánh giá</MenuItem>
          {Array.from({ length: 5 }, (_, i) => (
            <MenuItem key={i + 1} value={5 - i}>
              {5 - i} sao
            </MenuItem>
          ))}
        </TextField>
        <Typography variant="body2">
          {comments.pagination.total} {t('bình luận')}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {comments.data.map((comment) => (
          <CommentItem
            key={comment.id}
            comment={comment}
            organizationId={organizationId}
            appId={appId}
          />
        ))}
      </Box>
      {comments.data.length === 0 ? (
        <Typography variant="h6" align="center">
          {t('noDataFound')}
        </Typography>
      ) : (
        <CustomPagination
          currentPage={comments.pagination.page}
          limit={comments.pagination.limit}
          total={comments.pagination.total}
          onChangePagination={handleChangePage}
        />
      )}
    </Box>
  );
};

export default Comments;
