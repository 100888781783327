import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { STAT_OVERVIEW_CHART } from '@src/constants/chart';
import apis from '@src/apis';
import DonutChartBlock from '../../components/ApexCharts/DonutChartBlock';
import LoadingOverview from './LoadingOverview';

const AppStats = ({ isReLoad }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchStatAppsData = async () => {
    try {
      const res = await apis.stat.statApps(organizationId);
      if (res?.status) {
        const { appStatus: appStatusCount = {} } = res?.result || {};
        const {
          published = 0,
          waitingReview = 0,
          approved = 0,
          publishScheduled = 0,
          rejected = 0,
          banned = 0,
          draft = 0,
        } = appStatusCount;

        const appStatus = {
          type: STAT_OVERVIEW_CHART.APP_STATUS,
          title: t('appStatus'),
          labels: [
            t('published'),
            t('waitingReview'),
            t('approved'),
            t('publishScheduled'),
            t('rejected'),
            t('banned'),
            t('draft'),
          ],
          series: [
            published,
            waitingReview,
            approved,
            publishScheduled,
            rejected,
            banned,
            draft,
          ],
          plotLabel: t('app'),
          titleInfo: t('statAppTitleInfo'),
        };

        return appStatus;
      }
      return {};
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      return {};
    }
  };

  const fetchStatData = async () => {
    setLoading(true);
    const statApps = await fetchStatAppsData();
    setData(statApps);
    setLoading(false);
  };

  useEffect(() => {
    fetchStatData();
  }, [isReLoad]);

  if (loading) return <LoadingOverview />;

  return (
    <DonutChartBlock
      data={data}
      hasLegendValue
      colors={[
        '#176BA0',
        '#19AADE',
        '#EB548C',
        '#EF7E32',
        '#EE9A3A',
        '#DE542C',
        '#41AC69',
      ]}
    />
  );
};
export default AppStats;
