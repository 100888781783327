export const actionTypes = {
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_LIST_ORGANIZATION: 'UPDATE_ORGANIZATION',
  UPDATE_SELECTED_ORGANIZATION: 'UPDATE_SELECTED_ORGANIZATION',
};

export const updateUser = (userInfo) => ({
  type: actionTypes.UPDATE_USER,
  userInfo,
});

export const updateListOrganization = (listOrganization) => ({
  type: actionTypes.UPDATE_LIST_ORGANIZATION,
  listOrganization,
});

export const updateSelectedOrganization = (selectedOrganization) => ({
  type: actionTypes.UPDATE_SELECTED_ORGANIZATION,
  selectedOrganization,
});
