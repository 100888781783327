import { SERVICE } from '@src/constants';
import snakecaseKeys from 'snakecase-keys';
import { IAM_CLIENT_ID, IAM_REALM, IAM_URL } from '@src/configs';
import api from './api';

export const checkUserPermission = async ({ method, uri, organizationId }) => {
  const res = await api({
    method: 'GET',
    url: '/auth/check-route-permission',
    params: { method, uri, organizationId },
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const checkPermittedMenus = async ({ organizationId }) => {
  const res = await api({
    method: 'GET',
    url: '/auth/permitted-menus',
    params: { organizationId },
    source: SERVICE.ACCOUNT,
  });
  return res;
};

export const login = async ({ email, password }) => {
  const res = await api({
    method: 'POST',
    url: `${IAM_URL}/auth/realms/${IAM_REALM}/protocol/openid-connect/token`,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    data: new URLSearchParams(
      snakecaseKeys({
        grant_type: 'password',
        client_id: IAM_CLIENT_ID,
        username: email,
        password,
      }),
    ),
    source: SERVICE.IAM,
  });

  return res;
};
