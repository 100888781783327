import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Search } from '@mui/icons-material';
import debounce from '@src/utils/debounce';
import camelcase from 'camelcase';
import { ALL, FILTER_APP_STATUS } from '@src/constants';
import CreateAppPopup from './CreateAppPopup';

const ButtonCreateCSP = ({ setData }) => {
  const { t } = useTranslation();
  const [showPopupCreateAdmin, setShowPopupCreateAdmin] = useState(false);

  return (
    <Box marginLeft="auto">
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setShowPopupCreateAdmin(true)}
      >
        {t('add')}
      </Button>
      <CreateAppPopup
        open={showPopupCreateAdmin}
        onClose={() => setShowPopupCreateAdmin(false)}
        setListData={setData}
      />
    </Box>
  );
};

const FilterApp = ({ filter, setFilter, setData }) => {
  const { t } = useTranslation();
  const [inputText, setInputText] = useState(filter.inputText);

  const handleChangeInputText = (e) => {
    setInputText(e.target.value);
    debounce(() => setFilter({ ...filter, inputText: e.target.value }))();
  };

  useEffect(() => {
    setInputText(filter.inputText);
  }, [filter.inputText]);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="h3" mr="auto">
        {t('listApp')}
      </Typography>
      <ButtonCreateCSP setData={setData} />
      <Box minWidth={180}>
        <TextField
          select
          label={filter.status === null ? t('status') : ''}
          variant="outlined"
          size="small"
          fullWidth
          value={filter.status}
          onChange={(e) => setFilter({ ...filter, status: e.target.value })}
          InputLabelProps={{ shrink: false }}
        >
          <MenuItem value={ALL}>{t('all')}</MenuItem>
          {Object.values(FILTER_APP_STATUS).map((status) => (
            <MenuItem key={status} value={status}>
              {t(camelcase(status))}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <TextField
        size="small"
        variant="outlined"
        placeholder={t('search')}
        value={inputText}
        onChange={handleChangeInputText}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search color="primary" fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default FilterApp;
