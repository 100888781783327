import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Box, Divider, Typography } from '@mui/material';
import {
  APP_VERSION_STATUS,
  PLATFORM,
  REGEX_ANDROID_LINK,
  REGEX_IOS_LINK,
  PUBLISH_METHOD,
  VERSION_REGEX,
  SCREEN_SHOTS_TYPE,
} from '@src/constants';
import apis from '@src/apis';
import {
  regexEmail,
  regexName,
  regexPhone,
  regexUrl,
  regexYoutube,
} from '@src/constants/regex';
import { uploadBase64Image } from '@src/services/upload';
import Popup from '@src/components/Popup';

import { StyledAppDetail as StyledAppDetailInfo } from '../AppDetailInfo/index.style';
import GeneralInformation from './GeneralInformation';
import AppEvaluation from './AppEvaluation';
import IntroductoryPhoto from './IntroductoryPhoto';
import PlatformSupport from './PlatformSupport';
import OtherInformation from './OtherInformation';
import ActionButtons from './ActionButtons';

const MAX_TEXT_LENGTH = 500;

const AppCreateVersion = ({
  versionData,
  organizationId,
  appId,
  onChangeApp,
  isExistOldVersion,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const disabled = ![APP_VERSION_STATUS.DRAFT].includes(versionData?.status);

  const isPublished = [
    APP_VERSION_STATUS.PUBLISHED,
    APP_VERSION_STATUS.BANNED,
  ].includes(versionData?.status);

  const [isError, setIsError] = useState(false);
  const [isErrorDraft, setIsErrorDraft] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonData, setCommonData] = useState({
    appImageUrl: '',
    featuredImageUrl: '',
    thumbnailImageUrl: '',
    videoUrl: '',
    version: '',
  });
  const [screenShots, setScreenShots] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [otherData, setOtherData] = useState({
    advertisingContent: '',
    description: '',
    newFeatures: '',
    keywords: [],
    supportUrl: '',
    marketingUrl: '',
    copyRight: '',
  });
  const [evaluationData, setEvaluationData] = useState({
    loginInfo: {
      active: false,
      username: '',
      password: '',
    },
    contactInfo: {
      name: '',
      email: '',
      phoneNumber: '',
    },
    note: '',
  });
  const [publishData, setPublishData] = useState({
    publishMethod: PUBLISH_METHOD.AUTO,
    publishAt: null,
    resetReview: false,
  });
  const [popup, setPopup] = useState({
    open: false,
    title: '',
    content: '',
    onOk: () => {},
  });
  const [countClickSubmit, setCountClickSubmit] = useState(0);

  const validatePlatform = (platform, isDraft = false) => {
    if (!platform?.name) return false;

    if (!isDraft && !platform?.url) return false;

    if (platform?.name === PLATFORM.IOS && platform?.url)
      return REGEX_IOS_LINK.test(platform.url);

    if (platform?.name === PLATFORM.ANDROID && platform?.url)
      return REGEX_ANDROID_LINK.test(platform.url);

    if (platform?.name === PLATFORM.WEB_APP && platform?.url)
      return regexUrl.test(platform.url);

    return true;
  };

  const validateScreenShots = (screenShotsData) => {
    if (!screenShotsData.length) return false;

    const hasAndroid = platforms.some(
      (platform) => platform.name === PLATFORM.ANDROID,
    );
    const hasIos = platforms.some((platform) => platform.name === PLATFORM.IOS);
    const hasWeb = platforms.some(
      (platform) => platform.name === PLATFORM.WEB_APP,
    );

    if (hasAndroid || hasIos) {
      const phoneScreenShots = screenShotsData.find(
        (item) => item.type === SCREEN_SHOTS_TYPE.PHONE,
      );
      if (phoneScreenShots?.urls.length < 2) return false;
    }

    if (hasWeb) {
      const websiteScreenShots = screenShotsData.find(
        (item) => item.type === SCREEN_SHOTS_TYPE.WEBSITE,
      );
      if (websiteScreenShots?.urls.length < 2) return false;
    }

    return true;
  };

  const invalidDataDraft = {
    version:
      !commonData.version ||
      (commonData.version && !VERSION_REGEX.test(commonData.version)),
    videoUrl: commonData.videoUrl && !regexYoutube.test(commonData.videoUrl),
    platforms:
      !platforms.length ||
      (platforms.length &&
        !platforms.every((platform) => validatePlatform(platform, true))),
    advertisingContent: otherData.advertisingContent.length > MAX_TEXT_LENGTH,
    description: otherData.description.length > 4000,
    newFeatures: isExistOldVersion && otherData.newFeatures.length > 4000,
    marketingUrl:
      otherData.marketingUrl && !regexUrl.test(otherData.marketingUrl),
    copyRight: otherData.copyRight && !regexUrl.test(otherData.copyRight),
    contactInfo: {
      name:
        evaluationData.contactInfo.name &&
        !regexName.test(evaluationData.contactInfo.name),
      email:
        evaluationData.contactInfo.email &&
        !regexEmail.test(evaluationData.contactInfo.email),
      phoneNumber:
        evaluationData.contactInfo.phoneNumber &&
        !regexPhone.test(evaluationData.contactInfo.phoneNumber),
    },
    note: evaluationData.note.length > MAX_TEXT_LENGTH,
  };

  const invalidData = {
    version:
      !commonData.version ||
      (commonData.version && !VERSION_REGEX.test(commonData.version)),
    appImageUrl: !commonData.appImageUrl,
    featuredImageUrl: !commonData.featuredImageUrl,
    thumbnailImageUrl: !commonData.thumbnailImageUrl,
    videoUrl: commonData.videoUrl && !regexYoutube.test(commonData.videoUrl),
    platforms:
      !platforms.length ||
      !platforms.every((platform) => validatePlatform(platform, false)),
    screenShots: !validateScreenShots(screenShots),
    advertisingContent: otherData.advertisingContent.length > MAX_TEXT_LENGTH,
    description: !otherData.description || otherData.description.length > 4000,
    newFeatures: isExistOldVersion && otherData.newFeatures.length > 4000,
    keywords: !otherData.keywords.length,
    supportUrl: !regexUrl.test(otherData.supportUrl),
    marketingUrl:
      otherData.marketingUrl && !regexUrl.test(otherData.marketingUrl),
    copyRight: otherData.copyRight && !regexUrl.test(otherData.copyRight),
    loginInfo: {
      username:
        evaluationData.loginInfo.active && !evaluationData.loginInfo.username,
      password:
        evaluationData.loginInfo.active && !evaluationData.loginInfo.password,
    },
    contactInfo: {
      name:
        evaluationData.contactInfo.name &&
        !regexName.test(evaluationData.contactInfo.name),
      email:
        evaluationData.contactInfo.email &&
        !regexEmail.test(evaluationData.contactInfo.email),
      phoneNumber:
        evaluationData.contactInfo.phoneNumber &&
        !regexPhone.test(evaluationData.contactInfo.phoneNumber),
    },
    note: evaluationData.note.length > MAX_TEXT_LENGTH,
    publishAt:
      publishData.publishMethod === PUBLISH_METHOD.SCHEDULE &&
      !publishData.publishAt,
  };

  const validateDraft = () => {
    setIsError(false);
    if (invalidDataDraft.version) {
      document.getElementById('version').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.videoUrl) {
      document.getElementById('videoUrl').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.platforms) {
      if (!platforms.length) {
        document
          .getElementById('platform')
          .scrollIntoView({ behavior: 'smooth' });
        setIsError(true);
        return false;
      }
      const platform = platforms.find((item) => !validatePlatform(item, true));
      document.getElementById(`${platform.name}-url`).focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.advertisingContent) {
      document.getElementById('advertisingContent').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.description) {
      document.getElementById('description').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (isExistOldVersion && invalidDataDraft.newFeatures) {
      document.getElementById('newFeatures').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.marketingUrl) {
      document.getElementById('marketingUrl').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.copyRight) {
      document.getElementById('copyRight').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.contactInfo.name) {
      document.getElementById('name').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.contactInfo.email) {
      document.getElementById('email').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.contactInfo.phoneNumber) {
      document.getElementById('phoneNumber').focus();
      setIsErrorDraft(true);
      return false;
    }
    if (invalidDataDraft.note) {
      document.getElementById('note').focus();
      setIsErrorDraft(true);
      return false;
    }

    setIsErrorDraft(false);
    return true;
  };

  const validate = () => {
    setCountClickSubmit(countClickSubmit + 1);
    setIsErrorDraft(false);
    if (invalidData.version) {
      document.getElementById('version').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.videoUrl) {
      document.getElementById('videoUrl').focus();
      setIsError(true);
      return false;
    }
    if (
      invalidData.appImageUrl ||
      invalidData.featuredImageUrl ||
      invalidData.thumbnailImageUrl
    ) {
      setIsError(true);
      return false;
    }
    if (invalidData.platforms) {
      if (!platforms.length) {
        document
          .getElementById('platform')
          .scrollIntoView({ behavior: 'smooth' });
        setIsError(true);
        return false;
      }
      const platform = platforms.find((item) => !validatePlatform(item));
      document.getElementById(`${platform.name}-url`).focus();
      setIsError(true);
      return false;
    }
    if (invalidData.screenShots) {
      document
        .getElementById('screenshots')
        .scrollIntoView({ behavior: 'smooth' });
      setIsError(true);
      return false;
    }
    if (invalidData.advertisingContent) {
      document.getElementById('advertisingContent').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.description) {
      document.getElementById('description').focus();
      setIsError(true);
      return false;
    }
    if (isExistOldVersion && invalidData.newFeatures) {
      document.getElementById('newFeatures').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.keywords) {
      document.getElementById('keywords').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.supportUrl) {
      document.getElementById('supportUrl').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.marketingUrl) {
      document.getElementById('marketingUrl').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.copyRight) {
      document.getElementById('copyRight').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.loginInfo.username) {
      document.getElementById('username').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.loginInfo.password) {
      document.getElementById('password').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.contactInfo.name) {
      document.getElementById('name').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.contactInfo.email) {
      document.getElementById('email').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.contactInfo.phoneNumber) {
      document.getElementById('phoneNumber').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.note) {
      document.getElementById('note').focus();
      setIsError(true);
      return false;
    }
    if (invalidData.publishAt) {
      document.getElementById('publishAt').focus();
      setIsError(true);
      return false;
    }

    setIsError(false);
    return true;
  };

  const handleUploadImage = async (file) => {
    try {
      if (!file) return '';
      if (typeof file === 'string') {
        return file;
      }
      const presignedUrl = await uploadBase64Image(file);
      if (presignedUrl) {
        return presignedUrl;
      }
      return null;
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      return null;
    }
  };

  const formatScreenShots = async () => {
    const result = await Promise.all(
      screenShots.map(async (item) => ({
        type: item.type,
        urls: await Promise.all(
          item.urls.map(async (urlItem) => {
            if (typeof urlItem === 'string') {
              return urlItem;
            }
            const presignedUrl = await handleUploadImage(urlItem.file);
            return presignedUrl;
          }),
        ),
      })),
    );
    return result;
  };

  const handleSaveDraft = async () => {
    try {
      if (!validateDraft()) return;
      setIsSubmitting(true);
      const saveData = {
        version: commonData.version,
        videoUrl: commonData.videoUrl,
        appImageUrl: await handleUploadImage(commonData.appImageUrl),
        featuredImageUrl: await handleUploadImage(commonData.featuredImageUrl),
        thumbnailImageUrl: await handleUploadImage(
          commonData.thumbnailImageUrl,
        ),
        screenShots: await formatScreenShots(),
        platforms,
        ...otherData,
        reviewAppInfo: {
          ...evaluationData,
          note: evaluationData.note || null,
        },
        ...publishData,
      };
      const res = await apis.app.updateAppVersion(
        organizationId,
        appId,
        versionData.id,
        saveData,
      );
      if (res?.status) {
        onChangeApp();
        enqueueSnackbar(t('saveDraftSuccess'), { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmitForReview = async () => {
    try {
      setIsSubmitting(true);
      const saveData = {
        version: commonData.version,
        videoUrl: commonData.videoUrl,
        appImageUrl: await handleUploadImage(commonData.appImageUrl),
        featuredImageUrl: await handleUploadImage(commonData.featuredImageUrl),
        thumbnailImageUrl: await handleUploadImage(
          commonData.thumbnailImageUrl,
        ),
        screenShots: await formatScreenShots(),
        platforms,
        ...otherData,
        reviewAppInfo: {
          ...evaluationData,
          note: evaluationData.note || null,
        },
        ...publishData,
      };
      const res = await apis.app.submitAppVersion(
        organizationId,
        appId,
        versionData.id,
        saveData,
      );
      if (res?.status) {
        enqueueSnackbar(t('submitForReviewSuccess'), { variant: 'success' });
        onChangeApp();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelSubmit = async () => {
    try {
      setIsSubmitting(true);
      const res = await apis.app.cancelSubmitAppVersion(
        organizationId,
        appId,
        versionData.id,
      );
      if (res?.status) {
        enqueueSnackbar(t('cancelReviewRequestSuccess'), {
          variant: 'success',
        });
        onChangeApp();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePublish = async () => {
    try {
      setIsSubmitting(true);
      const res = await apis.app.publishAppVersion(
        organizationId,
        appId,
        versionData.id,
      );
      if (res?.status) {
        enqueueSnackbar(t('publishSuccess'), { variant: 'success' });
        onChangeApp();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRequestChangeAppVersion = async () => {
    try {
      setIsSubmitting(true);
      const res = await apis.app.requestChangeAppVersion(
        organizationId,
        appId,
        versionData.id,
      );
      if (res?.status) {
        enqueueSnackbar(t('requestChangeSuccess'), { variant: 'success' });
        onChangeApp();
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  const popupData = {
    submitForReview: {
      title: t('confirmSend'),
      content: t('confirmSendContent'),
      onOk: handleSubmitForReview,
    },
    cancelSubmit: {
      title: t('confirmCancel'),
      content: t('confirmCancelContent'),
      onOk: handleCancelSubmit,
    },
  };

  const handleClickSubmitForReview = () => {
    if (!validate()) return;
    setPopup({
      open: true,
      ...popupData.submitForReview,
    });
  };

  const handleClickCancelSubmit = () =>
    setPopup({
      open: true,
      ...popupData.cancelSubmit,
    });

  const fetchData = async () => {
    if (versionData) {
      setCommonData({
        version: versionData?.version || '',
        appImageUrl: versionData?.appImageUrl,
        featuredImageUrl: versionData?.featuredImageUrl,
        thumbnailImageUrl: versionData?.thumbnailImageUrl,
        videoUrl: versionData?.videoUrl,
      });

      const phoneUrls =
        versionData?.screenShots?.find(
          (item) => item.type === SCREEN_SHOTS_TYPE.PHONE,
        )?.urls || [];

      const websiteUrls =
        versionData?.screenShots?.find(
          (item) => item.type === SCREEN_SHOTS_TYPE.WEBSITE,
        )?.urls || [];

      setScreenShots([
        { type: SCREEN_SHOTS_TYPE.PHONE, urls: phoneUrls },
        { type: SCREEN_SHOTS_TYPE.WEBSITE, urls: websiteUrls },
      ]);

      setPlatforms(versionData?.platforms || []);
      setOtherData({
        advertisingContent: versionData?.advertisingContent || '',
        description: versionData?.description || '',
        newFeatures: versionData?.newFeatures || '',
        keywords: versionData?.keywords || [],
        supportUrl: versionData?.supportUrl || '',
        marketingUrl: versionData?.marketingUrl || '',
        copyRight: versionData?.copyRight || '',
      });
      setEvaluationData({
        loginInfo: {
          active: versionData?.reviewAppInfo?.loginInfo?.active,
          username: versionData?.reviewAppInfo?.loginInfo?.username,
          password: versionData?.reviewAppInfo?.loginInfo?.password,
        },
        contactInfo: {
          name: versionData?.reviewAppInfo?.contactInfo?.name,
          email: versionData?.reviewAppInfo?.contactInfo?.email,
          phoneNumber: versionData?.reviewAppInfo?.contactInfo?.phoneNumber,
        },
        note: versionData?.reviewAppInfo?.note || '',
      });
      setPublishData({
        publishMethod: versionData?.publishMethod || PUBLISH_METHOD.AUTO,
        publishAt: moment(versionData?.publishAt)
          .subtract(7, 'hours')
          .format('YYYY-MM-DDTHH:mm'),
        resetReview: versionData?.resetReview || false,
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [versionData]);

  return (
    <StyledAppDetailInfo>
      <Box className="header-card">
        <Box className="header-card-title">
          <Typography variant="h4">
            {t('version')} {versionData?.version}
          </Typography>
          <ActionButtons
            status={versionData?.status}
            handleSaveDraft={handleSaveDraft}
            handleClickSubmitForReview={handleClickSubmitForReview}
            handleClickCancelSubmit={handleClickCancelSubmit}
            handlePublish={handlePublish}
            handleRequestChangeAppVersion={handleRequestChangeAppVersion}
            isSubmitting={isSubmitting}
          />
        </Box>
        <Typography variant="subtitle1">
          {isPublished ? t('versionPublished') : t('versionWillBePublished')}
        </Typography>
      </Box>
      <Divider />
      <GeneralInformation
        commonData={commonData}
        setCommonData={setCommonData}
        isError={isError}
        isErrorDraft={isErrorDraft}
        invalidData={invalidData}
        disabled={disabled}
      />
      <Divider />
      <PlatformSupport
        platforms={platforms}
        setPlatforms={setPlatforms}
        isError={isError}
        isErrorDraft={isErrorDraft}
        disabled={disabled}
      />
      <Divider />
      <IntroductoryPhoto
        screenShots={screenShots}
        setScreenShots={setScreenShots}
        isError={isError}
        invalidData={invalidData}
        platformData={platforms}
        disabled={disabled}
        countClickSubmit={countClickSubmit}
      />
      <Divider />
      <OtherInformation
        otherData={otherData}
        setOtherData={setOtherData}
        isError={isError}
        maxTextLength={MAX_TEXT_LENGTH}
        invalidData={invalidData}
        isErrorDraft={isErrorDraft}
        invalidDataDraft={invalidDataDraft}
        isExistOldVersion={isExistOldVersion}
        disabled={disabled}
      />
      <Divider />
      <AppEvaluation
        evaluationData={evaluationData}
        setEvaluationData={setEvaluationData}
        publishData={publishData}
        setPublishData={setPublishData}
        isError={isError}
        invalidData={invalidData}
        isErrorDraft={isErrorDraft}
        invalidDataDraft={invalidDataDraft}
        disabled={disabled}
      />
      <Popup
        open={popup.open}
        onClose={() => setPopup({ open: false })}
        title={popup.title}
        content={popup.content}
        onOk={popup.onOk}
      />
    </StyledAppDetailInfo>
  );
};

export default AppCreateVersion;
