import { COLOR } from './color';

const BORDER_RADIUS = {
  popup: '24px 24px 0px 0px',
  container: '8px',
};

const BOX_SHADOW = '0px 2px 32px 0px rgba(0, 0, 0, 0.32)';

const BOX_SHADOW_CARD =
  '0px 4px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.08)';

const BOX_SHADOW_SIDEBAR = '0px 4px 16px 0px rgba(0, 0, 0, 0.16)';

const BORDER = `1px solid ${COLOR.pastelBlue[100]}`;

const BREAKPOINTS = {
  XS: 0,
  SM: 600,
  MD: 900,
  LG: 1200,
  XL: 1536,
};

const TOP_BAR_HEIGHT = 56;

const SIDE_BAR_WIDTH = 240;
const SIDE_BAR_WIDTH_COLLAPSED = 72;

const AVATAR_COLORS = [
  COLOR.primary.base,
  COLOR.success.base,
  COLOR.warning.base,
  COLOR.info.base,
];

export {
  BORDER_RADIUS,
  BOX_SHADOW,
  BREAKPOINTS,
  TOP_BAR_HEIGHT,
  SIDE_BAR_WIDTH,
  SIDE_BAR_WIDTH_COLLAPSED,
  BOX_SHADOW_CARD,
  BOX_SHADOW_SIDEBAR,
  BORDER,
  AVATAR_COLORS,
};
