import React, { useState, useEffect } from 'react';
import apis from '@src/apis';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  APP_VERSION_REVIEW_ACTION,
  APP_VERSION_STATUS,
  DATE_TIME_FORMAT,
} from '@src/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ROUTES from '@src/constants/route';
import RickTextEditor from './RickTextEditor';
import { StyledAppEvaluationDetail } from './index.style';
import StatusLabel from './StatusLabel';

const findLatestUpdate = (reviews = [], version = {}) => {
  let latestElement = null;
  let latestCreatedAt = moment(0);

  reviews.forEach((el) => {
    if (el.version !== version.version) return;
    const createdAt = moment(el.createdAt);
    if (createdAt.isAfter(latestCreatedAt)) {
      latestElement = el;
      latestCreatedAt = createdAt;
    }
  });

  return latestElement;
};

const findFirstSubmit = (reviews = [], version = {}) => {
  let firstSubmit = null;
  let latestCreatedAt = moment();

  reviews
    .filter((el) => el.action === APP_VERSION_REVIEW_ACTION.SUBMIT)
    .filter((el) => el.version === version.version)
    .forEach((el) => {
      const createdAt = moment(el.createdAt);
      if (createdAt.isBefore(latestCreatedAt)) {
        firstSubmit = el;
        latestCreatedAt = createdAt;
      }
    });

  return firstSubmit;
};

const AppEvaluationDetail = ({ appId, organizationId, versionId }) => {
  const [version, setVersion] = useState({});
  const [versionReview, setVersionReview] = useState({});
  const [firstActorSubmit, setFirstActorSubmit] = useState('');
  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const fetchVersion = async () => {
    try {
      const res = await apis.app.getAppVersions(organizationId, appId);
      if (res?.status) {
        const versions = res?.appVersions || [];
        const matchVersion = versions.find((el) => el.id === versionId);

        if (matchVersion) setVersion(matchVersion);
        return matchVersion;
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
      return null;
    }
    return null;
  };

  const fetchReviewVersion = async (resVersion) => {
    try {
      const res = await apis.app.getAppVersionReviews(organizationId, appId);
      if (res?.status) {
        const reviews = res?.result;
        const latestUpdate = findLatestUpdate(reviews, resVersion);
        const firstSubmit = findFirstSubmit(reviews, resVersion);

        if (firstSubmit) setFirstActorSubmit(firstSubmit?.performedBy?.name);
        if (latestUpdate) setVersionReview(latestUpdate);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchData = async () => {
    if (appId && organizationId && versionId) {
      const resVersion = await fetchVersion();
      if (resVersion) fetchReviewVersion(resVersion);
    }
  };

  const handleBack = () =>
    history.push(
      ROUTES.APP_EVALUATION.replace(':organizationId', organizationId).replace(
        ':appId',
        appId,
      ),
    );

  useEffect(() => {
    fetchData();
  }, [organizationId, appId, versionId]);

  const haveReason =
    [APP_VERSION_STATUS.BANNED, APP_VERSION_STATUS.REJECTED].includes(
      version?.status,
    ) && versionReview?.reason;

  const lastUpdateActor = versionReview?.performedBy?.name;

  return (
    <StyledAppEvaluationDetail>
      <Box className="title-app-box">
        <>
          <IconButton onClick={handleBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </IconButton>
          <Typography className="title-app">{t('versionReview')}</Typography>
        </>
      </Box>
      <Divider />
      <div>
        <div className="info">
          <div className="attribute">
            <h3>{t('createdAt')}</h3>
            <p>{moment(versionReview?.createdAt).format(DATE_TIME_FORMAT)}</p>
          </div>
          <div className="attribute">
            <h3>{t('performBy')}</h3>
            <p>{firstActorSubmit}</p>
          </div>
          <div className="attribute">
            <h3>{t('performFrom')}</h3>
            <p>{lastUpdateActor}</p>
          </div>
          <div className="attribute">
            <h3>{t('Submission ID')}</h3>
            <p>{versionReview?.id}</p>
          </div>
          <div className="attribute">
            <h3>{t('version')}</h3>
            <p>{versionReview?.version}</p>
          </div>
        </div>
      </div>
      <div className="status">
        <h3>{t('status')}</h3>
        <StatusLabel status={version?.status} />
      </div>
      {haveReason && (
        <>
          <h3>{t('reason')}</h3>
          <RickTextEditor reason={versionReview?.reason} readonly />
        </>
      )}
    </StyledAppEvaluationDetail>
  );
};

export default AppEvaluationDetail;
