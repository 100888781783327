import { Avatar, Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { DEFAULT_APP_ICON } from '@src/constants';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TitleApp = ({ name, imgSrc = DEFAULT_APP_ICON, onBack, isBack }) => (
  <Box className="title-app-box">
    {isBack && (
      <IconButton onClick={onBack}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </IconButton>
    )}
    <Avatar variant="rounded" src={imgSrc} />
    <Typography variant="h2" className="title-app">
      {name}
    </Typography>
  </Box>
);

export default TitleApp;
