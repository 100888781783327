import { SERVICE } from '@src/constants';
import api from './api';

export const getOrganization = async (organizationId) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}`,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const updateOrganization = async (organizationId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};
