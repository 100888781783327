import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledOrganizationInfo = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .file-input {
    display: none;
  }

  .header-card {
    .title {
      color: ${COLOR.darkestBlue[100]};
    }
  }

  .body-card {
    width: 100%;
    margin: 24px 0px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .footer-card {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    .button {
      min-width: 120px;
    }
  }

  .disabled {
    padding: 12px;
  }

  .textfield-input {
    .MuiOutlinedInput-root {
      border-radius: 24px;
      height: 40px;
      background-color: ${COLOR.darkBlue[4]};
      fieldset {
        border: none;
      }
    }
  }

  .date-picker {
    max-width: unset !important;
  }

  .input-box {
    display: flex;
    align-items: center;

    .required {
      color: ${COLOR.red[100]};
    }
  }

  .label-input {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;

    span {
      color: ${COLOR.error.base};
    }
  }
`;
