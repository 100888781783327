import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Avatar, Box, Typography } from '@mui/material';
import apis from '@src/apis';
import { DEFAULT_APP_ICON } from '@src/constants';
import RankTable from '@src/components/RankReport/RankTable';
import Tooltip from '@src/components/Tooltip';
import { formatNumber as formatNumberUtil } from '@src/utils/formatNumber';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { COLOR } from '@src/styles/color';
import LoadingOverview from './LoadingOverview';

const AppRevenueStats = ({ startTime, endTime, isReLoad }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { formatNumber } = useFormatNumber();

  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isDecrease, setIsDecrease] = useState(true);
  const [total, setTotal] = useState(0);
  const [percent, setPercent] = useState(0);

  const LIMIT = 5;
  const TYPE = t('hasRevenue');
  const OBJECTS = t('application');
  const status = isDecrease ? t('highest') : t('lowest');
  const statusTooltip = !isDecrease ? t('highest') : t('lowest');

  const heads = [
    { label: t('appName'), valueName: 'appInfo', align: 'left' },
    { label: t('Revenue'), valueName: 'revenue', align: 'right' },
    { label: t('changed'), valueName: 'changed', align: 'right' },
  ];

  const colorChanged = (changed) => {
    if (changed > 0) return COLOR.success.base;
    if (changed < 0) return COLOR.error.base;
    return COLOR.blueGray[64];
  };

  const formatData = data.map((item) => ({
    ...item,
    appInfo: (
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar
          variant="rounded"
          src={item?.latestPublishedAppVersion?.appImageUrl || DEFAULT_APP_ICON}
          alt="img"
          className="img"
          sx={{ width: '28px', height: '28px' }}
        />
        <Typography variant="body2">{item?.name}</Typography>
      </Box>
    ),
    revenue: (
      <Tooltip
        title={formatNumberUtil(item?.report?.revenue?.total)}
        placement="right"
        arrow
      >
        <Typography>{formatNumber(item?.report?.revenue?.total)}</Typography>
      </Tooltip>
    ),
    changed: (
      <Typography
        variant="body2"
        color={() => colorChanged(item?.report?.revenue?.changed)}
      >{`${item?.report?.revenue?.changed > 0 ? '+' : ''}${formatNumber(
        item?.report?.revenue?.changed,
      )}%`}</Typography>
    ),
  }));

  const handleChangeSort = () => {
    setIsDecrease(!isDecrease);
  };

  const fetchAppRevenueStatsData = async () => {
    try {
      setLoading(true);
      const res = await apis.stat.appRevenueStats(organizationId, {
        startTime,
        endTime,
        limit: LIMIT,
        sortRevenue: isDecrease ? 'desc' : 'asc',
      });
      if (res?.status) {
        const { limitRevenue, data: revenueData, totalRevenue } = res?.result;
        const fixedLimitRevenue = limitRevenue
          ? parseFloat(((limitRevenue / totalRevenue) * 100).toFixed(2))
          : 0;
        setTotal(limitRevenue || 0);
        setPercent(fixedLimitRevenue);
        setData(revenueData || []);
      }
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    } finally {
      setLoading(false);
      if (isFirstLoading) setIsFirstLoading(false);
    }
  };

  useEffect(() => {
    fetchAppRevenueStatsData();
  }, [startTime, endTime, isDecrease, isReLoad]);

  if (loading && isFirstLoading) return <LoadingOverview />;

  return (
    <RankTable
      data={formatData}
      heads={heads}
      title={t('topRank', {
        limit: LIMIT,
        type: TYPE,
        object: OBJECTS,
        status,
      })}
      limit={LIMIT}
      type={TYPE}
      object={OBJECTS}
      status={statusTooltip}
      onChangeSort={handleChangeSort}
      isDecrease={isDecrease}
      loading={loading}
      total={total}
      percent={percent}
    />
  );
};
export default AppRevenueStats;
