import ROUTES from '@src/constants/route';

export const sideBarListOptions = [
  {
    id: 1,
    label: 'Chung',
    children: [
      {
        id: 1,
        label: 'appInfo',
        path: ROUTES.APP_DETAIL_INFO,
      },
      {
        id: 2,
        label: 'Lịch sử phê duyệt',
        path: ROUTES.APP_EVALUATION,
      },
      {
        id: 4,
        label: 'packages',
        path: ROUTES.APP_PACKAGES,
      },
      {
        id: 5,
        label: 'paymentHistory',
        path: ROUTES.PAYMENT_HISTORY,
      },
    ],
  },
  {
    id: 2,
    label: 'Tích hợp',
    children: [
      {
        id: 6,
        label: 'credentials',
        path: ROUTES.CREDENTIAL_APP,
      },
      {
        id: 7,
        label: 'webhookConfig',
        path: ROUTES.APP_WEBHOOK,
      },
    ],
  },
  {
    id: 3,
    label: 'CSKH',
    path: ROUTES.APP_EVALUATION,
    children: [
      {
        id: 1,
        label: 'evaluate',
        path: ROUTES.APP_COMMENT,
      },
    ],
  },
];
