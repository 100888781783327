import React from 'react';
import { Box, LinearProgress, Rating, Typography } from '@mui/material';
import { COLOR } from '@src/styles/color';

const Ranking = ({ rating = {} }) => {
  const rankingData = Array.from({ length: 5 }, (_, index) => {
    const rank = 5 - index;
    return {
      id: index + 1,
      rank,
      value: rating[rank] || 0,
      percentValue: rating[rank]
        ? (rating[rank] / Object.values(rating).reduce((a, b) => a + b, 0)) *
          100
        : 0,
    };
  });
  const totalRating = Object.values(rating).reduce((a, b) => a + b, 0);
  const averageRating =
    totalRating > 0
      ? (
          Object.entries(rating).reduce(
            (acc, [key, value]) => acc + key * value,
            0,
          ) / totalRating
        ).toFixed(1)
      : 0;

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" mb={1}>
          Xếp hạng & nhận xét
        </Typography>
      </Box>
      {totalRating === 0 ? (
        <Box display="flex" alignItems="center">
          <Typography>Ứng dụng vẫn chưa có đánh giá nào.</Typography>
        </Box>
      ) : (
        <Box display="flex">
          <Box width={120}>
            <Box display="flex" mt={2}>
              <Typography variant="h2">{averageRating}</Typography>
              <Typography variant="caption" mt="auto">
                /5
              </Typography>
            </Box>
            <Box width="100%">
              <Typography variant="subtitle2">
                {totalRating} xếp hạng
              </Typography>
            </Box>
          </Box>
          <Box width="100%">
            {rankingData.map((item) => (
              <Box
                key={item.id}
                display="flex"
                alignItems="center"
                gap={1}
                color="grey"
              >
                <Box width={85} height={12} display="flex" justifyContent="end">
                  <Rating
                    name="disabled"
                    value={item.rank}
                    disabled
                    size="small"
                    sx={{ color: COLOR.ink[100] }}
                    max={item.rank}
                  />
                </Box>
                <Typography
                  fontSize={10}
                  fontWeight={400}
                  width={16}
                  textAlign="right"
                >
                  {item.value}
                </Typography>
                <Box width="100%">
                  <LinearProgress
                    variant="determinate"
                    value={item.percentValue}
                    sx={{ borderRadius: 5 }}
                    color="inherit"
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Ranking;
