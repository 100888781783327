import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tabs, Tab, Box, Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartLine,
  faSquarePollVertical,
} from '@fortawesome/free-solid-svg-icons';
import useSearchParams from '@src/hooks/useSearchParams';
import OverviewStats from './OverviewStats';
import Report from './Report';
import ReportApp from './ReportApp';

const STATS_PAGE = { STATISTIC: 'STATISTIC', REPORT: 'REPORT' };

const Statistic = () => {
  const history = useHistory();
  const { params, removeParams } = useSearchParams();
  const { appId } = params;

  if (appId) return <ReportApp />;

  const { t } = useTranslation();
  const [tab, setTab] = useState(
    window.location.pathname.includes('/statistic')
      ? STATS_PAGE.STATISTIC
      : STATS_PAGE.REPORT,
  );

  const tabs = [
    {
      label: t('statistic'),
      value: STATS_PAGE.STATISTIC,
      icon: <FontAwesomeIcon icon={faChartLine} />,
    },
    {
      label: t('report'),
      value: STATS_PAGE.REPORT,
      icon: <FontAwesomeIcon icon={faSquarePollVertical} />,
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);

    removeParams('startTime', 'endTime');
    history.push(
      window.location.pathname.replace(
        tab.toLowerCase(),
        newValue.toLowerCase(),
      ),
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tabs value={tab} onChange={handleChangeTab} className="tabs">
          {tabs.map((item) => (
            <Tab
              key={item.label}
              label={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  {item.icon}
                  <Box>{item.label}</Box>
                </Box>
              }
              value={item.value}
              className="tab"
            />
          ))}
        </Tabs>
      </Box>
      <Divider sx={{ marginBottom: '12px' }} />
      {tab === STATS_PAGE.STATISTIC && (
        <OverviewStats isReLoad={tab === STATS_PAGE.STATISTIC} />
      )}
      {tab === STATS_PAGE.REPORT && (
        <Report isReLoad={tab === STATS_PAGE.REPORT} />
      )}
    </>
  );
};
export default Statistic;
