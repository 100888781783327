import * as user from './user';
import * as auth from './auth';
import * as app from './app';
import * as category from './category';
import * as upload from './upload';
import * as organization from './organization';
import * as transaction from './transaction';
import * as packages from './package';
import * as comments from './comment';
import * as stat from './stat';
import * as report from './report';

export default {
  user,
  auth,
  app,
  category,
  upload,
  organization,
  transaction,
  packages,
  comments,
  stat,
  report,
};
