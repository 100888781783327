import { styled } from '@mui/material/styles';
import { Box, TableCell } from '@mui/material';
import { COLOR } from '@src/styles/color';

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-head {
    font-weight: 590;
    font-size: 14px;
    white-space: nowrap;
    color: black;
    border: none;
    padding: 6px 4px;
  }

  &.MuiTableCell-body {
    font-size: 14px;
    font-weight: 500;
    border: none;
    color: black;
    padding: 6px 4px;
  }
`;

export const StyledCustomTable = styled(Box)`
  .table-cell {
    font-weight: 500;
    font-size: 14px;
  }

  .table-row {
    background-color: ${COLOR.white[100]};
  }

  .table-container {
    border-radius: 16px;
  }
`;
