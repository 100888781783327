import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledSelectOrganization = styled(Box)`
  .title {
    text-align: center;
    padding: 16px;

    img {
      width: 117px;
      height: 32px;
    }
  }

  .content {
    padding: 24px 24px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: center;
  }

  .organization-box {
    display: flex;
    flex-direction: column;

    .organization-item {
      padding: 8px;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;

      &:hover {
        background-color: ${COLOR.ink[8]};
      }
    }

    .selected {
      background-color: ${COLOR.darkBlue[16]};
      &:hover {
        background-color: ${COLOR.darkBlue[16]} !important;
      }
    }

    .divider {
      width: 97%;
      margin: 0 auto;
    }
  }

  .organization-container {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 2px;
    margin-bottom: 29px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: ${COLOR.ink[4]};
    }

    &::-webkit-scrollbar-thumb {
      background: ${COLOR.ink[8]};
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${COLOR.ink[16]};
    }

    &::-webkit-scrollbar-thumb:active {
      background: ${COLOR.ink[16]};
    }

    &::-webkit-scrollbar-thumb:window-inactive {
      background: ${COLOR.ink[16]};
    }
  }

  .account-box {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 24px;
    border: 1px solid ${COLOR.ink[16]};
    width: fit-content;
    margin: 0 auto;
  }

  .logout-button {
    width: fit-content;
    padding: 12px 40px;
    margin: auto;
  }
`;
