import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLOR } from '@src/styles/color';

export const StyledAppWebhook = styled(Box)`
  .text-red {
    color: ${COLOR.error.base};
  }

  .border-text-red {
    padding: 7px;
    border-radius: 100%;
    border: 1px solid ${COLOR.border.error};
  }

  .guide {
    color: ${COLOR.primary.base};
    cursor: pointer;
    text-decoration: underline;
  }

  .action {
    width: 150px;
  }
`;
