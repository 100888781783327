import React from 'react';
import Chart from 'react-apexcharts';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { COLOR } from '@src/styles/color';
import { useTranslation } from 'react-i18next';
import useFormatNumber from '@src/hooks/useFormatNumber';
import { formatNumber as formatNumberUtils } from '@src/utils/formatNumber';
import { Info } from '@mui/icons-material';
import { MIN_HEIGHT_CHART } from '@src/constants/chart';
import Tooltip from '../Tooltip';

const fixedNumber = 0;

const PieChart = ({
  labels,
  series,
  colors,
  changed,
  value,
  color,
  plotLabel = '',
  hasLegendValue,
}) => {
  const { formatNumber } = useFormatNumber();

  const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.down(2000));

  const options = {
    chart: { type: 'donut', toolbar: { show: false } },
    labels,
    colors,
    legend: {
      markers: { width: 21, height: 8, radius: 2 },
      position: 'right',
    },
    tooltip: {
      y: {
        formatter: (v) =>
          !changed && changed !== 0 ? formatNumberUtils(v) : formatNumber(v),
      },
    },
    dataLabels: { enabled: false },
    plotOptions: { pie: { expandOnClick: false } },
  };

  if (hasLegendValue)
    options.legend = {
      ...options.legend,
      formatter(seriesName, opts) {
        const total = opts.w.globals.series.reduce((a, b) => a + b, 0);
        const optValue = opts.w.globals.series[opts.seriesIndex];
        const percent = total
          ? ((optValue / total) * 100).toFixed(fixedNumber)
          : 0;
        return [
          `${seriesName}: <strong>${
            !changed && changed !== 0
              ? formatNumberUtils(optValue)
              : formatNumber(optValue)
          }</strong> (${percent}%)`,
        ];
      },
    };

  if (!changed && changed !== 0)
    options.plotOptions.pie.donut = {
      labels: {
        show: true,
        name: {
          offsetY: 15,
        },
        value: {
          fontSize: '24px',
          fontWeight: 700,
          offsetY: -25,
          color: '#000',
          fontFamily: 'SF Pro Rounded',
        },
        total: {
          show: true,
          showAlways: true,
          fontSize: '14px',
          fontWeight: 510,
          color: '#000',
          fontFamily: 'SF Pro Rounded',
          label: plotLabel,
          formatter: (w) =>
            formatNumberUtils(
              w.globals.seriesTotals.reduce((a, b) => a + b, 0),
            ),
        },
      },
    };
  else
    options.plotOptions.pie.donut = {
      labels: {
        show: true,
        name: {
          offsetY: 20,
        },
        value: {
          fontSize: '24px',
          fontWeight: 700,
          offsetY: -20,
          fontFamily: 'SF Pro Rounded',
          color: '#000',
        },
        total: {
          show: true,
          showAlways: true,
          fontSize: '14px',
          fontWeight: 510,
          fontFamily: 'SF Pro Rounded',
          color: changed === 0 ? COLOR.blueGray[64] : color,
          label: `${changed > 0 ? '+' : ''}${formatNumber(changed)}%`,
          formatter: () => formatNumber(value),
        },
      },
    };

  return (
    <Box
      sx={{
        '.apexcharts-legend-text': {
          fontFamily: 'SF Pro Rounded !important',
          fontSize: `${isLaptop ? '12px' : '14px'} !important`,
        },
        '.apexcharts-legend-series': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          gap: 1,
          width: changed ? '140px' : 'unset',
        },

        width: '100%',
        height: '100%',
      }}
    >
      <Box sx={{ position: 'relative' }} width="100%" height="100%">
        <Chart
          options={options}
          series={series}
          type="donut"
          width="100%"
          height="100%"
        />
      </Box>
    </Box>
  );
};

const DonutChartBlock = ({ data, hasLegendValue = false, colors = [] }) => {
  const { t } = useTranslation();
  const {
    title,
    labels = [],
    series,
    value,
    changed,
    plotLabel = '',
    titleInfo,
  } = data;

  const color = changed > 0 ? COLOR.success.base : 'error';

  return (
    <Box
      sx={{
        borderRadius: '12px',
        border: '1px solid rgba(60, 60, 67, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        gap: '25px',
        p: '25px',
        height: MIN_HEIGHT_CHART,
      }}
    >
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography variant="h4m">{title}</Typography>
        {titleInfo && (
          <Tooltip title={titleInfo} placement="top" arrow>
            <Info fontSize="small" color="disabled" />
          </Tooltip>
        )}
      </Box>

      {!series || series?.length === 0 ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2">{t('noData')}</Typography>
        </Box>
      ) : (
        <PieChart
          series={series}
          colors={colors}
          labels={labels}
          value={value}
          changed={changed}
          color={color}
          hasLegendValue={hasLegendValue}
          plotLabel={plotLabel}
        />
      )}
    </Box>
  );
};

export default DonutChartBlock;
