import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { matchPath } from 'react-router-dom';
import useOrg from '@src/hooks/useOrg';
import ROUTES from '@src/constants/route';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  const currentPath = window.location.pathname;
  const isDisabledSidebar = ['/'].includes(currentPath);
  const { loading } = useOrg();

  if (loading)
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" />
      </Box>
    );

  const isBackgroundWhite =
    matchPath(currentPath, ROUTES.STATISTIC) ||
    matchPath(currentPath, ROUTES.REPORT);

  return (
    <Box
      display="flex"
      minHeight="100vh"
      height="100%"
      bgcolor={isBackgroundWhite ? 'white' : 'transparent'}
      width="calc(100% - 4px)"
    >
      {!isDisabledSidebar && <Sidebar />}
      <Box padding="16px 32px" marginX="auto" width="100%" height="fit-content">
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
