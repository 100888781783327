import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, matchPath } from 'react-router-dom';
import camelcase from 'camelcase';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddCircleOutline } from '@mui/icons-material';
import {
  faBan,
  faCheckCircle,
  faClock,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { APP_VERSION_STATUS } from '@src/constants';
import { sideBarListOptions } from './data';
import CreateVersionPopup from './CreateVersionPopup';

const SidebarApp = ({
  preVersion,
  lastVersion,
  organizationId,
  appId,
  versionId,
  resetApp,
}) => {
  const { t } = useTranslation();
  const currentPath = window.location.pathname;
  const history = useHistory();
  const isAllowCreateVersion = !preVersion;

  const [openPopupCreateVersion, setOpenPopupCreateVersion] = useState(false);

  const handleOpenPopupCreateVersion = () => {
    setOpenPopupCreateVersion(true);
  };

  const handleClosePopupCreateVersion = () => {
    setOpenPopupCreateVersion(false);
  };

  const handleClickInfoItem = ({ path, versionIdRedirect }) => {
    if (path) {
      const newPath = path
        .replace(':organizationId', organizationId)
        .replace(':appId', appId);
      history.push(newPath);
    } else {
      history.push(
        `/organization/${organizationId}/app/${appId}/version/${versionIdRedirect}`,
      );
    }
  };

  const getVersionIcon = (status) => {
    if (status === APP_VERSION_STATUS.PUBLISHED)
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="sm"
          style={{ color: 'green' }}
        />
      );
    if (status === APP_VERSION_STATUS.REJECTED)
      return (
        <FontAwesomeIcon
          icon={faXmarkCircle}
          size="sm"
          style={{ color: 'red' }}
        />
      );
    if (status === APP_VERSION_STATUS.BANNED)
      return (
        <FontAwesomeIcon icon={faBan} style={{ color: 'red' }} size="sm" />
      );
    return (
      <FontAwesomeIcon icon={faClock} size="sm" style={{ color: 'orange' }} />
    );
  };

  const isDisplayTooltip = (text) => text.length >= 28;

  const renderAppVersion = (appVersion) => {
    const textVersion = `${appVersion.version} - ${t(
      camelcase(appVersion.status),
    )}`;

    const isActive = appVersion.id === versionId;

    if (appVersion) {
      return (
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="body2" className="text-item">
            {getVersionIcon(appVersion.status)}
          </Typography>
          <Tooltip
            title={isDisplayTooltip(textVersion) ? textVersion : ''}
            arrow
          >
            <Typography
              className="text-item"
              color={isActive ? 'primary' : 'initial'}
              onClick={() =>
                handleClickInfoItem({ versionIdRedirect: appVersion.id })
              }
            >
              {textVersion}
            </Typography>
          </Tooltip>
        </Box>
      );
    }
    return '';
  };

  const getDefaultNameVersionCreate = () => {
    if (lastVersion) {
      const version = lastVersion.version.split('.');
      version[version.length - 1] = Number(version[version.length - 1]) + 1;
      return version.join('.');
    }
    return '1.0.0';
  };

  return (
    <>
      <Box>
        <Box display="flex" alignItems="end">
          <Typography variant="h4">{t('version')}</Typography>
          {isAllowCreateVersion && (
            <IconButton
              size="small"
              color="inherit"
              onClick={handleOpenPopupCreateVersion}
            >
              <Tooltip title={t('createNewVersion')} arrow>
                <AddCircleOutline fontSize="small" />
              </Tooltip>
            </IconButton>
          )}
        </Box>
        <Box px={1}>
          {preVersion && renderAppVersion(preVersion)}
          {lastVersion && renderAppVersion(lastVersion)}
        </Box>
      </Box>
      <Box>
        {sideBarListOptions.map((item, index) => (
          <Box key={item.id}>
            <Typography variant="h4" mt={index === 0 ? 0 : 3}>
              {t(item.label)}
            </Typography>
            <Box px={1}>
              {item.children.map((child) => (
                <Tooltip
                  key={child.id}
                  title={isDisplayTooltip(t(child.label)) ? t(child.label) : ''}
                  arrow
                >
                  <Typography
                    onClick={() => handleClickInfoItem({ path: child.path })}
                    color={
                      matchPath(currentPath, child.path) ? 'primary' : 'initial'
                    }
                    className="text-item"
                  >
                    {t(child.label)}
                  </Typography>
                </Tooltip>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
      <CreateVersionPopup
        open={openPopupCreateVersion}
        onClose={handleClosePopupCreateVersion}
        organizationId={organizationId}
        appId={appId}
        defaultName={getDefaultNameVersionCreate()}
        resetApp={resetApp}
      />
    </>
  );
};

export default SidebarApp;
