import { Box, Grid, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import React from 'react';
import camelcase from 'camelcase';
import { useHistory } from 'react-router-dom';
import AppItem from '@src/components/AppItem';
import CustomPagination from '@src/components/CustomPagination';
import { APP_VERSION_STATUS } from '@src/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCheckCircle,
  faClock,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';

const AppList = ({ data, onChangePage, organizationId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getVersionIcon = (status) => {
    if (status === APP_VERSION_STATUS.PUBLISHED)
      return (
        <FontAwesomeIcon
          icon={faCheckCircle}
          size="sm"
          style={{ color: 'green' }}
        />
      );
    if (status === APP_VERSION_STATUS.REJECTED)
      return (
        <FontAwesomeIcon
          icon={faXmarkCircle}
          size="sm"
          style={{ color: 'red' }}
        />
      );
    if (status === APP_VERSION_STATUS.BANNED)
      return (
        <FontAwesomeIcon icon={faBan} style={{ color: 'red' }} size="sm" />
      );
    return (
      <FontAwesomeIcon icon={faClock} size="sm" style={{ color: 'orange' }} />
    );
  };

  const getDataVersion = (appVersion) => {
    if (appVersion) {
      return {
        icon: getVersionIcon(appVersion.status),
        text: `${t('version')} ${appVersion.version} - ${t(
          camelcase(appVersion.status),
        )}`,
      };
    }
    return '';
  };

  const handleClickApp = (app) => {
    const versionId = app.prepareSubmissionAppVersionId
      ? app.prepareSubmissionAppVersionId
      : app.latestPublishedAppVersion.id;
    if (versionId) {
      history.push(
        `/organization/${organizationId}/app/${app.id}/version/${versionId}`,
      );
    } else {
      history.push(`/organization/${organizationId}/app/${app.id}/info`);
    }
  };

  return (
    <>
      {data.listApps.length === 0 ? (
        <Box my={5}>
          <Typography variant="h6" align="center">
            {t('noDataFound')}
          </Typography>
        </Box>
      ) : (
        <Box mt={3} padding={2} bgcolor="white" borderRadius={4}>
          <Grid container spacing={2}>
            {data.listApps.map((app) => (
              <Grid item sm={12} md={6} lg={4} key={uuidv4()}>
                <AppItem
                  title={app.name}
                  description={[
                    getDataVersion(app.prepareSubmissionAppVersion),
                    getDataVersion(app.latestPublishedAppVersion),
                  ]}
                  onClick={() => handleClickApp(app)}
                  imgSrc={app.imageUrl}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <CustomPagination
        currentPage={data.pagination.page}
        limit={data.pagination.limit}
        total={data.pagination.total}
        onChangePagination={onChangePage}
      />
    </>
  );
};

export default AppList;
