import {
  ERROR_CODE_SYSTEM,
  SERVICE,
  ERROR_CODE_ACCOUNT_SERVICE,
  ERROR_CODE_API_MANAGEMENT,
} from '@src/constants';

const ACCOUNT_ERROR_MESSAGE = {
  // ERROR CODE USER: 1000 - 1999
  [ERROR_CODE_ACCOUNT_SERVICE.USER_EXISTS]: 'userExists',
  [ERROR_CODE_ACCOUNT_SERVICE.EMAIL_OR_PASSWORD_WRONG]: 'emailOrPasswordWrong',
  [ERROR_CODE_ACCOUNT_SERVICE.USER_NOT_FOUND]: 'userNotFound',
  [ERROR_CODE_ACCOUNT_SERVICE.INVALID_USER]: 'invalidUser',
};

const API_MANAGEMENT_ERROR_MESSAGE = {
  // ERROR CODE IAM: 1000 - 1099
  [ERROR_CODE_API_MANAGEMENT.IAM_GET_ACCESS_TOKEN_ERROR]:
    'iamGetAccessTokenError',
  // ERROR CODE USER: 1100 - 1199
  [ERROR_CODE_API_MANAGEMENT.USER_NOT_FOUND]: 'userNotFound',
  // ERROR CODE APP: 1200 - 1299
  [ERROR_CODE_API_MANAGEMENT.APP_EXIST]: 'appExist',
  [ERROR_CODE_API_MANAGEMENT.APP_NOT_FOUND]: 'appNotFound',
  // ERROR CODE APP VERSION: 1300 - 1399
  [ERROR_CODE_API_MANAGEMENT.APP_VERSION_EXIST]: 'appVersionExist',
  [ERROR_CODE_API_MANAGEMENT.PREPARE_SUBMISSION_APP_VERSION_EXIST]:
    'prepareSubmissionAppVersionExist',
  [ERROR_CODE_API_MANAGEMENT.APP_VERSION_NOT_FOUND]: 'appVersionNotFound',
  [ERROR_CODE_API_MANAGEMENT.APP_VERSION_STATUS_INVALID]:
    'appVersionStatusInvalid',
  // ERROR CODE ORGANIZATION: 4300 - 4399
  [ERROR_CODE_API_MANAGEMENT.ORGANIZATION_NOT_FOUND]: 'organizationNotFound',
  // ERROR CODE CATEGORY: 4400 - 449
  [ERROR_CODE_API_MANAGEMENT.PRIMARY_CATEGORY_NOT_FOUND]:
    'primaryCategoryNotFound',
  [ERROR_CODE_API_MANAGEMENT.SECONDARY_CATEGORY_NOT_FOUND]:
    'secondaryCategoryNotFound',
  [ERROR_CODE_API_MANAGEMENT.PRIMARY_CATEGORY_SECONDARY_CATEGORY_DUPLICATE]:
    'primaryCategorySecondaryCategoryDuplicate',
  [ERROR_CODE_API_MANAGEMENT.PRIMARY_CATEGORY_ALREADY_SECONDARY_CATEGORY]:
    'primaryCategoryAlreadySecondaryCategory',
  [ERROR_CODE_API_MANAGEMENT.SECONDARY_CATEGORY_ALREADY_PRIMARY_CATEGORY]:
    'secondaryCategoryAlreadyPrimaryCategory',
};

const getErrorMessage = (code, service, serverErrorMessage) => {
  let message;

  switch (service) {
    case SERVICE.ACCOUNT:
      message = ACCOUNT_ERROR_MESSAGE[code];
      break;
    case SERVICE.API_MANAGEMENT:
      message = API_MANAGEMENT_ERROR_MESSAGE[code];
      break;
    default:
  }

  if (message) return message;

  switch (code) {
    case ERROR_CODE_SYSTEM.BAD_REQUEST:
      return 'badRequest';
    case ERROR_CODE_SYSTEM.UNAUTHORIZED:
      return 'unauthorized';
    case ERROR_CODE_SYSTEM.FORBIDDEN:
      return 'forbidden';
    case ERROR_CODE_SYSTEM.NOT_FOUND:
      return 'notFound';
    default: {
      if (serverErrorMessage) return serverErrorMessage;
      return 'serverError';
    }
  }
};

export default getErrorMessage;
