import { Grid } from '@mui/material';
import React from 'react';
import { REPORT_CHARTS } from '@src/constants/chart';
import LineChartBlock from '@src/components/ApexCharts/LineChartBlock';
import DonutChartBlock from '@src/components/ApexCharts/DonutChartBlock';

const Report = ({ reportData = {} }) => {
  const parseDonutValue = (data = {}) => ({
    labels: data?.series?.map((el) => el.key) || [],
    series: data?.series?.map((el) => el.total || 0) || [],
    value: data.total,
    changed: data.changed,
  });

  const stats = [
    {
      label: 'Doanh thu',
      type: REPORT_CHARTS.REVENUE,
      value: reportData.revenue?.total,
      changed: reportData.revenue?.changed,
      series: reportData?.revenue?.series?.map((el) => ({
        x: el.key,
        y: el.total,
      })),
    },
    {
      type: REPORT_CHARTS.TRANSACTION,
      label: 'Sản lượng giao dịch',
      value: reportData.transaction?.total,
      changed: reportData.transaction?.changed,
      series: reportData?.transaction?.series?.map((el) => ({
        x: el.key,
        y: el.total,
      })),
    },
    {
      type: REPORT_CHARTS.USER,
      label: 'Số thuê bao tương tác',
      value: reportData.user?.total,
      changed: reportData.user?.changed,
      series: reportData?.user?.series?.map((el) => ({
        x: el.key,
        y: el.total,
      })),
    },

    {
      type: REPORT_CHARTS.USER_ACTIVE,
      label: 'Số người dùng hoạt động',
      value: reportData.userActive?.total,
      changed: reportData.userActive?.changed,
      series: reportData?.userActive?.series?.map((el) => ({
        x: el.key,
        y: el.total,
      })),
    },
  ];

  const donut = [
    {
      type: REPORT_CHARTS.REVENUE_BY_PACKAGE,
      title: 'Tỷ trọng doanh thu các gói cước',
      ...parseDonutValue(reportData.revenueByPkg),
    },
    {
      type: REPORT_CHARTS.REGISTERED_TRANSACTION_BY_PACKAGE,
      title: 'Sản lượng đăng ký gói cước',
      ...parseDonutValue(reportData.registerTrsByPkg),
    },
    {
      type: REPORT_CHARTS.RENEWED_TRANSACTION_BY_PACKAGE,
      title: 'Sản lượng gia hạn gói cước',
      ...parseDonutValue(reportData.renewTrsByPkg),
    },
    {
      type: REPORT_CHARTS.CANCELLED_TRANSACTION_BY_PACKAGE,
      title: 'Sản lượng hủy gói cước',
      ...parseDonutValue(reportData.cancelTrsByPkg),
    },
  ];

  return (
    <>
      <Grid container spacing="20px">
        {stats.map((stat) => (
          <Grid item xs={6} md={3} key={stat.label}>
            <LineChartBlock data={stat} />
          </Grid>
        ))}
        {donut.map((data) => (
          <Grid item xs={6} xl={6} key={data.title}>
            <DonutChartBlock data={data} hasLegendValue />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default Report;
