import { SERVICE } from '@src/constants';
import api from './api';

export const getComments = async (organizationId, appId, params) => {
  const res = await api({
    method: 'GET',
    url: `/organizations/${organizationId}/apps/${appId}/app-comments`,
    params,
    source: SERVICE.API_MANAGEMENT,
  });
  return res;
};

export const replyComment = async (organizationId, appId, commentId, data) => {
  const res = await api({
    method: 'PUT',
    url: `/organizations/${organizationId}/apps/${appId}/app-comments/${commentId}`,
    source: SERVICE.API_MANAGEMENT,
    data,
  });
  return res;
};
