export default {
  HOME: '/',
  UNAUTHORIZED: '/403',
  APP: '/organization/:organizationId/app',
  ORGANIZATION_INFO: '/organization/:organizationId',
  APP_VERSION_DETAIL:
    '/organization/:organizationId/app/:appId/version/:versionId',
  APP_DETAIL_INFO: '/organization/:organizationId/app/:appId/info',
  APP_EVALUATION: '/organization/:organizationId/app/:appId/evaluation',
  APP_EVALUATION_DETAIL:
    '/organization/:organizationId/app/:appId/evaluation/:versionId',
  PAYMENT_HISTORY: '/organization/:organizationId/app/:appId/payment-history',
  APP_PACKAGES: '/organization/:organizationId/app/:appId/packages',
  APP_WEBHOOK: '/organization/:organizationId/app/:appId/webhooks',
  APP_COMMENT: '/organization/:organizationId/app/:appId/comments',
  CREDENTIAL_APP: '/organization/:organizationId/app/:appId/sso',
  LOGIN: '/login',
  STATISTIC: '/organization/:organizationId/statistic',
  REPORT: '/organization/:organizationId/report',
};
