import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ArrowBackRounded } from '@mui/icons-material';
import {
  DATE_TIME_PICKER_TYPES,
  START_TIME_DEFAULT,
  END_TIME_DEFAULT,
  REPORT_GROUP_OPTION,
} from '@src/constants';
import useSearchParams from '@src/hooks/useSearchParams';
import apis from '@src/apis';
import { CustomDatePickerRange } from '@src/components/CustomDatePickerRange';
import AppSelect from '@src/components/SelectApp';
import {
  REPORT_CHARTS,
  REPORT_CATEGORIES,
  CATEGORY_MAPPER,
} from '@src/constants/chart';
import Report from './ReportApp';
import DetailReport from './DetaiReport';
import Compare from './Compare';

const ReportApp = () => {
  const [selectedApp, setSelectedApp] = useState({});
  const [reportData, setReportData] = useState({});

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );

  const { params, addParams, removeParams } = useSearchParams();
  const {
    appId,
    report,
    startTime = START_TIME_DEFAULT,
    endTime = END_TIME_DEFAULT,
    group,
    category = REPORT_CATEGORIES.TOTAL,
  } = params;

  if (!startTime || !endTime) addParams({ startTime, endTime });

  const fetchApp = async () => {
    try {
      const res = await apis.app.getApp(organizationId, appId);
      if (res?.status) setSelectedApp(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  const fetchAppReport = async () => {
    try {
      const res = await apis.report.getAppReport(organizationId, {
        appId,
        startTime,
        endTime,
        reports: Object.values(REPORT_CHARTS).join(','),
        group: group || REPORT_GROUP_OPTION.DAY,
        category: CATEGORY_MAPPER[category],
      });
      if (res?.status) setReportData(res?.result);
    } catch (error) {
      enqueueSnackbar(t(error.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (appId) fetchApp();
  }, [appId]);

  useEffect(() => {
    if (appId && !report) fetchAppReport();
  }, [appId, startTime, endTime, report]);

  const handleChangeApp = (app) => addParams({ appId: app.id });

  const handleBack = () => {
    if (appId && report) removeParams('group', 'report', 'compareTo');
    else removeParams('appId');
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: 'sticky',
          top: '0',
          backgroundColor: 'white',
          zIndex: 1001,
          pt: 1,
          pb: 1,
        }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          <ArrowBackRounded
            cursor="pointer"
            size="small"
            color="primary"
            onClick={handleBack}
          />
          <AppSelect onSubmit={handleChangeApp} app={selectedApp} onlyPaidApp />
        </Box>

        <Box maxWidth={340}>
          <CustomDatePickerRange
            startDate={startTime}
            endDate={endTime}
            type={DATE_TIME_PICKER_TYPES.DATE}
            isRefresh={false}
            hasFromText={false}
            handleChangeStartDate={(value) => addParams({ startTime: value })}
            handleChangeEndDate={(value) => addParams({ endTime: value })}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: appId && report ? 'block' : 'none',
          marginTop: '16px',
        }}
      >
        <Compare app={selectedApp} />
        <DetailReport app={selectedApp} />
      </Box>
      <Box
        sx={{
          display: !(appId && report) ? 'block' : 'none',
          marginTop: '16px',
        }}
      >
        <Report reportData={reportData} />
      </Box>
    </>
  );
};

export default ReportApp;
