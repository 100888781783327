import React, { useEffect, useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { Search } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import debounce from '@src/utils/debounce';
import apis from '@src/apis';
import {
  MAX_SEARCH_LENGTH,
  REGEX_SPECIAL_CHARACTERS,
  DEBOUNCE_AUTO_COMPLETE,
  DEFAULT_APP_ICON,
} from '@src/constants';
import { COLOR } from '@src/styles/color';
import { useSelector } from 'react-redux';
import NoData from '../NoData';

const SearchAppTextfield = ({
  searchText,
  onSubmit = () => {},
  onlyPaidApp = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [apps, setApps] = useState([]);
  const [inputText, setInputText] = useState(searchText || '');
  const [focus, setFocus] = useState(false);
  const [isFirst, setIsfirst] = useState(true);

  const textFieldRef = useRef(null);

  const organizationId = useSelector(
    (state) => state.user.organization.selected.id,
  );

  const fetchApps = async (text, first) => {
    try {
      let res = {};
      if (onlyPaidApp)
        res = await apis.report.getPaidApps(organizationId, {
          appName: text,
          limit: 5,
        });
      else
        res = await apis.app.getApps(organizationId, {
          inputText: text,
          limit: 5,
        });
      if (res?.status) setApps(res?.result?.data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      if (first) setIsfirst(false);
    }
  };

  const handleChangeInputText = (event) => {
    const { value } = event.target;

    if (value !== '' && REGEX_SPECIAL_CHARACTERS.test(value)) return;
    if (value.length > MAX_SEARCH_LENGTH) return;
    debounce(fetchApps, DEBOUNCE_AUTO_COMPLETE)(value);
    setInputText(value);
  };

  useEffect(() => {
    fetchApps('', true);
  }, []);

  useEffect(() => {
    if (searchText) setInputText(searchText);
  }, [searchText]);

  return (
    <Box sx={{ margin: '4px' }}>
      <TextField
        size="small"
        value={inputText}
        placeholder="Nhập tên ứng dụng"
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <Search
                sx={{
                  color: focus
                    ? `${COLOR.standardBlue[100]} !important`
                    : `${COLOR.blueGray[64]} !important`,
                }}
                fontSize="small"
              />
            </InputAdornment>
          ),
        }}
        inputRef={textFieldRef}
        onChange={handleChangeInputText}
        sx={{ p: '12px' }}
      />
      {isFirst && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '245px',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box>
        {apps.length === 0
          ? !isFirst && <NoData width="200px" height="202px" />
          : apps.map((app, index) => (
              <Box key={app.id}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    cursor: 'pointer',
                    p: '8px 15px',

                    '&:hover': {
                      backgroundColor: `${COLOR.standardBlue[8]} !important`,
                    },
                  }}
                  onClick={() => onSubmit(app)}
                >
                  <Avatar
                    variant="rounded"
                    src={
                      app?.latestPublishedAppVersion?.appImageUrl ||
                      DEFAULT_APP_ICON
                    }
                    alt={app?.name}
                    sx={{ width: 32, height: 32, mr: 0.5 }}
                  />
                  <Typography variant="h6b" className="nowrap">
                    {app?.name}
                  </Typography>
                </Box>
                {index !== apps.length - 1 && (
                  <Divider
                    sx={{ borderColor: `${COLOR.black[4]} !important` }}
                  />
                )}
              </Box>
            ))}
      </Box>
    </Box>
  );
};

export default SearchAppTextfield;
