import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Popup from '@src/components/Popup';
import Card from '@src/components/Card';
import apis from '@src/apis';
import { regexPhone, regexUrl } from '@src/constants/regex';
import { uploadBase64Image } from '@src/services/upload';
import actions from '@src/redux/actions';
import { COLOR } from '@src/styles/color';
import Tooltip from '@src/components/Tooltip';
import { InfoRounded } from '@mui/icons-material';
import { CustomDatePicker } from '@src/components/CustomDatePickerRange';
import { DATE_TIME_PICKER_TYPES } from '@src/constants';
import { getChangedFields } from '@src/utils/object';
import { StyledOrganizationInfo } from './index.style';
import LoadingOrganizationInfo from './LoadingOrganizationInfo';

const OrganizationInfo = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { organizationId } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const fileInputRef = useRef(null);

  const [data, setData] = useState({});
  const [organizationApi, setOrganizationApi] = useState({});
  const [fileUpload, setFileUpload] = useState();
  const [avatar, setAvatar] = useState();
  const [owner, setOwner] = useState();
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);

  const handleData = () => {
    const dataChange = getChangedFields(
      organizationApi,
      { ...data, avatarUrl: avatar },
      ['ownerData'],
    );

    return dataChange;
  };

  const handleResetData = () => {
    setAvatar(organizationApi.avatarUrl);
    setData(organizationApi);
  };

  const handleChangeData = (key, value) => {
    setError({ ...error, [key]: '' });
    setData({ ...data, [key]: value });
  };

  const handleChangeAvatar = (e) => {
    const { files } = e.target;

    if (!files) return;

    const objectUrl = URL.createObjectURL(files[0]);

    setAvatar(objectUrl);
    setFileUpload(files[0]);
    fileInputRef.current.value = null;
  };

  const openChangeAvatar = () => {
    fileInputRef.current?.click();
  };

  const isValidData = () => {
    const {
      name,
      companyName,
      abbreviationName,
      representativeName,
      idIdentification,
      phoneNumber,
      websiteUrl,
    } = data;
    const errorData = {};

    if (!name?.trim()) {
      errorData.name = t('organizationNameRequired');
    }

    if (!companyName?.trim()) {
      errorData.companyName = t('companyNameRequired');
    }

    if (!abbreviationName?.trim()) {
      errorData.abbreviationName = t('abbreviationNameRequired');
    }

    if (!representativeName?.trim()) {
      errorData.representativeName = t('representativeNameRequired');
    }

    if (!idIdentification?.trim()) {
      errorData.idIdentification = t('idIdentificationRequired');
    }

    if (phoneNumber && !regexPhone.test(phoneNumber)) {
      errorData.phoneNumber = t('phoneInvalid');
    }

    if (websiteUrl && !regexUrl.test(data.websiteUrl)) {
      errorData.websiteUrl = t('websiteInvalid');
    }

    if (Object.keys(errorData).length) {
      setError(errorData);
      return false;
    }
    return true;
  };

  const handleUploadImage = async () => {
    try {
      if (!fileUpload) return '';
      if (typeof fileUpload === 'string') {
        return fileUpload;
      }
      const url = await uploadBase64Image(fileUpload);
      if (url) return url;

      return null;
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
      return null;
    }
  };

  const handleOpenConfirmPopup = () => {
    if (!isValidData()) return;

    setOpenConfirmPopup(true);
  };

  const handleCloseConfirmPopup = () => {
    setOpenConfirmPopup(false);
  };

  const handleUpdateOrganization = async () => {
    if (!isValidData()) return;

    try {
      const dataUpdate = handleData();
      if (fileUpload) {
        const avatarUrl = await handleUploadImage();
        dataUpdate.avatarUrl = avatarUrl;
      }
      const res = await apis.organization.updateOrganization(
        organizationId,
        dataUpdate,
      );
      if (res?.status) {
        enqueueSnackbar(t('updateSuccess'), { variant: 'success' });
        setOrganizationApi({ ...data, avatarUrl: avatar });
        dispatch(actions.user.updateSelectedOrganization(res?.result));
      }
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
    }
  };

  const fetchOrganization = async () => {
    try {
      setIsLoading(true);
      const res = await apis.organization.getOrganization(organizationId);
      if (res?.status) {
        const { result } = res;
        setAvatar(result?.avatarUrl);
        setData(result);
        setOwner(result?.ownerId?.name);
        setOrganizationApi({ ...res.result, avatarUrl: result?.avatarUrl });
      }
      setIsLoading(false);
    } catch (e) {
      enqueueSnackbar(t(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    fetchOrganization();
  }, [organizationId]);

  return (
    <StyledOrganizationInfo>
      <Card sx={{ maxWidth: '640px' }}>
        <Box className="header-card">
          <Typography variant="h3" className="title">
            {t('organizationInfo')}
          </Typography>
        </Box>

        {isLoading ? (
          <LoadingOrganizationInfo />
        ) : (
          <>
            <Box className="body-card">
              <Grid container spacing={4} className="flex">
                <Grid item xs={3}>
                  <Typography variant="h6">{t('avatar')}</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Avatar
                    src={avatar}
                    sx={{ width: '56px', height: '56px', cursor: 'pointer' }}
                    onClick={openChangeAvatar}
                  >
                    {data?.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleChangeAvatar}
                  />
                </Grid>
              </Grid>
              <Box>
                <Typography className="label-input" variant="h6">
                  {t('organizationName')} <span className="required">*</span>
                </Typography>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  placeholder={t('organizationName')}
                  value={data?.name || ''}
                  onChange={(e) => handleChangeData('name', e.target.value)}
                  error={!!error?.name}
                  helperText={error?.name}
                />
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('companyName')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('companyName')}
                    value={data?.companyName}
                    onChange={(e) =>
                      handleChangeData('companyName', e.target.value)
                    }
                    error={!!error?.companyName}
                    helperText={error?.companyName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('abbreviationName')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('abbreviationName')}
                    value={data?.abbreviationName}
                    onChange={(e) =>
                      handleChangeData('abbreviationName', e.target.value)
                    }
                    error={!!error?.abbreviationName}
                    helperText={error?.abbreviationName}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('representativeName')}{' '}
                    <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('representativeName')}
                    value={data?.representativeName}
                    onChange={(e) =>
                      handleChangeData('representativeName', e.target.value)
                    }
                    error={!!error?.representativeName}
                    helperText={error?.representativeName}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('owner')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    className="textfield-input"
                    fullWidth
                    value={owner}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('idIdentification')} <span className="required">*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('idIdentification')}
                    value={data?.idIdentification}
                    onChange={(e) =>
                      handleChangeData('idIdentification', e.target.value)
                    }
                    error={!!error?.idIdentification}
                    helperText={error?.idIdentification}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('effectiveTime')}
                    <Tooltip
                      title={t('effectiveTimeDesc')}
                      placement="top"
                      arrow
                    >
                      <InfoRounded fontSize="small" />
                    </Tooltip>
                  </Typography>
                  <CustomDatePicker
                    type={DATE_TIME_PICKER_TYPES.DATE}
                    selectedDate={data?.effectiveTime}
                    handleChangeDate={(value) =>
                      handleChangeData('effectiveTime', value)
                    }
                    textFieldDisable
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('phoneNumber')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('phoneNumber')}
                    value={data?.phoneNumber}
                    onChange={(e) =>
                      handleChangeData('phoneNumber', e.target.value)
                    }
                    error={!!error?.phoneNumber}
                    helperText={error?.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('website')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="https://example.com"
                    value={data?.websiteUrl}
                    onChange={(e) =>
                      handleChangeData('websiteUrl', e.target.value)
                    }
                    error={!!error?.websiteUrl}
                    helperText={error?.websiteUrl}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('address')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('address')}
                    value={data?.address}
                    onChange={(e) =>
                      handleChangeData('address', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('technicalFocalPoint')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('inputNameOrPhoneNumber')}
                    value={data?.technicalFocalPoint}
                    onChange={(e) =>
                      handleChangeData('technicalFocalPoint', e.target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('businessFocalPoint')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('inputNameOrPhoneNumber')}
                    value={data?.businessFocalPoint}
                    onChange={(e) =>
                      handleChangeData('businessFocalPoint', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="label-input" variant="h6">
                    {t('customerSuccessFocalPoint')}
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder={t('inputNameOrPhoneNumber')}
                    value={data?.customerSuccessFocalPoint}
                    onChange={(e) =>
                      handleChangeData(
                        'customerSuccessFocalPoint',
                        e.target.value,
                      )
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box className="footer-card">
              <Button
                variant="outlined"
                className="button"
                onClick={handleResetData}
              >
                {t('reset')}
              </Button>
              <Button
                variant="contained"
                className="button"
                disabled={!Object.keys(handleData())?.length}
                onClick={handleOpenConfirmPopup}
              >
                {t('save')}
              </Button>
            </Box>
          </>
        )}
      </Card>
      <Popup
        open={openConfirmPopup}
        onClose={handleCloseConfirmPopup}
        title={t('confirmSave')}
        content={t('confirmSaveContent')}
        onOk={handleUpdateOrganization}
        titleColor={COLOR.warning.base}
      />
    </StyledOrganizationInfo>
  );
};

export default OrganizationInfo;
